export const dataDigital = [
    {
        id:1,
        img:'https://res.cloudinary.com/dpsamxitw/image/upload/v1661855712/codler/a5b989fc-828b-4ada-bee0-ba9faf58560e_cqxxaf_m5afzf.webp',
        title:'Naseef',
        desg:'Director, Ceanor',
        desc:`It was a great experience working with Codler. They understood our requirements with minimal explanation and delivered exactly what we wanted. Looking forward to work with them again.`,
        star:`⭐⭐⭐⭐⭐`

    },
    {
        id:2,
        img:'https://res.cloudinary.com/dvbplh4z9/image/upload/v1667981770/codler%20branding%20page/4438355b-b1a4-4d13-9ecd-bceed7fcb79d_ut2zoc_f90nlr_e66wrj.webp',
        title:'Prabin BP',
        desg:'MD, Panac Systems Pvt Ltd',
        desc:`Codler helped us with Digital marketing and Brand building for Odikko.Odikko being an online marketplace startup required good digital marketing presence . The plans were executed flawlessly.`,
        star:`⭐⭐⭐⭐⭐`

    },
    {
        id:3,
        img:'http://res.cloudinary.com/dvbplh4z9/image/upload/v1667981525/codler%20branding%20page/Shajmeer_rng4qi_fbtrso_zwv3ne.webp',
        title:'Shajmeer',
        desg:'MD, Ugrab',
        desc:`A very professional team who proactively listens to our needs, requirements and comes back to us with creative solutions.The outcome we got was beyond our expectations. Thanks to Codlerr for all of your support.`,
        star:`⭐⭐⭐⭐⭐`

    },
    {
        id:4,
        img:'https://res.cloudinary.com/dpsamxitw/image/upload/v1661855712/codler/Shashidhar_l0yung_fgzama.webp',
        title:'Shashidhar GS',
        desg:'Head of Technology, Ondace',
        desc:`Codlerr has been very professional, comprehensive and competent throughout the process of working together. Their team worked closely with us to provide a website that met all our requirements.`,
        star:`⭐⭐⭐⭐⭐`

    }
    
]





export const DigitaGallery=[
    {
        id:'1',
        title:`Digital Marketing`,
        imgUrl:'https://res.cloudinary.com/dvbplh4z9/image/upload/v1667192595/codler%20branding%20page/WhatsApp_Image_2022-09-06_at_2.41.12_PM_wensrx.webp'
    },
    {
        id:'2',
        title:`Digital Marketing`,
        imgUrl:'https://res.cloudinary.com/dvbplh4z9/image/upload/v1667192595/codler%20branding%20page/WhatsApp_Image_2022-09-06_at_2.40.41_PM_1_zstbfi.webp'
    },
    {
        id:'3',
        title:`Digital Marketing`,
        imgUrl:'https://res.cloudinary.com/dvbplh4z9/image/upload/v1667192595/codler%20branding%20page/WhatsApp_Image_2022-09-06_at_2.40.41_PM_2_rs5eoj.webp'
    },
    {
        id:'4',
        title:`Digital Marketing`,
        imgUrl:'https://res.cloudinary.com/dvbplh4z9/image/upload/v1667192595/codler%20branding%20page/WhatsApp_Image_2022-09-06_at_2.40.41_PM_v5ievz.webp'
    },
    {
        id:'5',
        title:`Digital Marketing`,
        imgUrl:'https://res.cloudinary.com/dvbplh4z9/image/upload/v1667192595/codler%20branding%20page/WhatsApp_Image_2022-09-06_at_2.41.12_PM_wensrx.webp'
    },
    {
        id:'6',
        title:`Digital Marketing`,
        imgUrl:'https://res.cloudinary.com/dvbplh4z9/image/upload/v1667192595/codler%20branding%20page/WhatsApp_Image_2022-09-06_at_2.45.49_PM_sxgeyd.webp'
    },
]