import react,{useEffect, useState ,useRef} from 'react';
import '../digital-marketing.css';
import {toast} from 'react-toastify'
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Slides from '../component/sliders/website-slider';
import axios from 'axios';
import { Buffer } from 'buffer'
import Popup from '../component/popup-from';

import HubspotForm from 'react-hubspot-form'

function Website() {

    	//------------------Api-end axios------------------//
	const [items, setItems] = useState([]);
	useEffect(() => {
		const fetchItems = async () => {
			await axios.get("https://codlerbackend.onrender.com/admin/get/api/clients").then((response) => {
				setItems(response.data);
			});
		};
		fetchItems();
	}, []);

	/**
	 * Function that transform the buffer to base64
	 * @param {*} image : Image buffer array
	 * @returns : Base64 image
	 */
	const getImage = (image) => Buffer.from(new Uint8Array(image)).toString("base64");

	console.log(items);
	//------------------Api-end axios------------------//

    function displayClients(index) {
        console.log(index);
        return (
            <>
            <div className='bg-[#fffffff1] shadow-md grid place-items-center w-[95%] lg:w-[400px] h-[180px] lg:h-[220px] rounded-md'>
                <div className='grid place-items-center py-3 px-20 cursor-pointer' onClick={() => navigate(`/clients/${index.testiName}`, { state: { clients: index } })}>
                        <LazyLoadImage effect='blur' className='h-16 w-fit cursor-pointer' src={`data:image/png;base64,${getImage(index.testiLogo.data)}`} ></LazyLoadImage>
                        <p className='font-gilroysemibold pt-3'>{index.testiName}</p>
                </div>
            </div>
            </>
        )
    }


    const navigate = useNavigate('');
    const [showPop, setshowPop] = useState(false);

    

      
  return (
    <div className='bg-white'>
       
            {/* nav */}
                <div className='w-10/12 md:w-11/12 lg:w-[88%] mx-auto pt-6 md:pt-14 flex justify-between'>
                    <div>
                        <img className='cursor-pointer h-[35px] md:h-[40px]' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/log_blackk_m2lwoy.png' alt='image'></img>
                    </div>
                    <div className=''>
                        <span className='text-black hidden md:inline-block '><i class="fa-solid fa-phone"></i><a href='tel:+917012696630' className='text-black font-gilroysemibold pl-2.5 pr-4'>+91-7012696630</a></span>
                        <a onClick={() => setshowPop(true)} className='mt-3 md:mt-0 bg-[#7521A8] hover:bg-[#9a45cf] cursor-pointer no-underline hover:no-underline text-white font-gilroysemibold px-8 py-2.5 rounded-md'>Let's Talk</a>
                    </div>
                </div>

         
         
                

          <section className=' web-banner pt-20 md:pb-10 lg:pt-40 lg:pb-32'>
                        {/* banner */}

                    <div className='w-11/12 mx-auto grid grid-cols-1 md:grid-cols-2  '>
                                {/* left */}
                            <div className=' pl-3 left-ban-div'>
                                    <h3 className='font-gilroysemibold text-2xl lg:text-4xl'>Your one-stop Solution to</h3>
                                   <span className='inline-block relative'>
                                    <h2 className='font-gilroybold text-5xl lg:text-6xl'>Improve your</h2>
                                        <hr className='web-head-bg -z-10'></hr>
                                   </span>
                                    <p className='font-gilroysemibold text-lg md:text-xl py-3 md:py-10 md:w-[90%]'>we build, grow and help you scale by bringing the best digital marketing initiatives that aligns with your brand's mission.</p>

                                    <span onClick={() => setshowPop(true)}  className='bg-[#7521A8] hover:bg-[#9a45cf] text-white font-gilroysemibold px-8 py-2.5 rounded-md'><a href='#' className='text-white no-underline hover:no-underline mr-4'>Let's Talk</a><i class="fa-solid fa-angle-right"></i></span>
                            </div>
                                {/* right */}
                            <div className='py-10 md:py-0'>
                            <iframe className='rounded-[2rem] w-full h-60 lg:h-96' src="https://www.youtube.com/embed/cLh63hxSDjM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </div>
                    </div>
          </section>

          {/* sample */}
          <div className='grid place-items-center'>
            <Popup isVisible={showPop} onClose={() => setshowPop(false)} />
          </div>
          {/* sample */}


                {/*Counts  */}
          <section className='web-count'>
                    <div className='grid place-items-center pt-3'>
                                <p className='font-gilroysemibold text-center text-base lg:text-2xl py-2'>A unique website for your company that draws leads and turns them into clients!.</p>
                            </div>  
                    <div className='w-8/12 mx-auto grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-0'>
                        <div className='grid place-items-center'>
                            <h3 className='count-head font-gilroybold text-7xl md:text-5xl'>60+</h3>
                            <p className='font-gilroybold'>Happy Clients</p>
                        </div>
                        <div className='grid place-items-center'>
                            <h3 className='count-head font-gilroybold text-7xl md:text-5xl'>100+</h3>
                            <p className='font-gilroybold'>Successful Projects</p>
                        </div>
                        <div className='grid place-items-center'>
                            <h3 className='count-head font-gilroybold text-7xl md:text-5xl'>7+</h3>
                            <p className='font-gilroybold'>Different Countries</p>
                        </div>
                    </div>
          </section>


            {/* Brands */}
          <section>
                <div className='grid place-items-center py-10 web-project'>
                    <span className='inline-block relative'>
                            <h4 className='font-gilroybold text-5xl'>Our Projects</h4>
                                <hr className='web-head-bg -z-10'></hr>
                            </span>    

                            <div className='grid place-items-center pt-3'>
                                <p className='font-gilroysemibold text-center text-base md:text-xl'>Custom Website for Your Business that Attracts Leads and Converts Them into Customers!</p>
                            </div>                      
                </div>


                <div className='w-10/12 lg:w-8/12 brand-fit mx-auto place-items-center grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-5 py-4 rounded-md'>
                            {/* <div className='bg-[#fffffff1] shadow-md grid place-items-center w-[95%] lg:w-[400px] h-[180px] lg:h-[220px] rounded-md'>
                               <div className='grid place-items-center'>
                                <LazyLoadImage effect='blur' className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668073874/codler%20digital%20marketing/Ondace_Logo_i6jzcy.png'></LazyLoadImage>
                                <p className='font-gilroysemibold pt-3'>ONDACE</p>
                               </div>
                            </div>
                            <div className='bg-[#fffffff1] shadow-md grid place-items-center w-[95%] lg:w-[400px] h-[180px] lg:h-[220px] rounded-md'>
                                <div className='grid place-items-center'>
                                  <LazyLoadImage effect='blur' className='h-14' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668073874/codler%20digital%20marketing/JSK_Logo_lazt5a.png'></LazyLoadImage>
                                <p className='font-gilroysemibold pt-2'>JSK</p>
                                </div>
                            </div>
                            <div className='bg-[#fffffff1] shadow-md grid place-items-center w-[95%] lg:w-[400px] h-[180px] lg:h-[220px] rounded-md'>
                               <div className='grid place-items-center'>   
                                <LazyLoadImage effect='blur' className='h-28' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668073874/codler%20digital%20marketing/image_2435_rfvycc.png'></LazyLoadImage>
                                <p className='font-gilroysemibold'>MEMORIES</p>
                               </div>
                            </div>
                            <div className='bg-[#fffffff1] shadow-md grid place-items-center w-[95%] lg:w-[400px] h-[180px] lg:h-[220px] rounded-md'>
                                <div className='grid place-items-center'>
                                  <LazyLoadImage effect='blur' className='h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668255933/codler%20branding%20page/DX3_NFT_Studios_Black_LOgo_ncg7jh.png'></LazyLoadImage>
                                <p className='font-gilroysemibold'>DXB</p>
                                </div>
                            </div> */}

                        {items.map(displayClients)}
                </div>

                <div className='grid place-items-center py-10'>
                    
                    <a href='#LetsTalk'  className='no-underline hover:no-underline font-gilroysemibold bg-[#7521A8] hover:bg-[#9a45cf]  rounded-md text-white py-2.5 px-6 cursor-pointer'>Let's Get Started</a>
                </div>
          </section>



            {/* Ebook */}
        <section className='md:pb-32 pt-10 e-book'>
                    <div className='w-10/12 lg:w-8/12 mx-auto grid grid-cols-1 md:grid-cols-3'>
                            <div className='bg-[#7521A8] grid place-items-center'>
                                <LazyLoadImage  className='h-72 pt-1' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1669016820/codler%20branding%20page/kjjk_y02rzq.webp' alt=''></LazyLoadImage>
                            </div>
                            <div className='md:col-span-2 md:border-r-[40px] border-[#7521A8] '>
                                <div className='my-4 bg-white p-5 rounded-tr-md rounded-br-md shadow-xl md:-mr-3'>
                                    <h3 className='font-gilroysemibold'>Free E-Book</h3>
                                    <p className='font-gilroysemibold'>Download free E-Book and sign up for Codlerr’s Newsletter.</p>
                                    <form className='flex flex-col md:flex-row gap-3 md:gap-5 pt-3 md:pt-10'>
                                        <input className='w-full md:w-[70%] outline-none border-b-2' type='email' placeholder='example@gmail.com'/>
                                        <button className='bg-[#7521A8] hover:bg-[#9a45cf] text-white px-3 py-2.5 rounded-md'><i class="fa-solid fa-download"></i></button>
                                    </form>
                                </div>
                            </div>
                    </div>
        </section>


        {/* services */}
        <section className='w-10/12 lg:w-8/12 mx-auto'>
                <div className='grid place-items-center py-10 web-project'>
                    <span className='inline-block relative'>
                            <h4 className='font-gilroybold text-5xl'>Our Service</h4>
                                <hr className='web-head-bg -z-10'></hr>
                            </span>                          
                </div>

                <div>
                    <h4 className='font-gilroybold'>We develop, build</h4>
                    <p className='font-gilroysemibold text-base md:text-lg'>Take your brand online and lead your business with the help of one of the best teams in India. We will walk you through every nook and corner of the project. Our team will brainstorm, plan, research and organise data to meet your goals. Our accurate market understanding allows us to identify the market demands and integrate them into the design for a better user experience. Our tech team focuses on developing logical, intuitive, and appealing websites for all users.</p>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-y-16 py-5 px-10 service-grid rounded-sm mt-5'>
                        <div className='p-4 service-br'>
                            <h3 className='font-gilroybold'>01 Customised Website</h3>
                            <p className='font-gilroysemibold text-sm md:text-base'>Custom Website for Your Business that Attracts Leads and Converts Them into Customers!<br/>
                                        Cutting-edge technology and easy-to-use, search engine-optimized websites.<br/> 
                                        We specialise in creating custom websites that reflect your unique business. </p>
                                {/* <span className=''>
                                    <a href='#' className='my-3 no-underline hover:no-underline bg-[#7521A8] hover:bg-[#9a45cf] text-white px-4 py-2.5 rounded-md float-right'><i class="fa-solid fa-angle-right"></i></a>
                                </span> */}
                        </div>
                        <div className='p-4 service-br'>
                            <h3 className='font-gilroybold'>02 E–commerce Website</h3>
                            <p className='font-gilroysemibold text-sm md:text-base'>A brand-new online shop or a redesign of an existing website.<br/>  
                                        E-commerce websites that meet the needs and expectations of your customers.<br/>  
                                        Together, we will design a website that is not only beautiful to look at but also simple to use.</p>
                               
                        </div>
                        <div className='p-4 service-br'>
                            <h3 className='font-gilroybold'>03 Mobile Responsive Website</h3>
                            <p className='font-gilroysemibold text-sm md:text-base'>Mobile-responsive websites that look great on all devices<br/>  
                                We ensure the content is simple to read and navigate regardless of screen size.<br/> 
                                Websites designed with mobile devices in mind ensure a consistent and comfortable experience. </p>
                                
                        </div>
                        <div className='p-4 service-br'>
                            <h3 className='font-gilroybold'>04 Wordpress Website</h3>
                            <p className='font-gilroysemibold text-sm md:text-base'>We make the process of creating a website easy and affordable.<br/>  
                                WordPress websites designed beautifully, user-friendly, and easy to manage.<br/>  
                                Create a website that expresses your message and bran.</p>
                                
                        </div>
                        
                </div>
                <div className='serv-br -mt-2'>
                    <hr></hr>
                </div>
                        <div className='grid place-items-center'>
                        <div className='grid place-items-center py-10'>
                            <p className='font-gilroysemibold text-base md:text-2xl '>Make Your Website Stand Out With Our Professional Development Services.</p>
                                <a href='#LetsTalk' className='no-underline hover:no-underline font-gilroysemibold bg-[#7521A8] hover:bg-[#9a45cf]  rounded-md text-white py-2.5 px-6'>Let's Get Started</a>
                            </div>

                        </div>
        </section>


        {/* Testimonial */}

        <section className='testimonailss'>
                <div className='grid place-items-center pt-10 web-project'>
                    <span className='inline-block relative'>
                            <h4 className='font-gilroybold text-5xl'>Testimonials</h4>
                                <hr className='web-head-bg -z-10'></hr>
                            </span>                          
                </div>

                <div className='md:pb-10 w-11/12 mx-auto md:w-full'>
                    <Slides/>
                </div>
        </section>


        {/* Why you */}

        <section className='w-10/12 lg:w-9/12 mx-auto'>
            <div className='why-you-bg md:pl-20'>
                <div className=' web-project'>
                    <span className='inline-block relative'>
                            <h4 className='font-gilroybold text-3xl md:text-5xl'>Why you need a</h4>
                            
                                <hr className='web-head-bg -z-10'></hr>
                            </span><br></br>                          
                    <span className='inline-block relative'>
                            <h4 className='font-gilroybold text-3xl md:text-5xl'>Professional Website</h4>
                            
                                <hr className='web-head-bg -z-10'></hr>
                            </span>                          
                </div>
            <p className='font-gilroysemibold pt-5 pb-20 text-base md:text-lg md:pl-4'>1. A Professional website will give your business more credibility<br/>
                2. A Professional website saves your money<br/>
                3. A website enables you to keep your customers informed<br/>
                4. A website is always accessible<br/>
                5. A website makes it possible to target a wide market<br/>
                6. A website saves your time<br/>
                7. A website can inmprove customer service</p>
            </div>

                <div className='md:pl-24 xs:-mt-10 md:-mt-20'>
                    <a href='#LetsTalk' className='no-underline shadow-lg hover:no-underline font-gilroysemibold bg-[#7521A8] hover:bg-[#9a45cf]  rounded-md text-white py-2.5 px-6'>Let's Get Started</a>
                </div>
        </section>



        {/* Our cliebts */}
        <section className='md:py-32'>   
                <div className='grid place-items-center py-10 web-project'>
                    <span className='inline-block relative'>
                            <h4 className='font-gilroybold text-5xl'>Our Clients</h4>
                                <hr className='web-head-bg -z-10'></hr>
                            </span>                          
                </div>


                <div className='w-10/12 lg:w-9/12 mx-auto lg:pt-10'>
                        <div className='  border-[#7521A8]  border-r-[20px] border-l-[20px] '>
                           <div className='clients-brands px-5 place-items-center py-5 md:py-0  grid grid-cols-1 md:grid-cols-4 gap-5'>
                                <LazyLoadImage effect='blur' className='h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668073874/codler%20digital%20marketing/image_2435_rfvycc.png'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668255933/codler%20branding%20page/DX3_NFT_Studios_Black_LOgo_ncg7jh.png'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668073874/codler%20digital%20marketing/JSK_Logo_lazt5a.png'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-8' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668073874/codler%20digital%20marketing/Ondace_Logo_i6jzcy.png'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193221/codler%20branding%20page/MicrosoftTeams-image_my4qlm.png'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193214/codler%20branding%20page/Group_435_u2jmuk.svg'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-16' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193220/codler%20branding%20page/Logo_aqpcr9.png'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/logo22_znmlt7.png'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193213/codler%20branding%20page/ceanor_hpfyox.png'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-14' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193214/codler%20branding%20page/Group_434_iftgal.svg'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-14' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193220/codler%20branding%20page/Mask_Group_40_mrywex.png'></LazyLoadImage>
                                <LazyLoadImage effect='blur' className='h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1669021400/codler%20branding%20page/WhatsApp_Image_2022-11-21_at_2.16.55_PM_jlxy3g.jpg'></LazyLoadImage>
                           </div>
                        </div>
                             <div className='grid place-items-center pt-10 pb-3 md:pb-0'>
                                  <a href='#LetsTalk' className='no-underline shadow-lg hover:no-underline font-gilroysemibold bg-[#7521A8] hover:bg-[#9a45cf]  rounded-md text-white py-2.5 px-6'>Let's Get Started</a>
                            </div>
                </div>

        </section>

        	{/* Language */}
        <section className='language'>
            <div className='md:11/12 lg:w-8/12 mx-auto grid grid-cols-1 md:grid-cols-4 gap-3 md:gap-0 py-10'>
                <div className='border-[#7521A8]  lg:border-r-8 grid place-items-center'>
                    <h3 className='md:w-[70%] text-4xl font-gilroysemibold'>Language Used</h3>
                </div>
                <div className='md:col-span-3 grid grid-cols-1 md:grid-cols-4'>
                        <div className='grid place-items-center'>
                            <LazyLoadImage effect='blur' className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668085834/codler%20digital%20marketing/icons8-react-native-100_oq8xuc.png'></LazyLoadImage>
                            <p className='font-gilroysemibold'>React</p>
                        </div>
                        <div className='grid place-items-center'>
                            <LazyLoadImage effect='blur' className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668085834/codler%20digital%20marketing/icons8-wordpress-100_voqljv.png'></LazyLoadImage>
                            <p className='font-gilroysemibold'>Wordpress</p>
                        </div>
                        <div className='grid place-items-center'>
                            <LazyLoadImage effect='blur' className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668085834/codler%20digital%20marketing/icons8-shopify-100_dgmuof.png'></LazyLoadImage>
                            <p className='font-gilroysemibold'>Shopify</p>
                        </div>
                        <div className='grid place-items-center'>
                            <LazyLoadImage effect='blur' className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668085834/codler%20digital%20marketing/icons8-nodejs-100_zzvhcb.png'></LazyLoadImage>
                            <p className='font-gilroysemibold'>Nodejs</p>
                        </div>
                        
                </div>
            </div>
        </section>


        {/* Launch */}
        <section className='w-10/12 md:w-8/12 mx-auto lauch my-5 lg:my-20'>

            <div className='py-10 lg:py-24 '>
                <div className='web-project quots'>
                        <span className='inline-block relative'>
                                <h4 className='font-gilroybold text-2xl lg:text-4xl'>Do you need a partner in web development?</h4>
                                    <hr className='web-head-bg -z-10'></hr>
                        </span>                          
                    </div>
                <div className='pt-2  web-project quots'>
                        <span className='inline-block relative'>
                                <h4 className='font-gilroybold text-2xl lg:text-4xl'>The One-Stop Shop for All Your Needs is Us!</h4>
                                    <hr className='web-head-bg -z-10'></hr>
                        </span>                          
                </div>
                <div className='grid place-items-center pt-10'>
                    <a href='#LetsTalk' className='no-underline shadow-lg hover:no-underline font-gilroysemibold bg-[#7521A8] hover:bg-[#9a45cf]  rounded-md text-white py-2.5 px-6'>Let's Get Started</a>
                </div>
            </div>


        </section>


        

        <section>
            {/* hubspot */}
         <div className='w-10/12 lg:w-8/12 mx-auto' id='LetsTalk' >
            <div className=' bg-[#ffffff] shadow-md rounded-sm xs:px-4 sm:px-4 md:px-20 py-10 mb-3 md:mb-0'>
            <h4 className='text-3xl font-gilroybold pb-3 md:pb-0'>Let's Get Started</h4>
                <HubspotForm
                region="na1"
                portalId="23124429"
                formId='dac46861-6875-468c-8fc3-4e46b70d1a5a'
                loading={<div>Loading...</div>}
                />
             </div>
         </div>
         {/* hubspot */}
        </section>


        {/* Footer */}
        <section className='web-footer'>
            <div className='w-11/12 mx-auto flex flex-col md:flex-row  justify-between align-middle py-8'>
                <div className='grid md:place-items-center'>
                    <img className="h-10" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/log_blackk_m2lwoy.png" alt="" />
                </div>
                <div className='p-2 md:p-0'>
                    <div className='flex gap-3 pt-5 md:pt-0'>
                        <a target='_blank' href='https://www.facebook.com/codlerrsolutions/'><i className="fa-brands fa-facebook pr-2 text-[#7521A8]  text-3xl"></i></a>
                        <a target='_blank' href='https://instagram.com/codlerr?igshid=YmMyMTA2M2Y='><i className="fa-brands fa-instagram pr-2 text-[#7521A8]  text-3xl"></i></a>
                        <a target='_blank' href='https://www.linkedin.com/company/codlerr'><i class="fa-brands fa-linkedin-in pr-2 text-[#7521A8]  text-3xl"></i></a>
                        <a target='_blank' href='https://twitter.com/Codler3?t=PYG-ShshZ3RTT68Z6F56Og&s=08'><i className="fa-brands fa-twitter pr-2 text-[#7521A8]  text-3xl"></i></a>
                    </div>
                    <div className='pt-3'>
                    <a className='text-black font-gilroysemibold' href='tel:+917012696630'>tel:+917012696630</a>
                    </div>
                    <a className='text-[#7521A8] no-underline hover:no-underline  font-gilroysemibold cursor-pointer' onClick={() => navigate('/Contactus')}>info@codlerr.com</a>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Website;