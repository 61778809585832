import react,{useEffect, useState} from 'react';
import '../digital-marketing.css';
import {toast} from 'react-toastify';
import HubspotForm from 'react-hubspot-form'

const  Popufrom = ({ isVisible, onClose }) => {




    // form submission
  

  // form submission

    if ( !isVisible ) return null;

    const handleClose = (e) => {
        if( e.target.id === 'wrapper') onClose();
    }

  return (
    <div onClick={handleClose} id='wrapper' className='fixed inset-0 bg-slate-800 bg-opacity-25 backdrop-blur-sm z-50 grid place-items-center'>
         <div className='w-[90%] md:w-[40%] bg-[#ffffff] shadow-md rounded-md p-3 relative'>
         <button onClick={() => onClose()} className='absolute right-2 top-2'><i class="fa-regular fa-circle-xmark text-2xl  text-[#7521A8] hover:text-red-500"></i></button>
    <h4 className='text-[#7521A8] font-gilroybold text-xl md:text-3xl'>Let's focus on making<br/> amazing things digitally.</h4>
            <HubspotForm
              region="na1"
              portalId="23124429"
              formId='596236a1-40fe-4380-bef5-38d6d9ea63f2'
              onSubmit={() => onClose()}
              loading={<div>Loading...</div>}
              />
         </div>
        
    </div>

       
  )
}

export default Popufrom;


