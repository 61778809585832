import React, {useRef,useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../../digital-marketing.css'

import { dataDigital } from '../../constants/digital-data';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';




function Websiteslider() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,


    
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          
          {
            breakpoint: 600,
            settings: {
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 400,
            settings: {
                infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  return (
    <div className="w-full overflow-x-hidden">
        <div className="py-10">
        <Slider {...settings}>
            {dataDigital.map((item) => (

            <div className=" xs:px-5 md:px-2 lg:px-5 bg-[#7521A8] web-slider rounded-md">
                <div className="grid grid-cols-3">
                    <div className="col-span-2">
                        <p className="xs:text-sm md:text-xs lg:text-sm text-white text-center py-4 lg:my-5 pr-2">"{item.desc}"</p>
                    </div>
                    <div className="">
                        <img className="h-24 md:h-16 lg:h-28 -rotate-6" src={item.img} alt="item.title"></img>
                        <p className="text-white text-xs pt-3 font-mono">- {item.desg}</p>
                    </div>
                </div>
            </div>
            ))}
            </Slider>
     </div>
    </div>
  )
}

export default Websiteslider;