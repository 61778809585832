export const services=[
     {
        heading:'01 Web Development',
        fields1:'E-commerce website',
        fields2:'Static website',
        fields3:'WordPress website',
        fields4:'Responsive websites'
     },
    {
          heading:'02 App development',
          fields1:'Mobile App Optimization',
          fields2:'IOS App Development',
          fields3:'Android App Development',
          fields4:'Windows App Development'
       },
      {
          heading:'03 Digital Marketing',
          fields1:'Social media Marketing ',
          fields2:'Lead Generation campaigns',
          fields3:'Email Marketing ',
          fields4:'Google ads'
      },
      {
          heading:'04 Branding',
          fields1:'Strategic research planning ',
          fields2:'LOGO Design',
          fields3:'Logo oriented website',
          fields4:'Developing brand position'
      },
      {
          heading:'05 SEO',
          fields1:'SEO Audit',
          fields2:'Competitive Analysis ',
          fields3:'ON-Page SEO',
          fields4:'Off-page SEO'
      },
    ]
     export const images=[{
         name:"N Manasa",
         designation:'Senior Content Writer',
         url:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/Image_4_qeidyk.webp'

     },
     {
        name:"Rashid PK",
        designation:'Web Developer',
        url:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/image_2_mlwiot.webp'       

    },
    {
        name:"Suha Fazal",
        designation:'Social Media Marketer',
        url:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/Image_6_ptsxsl.webp'

    },
    {
        name:"Mirza Sadique",
        designation:'Intern',
        url:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/image_1_ym8lid.webp'

    },
    {
        name:"Noora Latheef",
        designation:'Web Developer',
        url:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/Image_5_qwcgvo.webp'

    },
    {
        name:"Haniyya Hessa Sharfu",
        designation:'Intern',
        url:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/image_3_dvqgsw.webp'

    },
    
    ]
    export const Digital=[{
        fontcolor:'white',
        description:'Ugrab is a multi-vendor platform developed to fulfill the day-to-day needs of people through the smarter route. This platform assures you to lead a smarter way of living, giving you an outspread preference regarding sellers, brands, prices and much more on every product and services based on your location.',
        bgcolor:'rgba(0,255,255,0.5)',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281811/codler/91770ec6-c0fa-4e7e-8e5a-a3a5d3bffaa2_vndpee.jpg',
        name:'UGRAB',
        },
        {
        fontcolor:'black',
        description:'Odikko is an online marketplace exclusive to your neighbourhood. Sellers known to you sell quality products and services from your local market. All this is available to you at your fingertips – the ODIKKO app .',
        bgcolor:'rgb(255,255,255)',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281811/codler/0f6690c8-9f80-4f25-982d-505fefa97e29_c3wfew.jpg',
        name:'ODIKKO',
        },
        {
        fontcolor:'white',
        description:' Smart Costech is an advanced home automation technology adopted to make living easier by controlling them at your fingertips.',
        bgcolor:'rgba(0,128,0,0.6)',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281817/codler/b22dcc29-fc2b-4aec-ba24-ba1d94e43285_kjewam.jpg',
        name:'SMART COSTECH',
        },
        {
        fontcolor:'white',
        description:'Krijay Technologies is one of the best SAP Business One Partners in Hyderabad known for its trusted business automation services for small to medium-sized enterprises.',
        bgcolor:'rgb(47,79,79)',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655064684/codler/5467adaa-e00e-4b47-9ae9-f310d96507de_wnlqq8.jpg',
        name:'KRIJAY',

       },
       {
        fontcolor:'white',
        description:'Cravello is a one-stop food delivery service to satisfy your cravings, be it midnight or midday.',
        bgcolor:'rgba(155,17,30)',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655235885/codler/188040712_548786216115994_5120468500979650700_n_e6ws4o.jpg',
        name:'CRAVELLO',

       },
       {
        fontcolor:'white',
        description:'Krijay Technologies is one of the best SAP Business One Partners in Hyderabad known for its trusted business automation services for small to medium-sized enterprises.',
        bgcolor:'rgba(210,105,30)',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655309974/codler/WhatsApp_Image_2022-06-15_at_12.20.22_PM_ejepew.jpg',
        name:'UBODE',

       },
       {
        fontcolor:'white',
        description:' Delisho is a food delivery service application by providing a platform for local restaurants and cafes to deliver food through them.',
        bgcolor:'rgba(40,40,43)',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655309974/codler/WhatsApp_Image_2022-06-15_at_12.20.24_PM_gqup1y.jpg',
        name:'DELISHO',

       },

    ]

    
    export const DigitalVideos=[
        {   
          bgcolor:'rgba(128,128,128,0.1)',
            name:'UGRAB',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655315835/codler/video-to-gif-converter_arey5o.gif'
         },
        /* {  
            bgcolor:'rgb(128,128,128,0.1)', 
            name:'CEANOR',
            src:'https://res.cloudinary.com/dbwyrrrqo/video/upload/v1655310056/codler/ceanor_2_ywa7vs.mp4'
         },*/
        
    ]

    export const BrandingBrochures=[
    
         {  
            bgcolor:'rgb(128,128,128,0.1)', 
            name:'EDEZA',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655233928/codler/Web_capture_15-6-2022_04334__qg7k2m.jpg',
            alink:'https://drive.google.com/file/d/1z_KLHpdiXaakhPwpi2a3P-rQSO5dpgbk/view?usp=sharing'
         },
    ]

    export const Website=[
        {
            link:'/portfolio/ceanor',
            description:'Céanôr was born out of passion. They aim to break the idea that skincare is gender-restricted and for the privileged. Each of their products is uniquely formulated considering the socio-geographic factors of our region. Furthermore, they strive to make skincare accessible for all.',
            bgcolor:'rgb(255,255,255)',
            color:'black',
            name:'Ceanor',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680703/codler/Screenshot_60_zxosay.png',
            logo_image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242041/codler/CEANOR_xslfif.png',
            about:`Ceanor was born out of passion. They aim to break the idea that skincare is gender-restricted and for the privileged. Each of their products is uniquely formulated considering the socio-geographic factors of our region.
            Furthermore, they strive to make skincare accessible for all. Simply put, their aim is Good Skin for All.`,
            our_role:`Our part was to provide a simple yet professional website design to create a captivating impact. We created an interactive website design for the brand with in-house research and data validation, which is essential when creating a website that caters to various B2C audiences.`,
            opportunity:`We had the opportunity to do the website designing, website development, and user experience of the brand by combining our experience with the clients' preferences.`,
            what_we_delivered:`Ceanor was born with the aim of “Good skin for all” and they excellently followed this notion. So we envisioned of a subtle and classy website for the brand which we successfully delivered while savouring. We designed and developed the website all from scratch.`,
            opportunity_image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655147312/codler/WC-02_kafmcd.png',
            delivered_image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655147312/codler/WC-03_rm14y9.png'
    
        },
        {
            link:'/portfolio/krijay',
            description:'Led by the expert team of CA and IT professionals, Krijay Technologies is one of the best SAP Business One Partners in Hyderabad known for its trusted business automation services for small to medium-sized enterprisesOur unmatched SAP Business One implementation.',
            bgcolor:'rgb(30,81,123)',
            color:'white',
            name:'Krijay',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680690/codler/Screenshot_63_hugwqm.png'
        },
       {   
           link:'/portfolio/ceanor',
           description:'Ondace is a focused enterprise that strives on re-defining the way applications are built and tested. The believe in simplifying business solutions without compromising on Innovation. They are recognised for their commitment, and willingness to work at the pace of their customers.',
           bgcolor:'rgb(255,255,255)',
           color:'black',
           name:'ONDACE',
           src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680682/codler/Screenshot_65_hkhxvo.png'
        },
        {
            link:'/portfolio/ceanor',
            description:'EDEZA Trading LLC is a UAE based company providing the worlds most extensive modular pipe support line, offering technological solutions for all common industrial applications.',
            bgcolor:'rgb(11,127,171)',
            color:'white',
            name:'EDEZA',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655312133/codler/Screenshot_78_cni5ju.png',
        },
        {
            link:'/portfolio/ceanor',
            description:'ATK BUILD MART has a huge range of tiles,bathroom-fittings, and sanitary ware with 17 years of expertise.',
            bgcolor:'rgb(128,0,0)',
            color:'white',
            name:'ATK BUILD MART',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655312133/codler/Screenshot_77_dbne4a.png',
        },
          {
            link:'/portfolio/ceanor',
            description:'ATK Enterprises is one of the leading trade organizations reliable for quality and service backed by efficient and trained staffs.',
            bgcolor:'rgb(255,0,0)',
            color:'white',
            name:'ATK ENTERPRISE',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655369904/codler/Screenshot_81_dnitvy.png',
        },
    ]

    export const all=[
        {
            fontcolor:'white',
            description:'Ugrab is a multi-vendor platform developed to fulfill the day-to-day needs of people through the smarter route. This platform assures you to lead a smarter way of living, giving you an outspread preference regarding sellers, brands, prices and much more on every product and services based on your location.',
            bgcolor:'rgba(0,255,255,0.5)',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281811/codler/91770ec6-c0fa-4e7e-8e5a-a3a5d3bffaa2_vndpee.jpg',
            name:'UGRAB',
            },
            {
            fontcolor:'black',
            description:'Odikko is an online marketplace exclusive to your neighbourhood. Sellers known to you sell quality products and services from your local market. All this is available to you at your fingertips – the ODIKKO app .',
            bgcolor:'rgb(255,255,255)',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281811/codler/0f6690c8-9f80-4f25-982d-505fefa97e29_c3wfew.jpg',
            name:'ODIKKO',
            },
            {
            fontcolor:'white',
            description:' Smart Costech is an advanced home automation technology adopted to make living easier by controlling them at your fingertips.',
            bgcolor:'rgba(0,128,0,0.6)',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281817/codler/b22dcc29-fc2b-4aec-ba24-ba1d94e43285_kjewam.jpg',
            name:'SMART COSTECH',
            },
            {
            fontcolor:'white',
            description:'Krijay Technologies is one of the best SAP Business One Partners in Hyderabad known for its trusted business automation services for small to medium-sized enterprises.',
            bgcolor:'rgb(47,79,79)',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655064684/codler/5467adaa-e00e-4b47-9ae9-f310d96507de_wnlqq8.jpg',
            name:'KRIJAY',
    
           },
           {
            fontcolor:'white',
            description:'Cravello is a one-stop food delivery service to satisfy your cravings, be it midnight or midday. ',
            bgcolor:'rgba(155,17,30)',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655235885/codler/188040712_548786216115994_5120468500979650700_n_e6ws4o.jpg',
            name:'CRAVELLO',
    
           },
           {
            fontcolor:'white',
            description:'Krijay Technologies is one of the best SAP Business One Partners in Hyderabad known for its trusted business automation services for small to medium-sized enterprises.',
            bgcolor:'rgba(210,105,30)',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655309974/codler/WhatsApp_Image_2022-06-15_at_12.20.22_PM_ejepew.jpg',
            name:'UBODE',
    
           },
           {
            fontcolor:'white',
            description:'Krijay Technologies is one of the best SAP Business One Partners in Hyderabad known for its trusted business automation services for small to medium-sized enterprises.',
            bgcolor:'rgba(40,40,43)',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655309974/codler/WhatsApp_Image_2022-06-15_at_12.20.24_PM_gqup1y.jpg',
            name:'DELISHO',
    
           },
        {
            link:'/portfolio/ceanor',
            description:'Céanôr was born out of passion. They aim to break the idea that skincare is gender-restricted and for the privileged. Each of their products is uniquely formulated considering the socio-geographic factors of our region. Furthermore, they strive to make skincare accessible for all.',
            bgcolor:'rgb(255,255,255)',
            color:'black',
            name:'CEANOR',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680703/codler/Screenshot_60_zxosay.png',
        },
        {
            link:'/portfolio/krijay',
            description:'Led by the expert team of CA and IT professionals, Krijay Technologies is one of the best SAP Business One Partners in Hyderabad known for its trusted business automation services for small to medium-sized enterprisesOur unmatched SAP Business One implementation.',
            bgcolor:'rgb(30,81,123)',
            color:'white',
            name:'KRIJAY',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680690/codler/Screenshot_63_hugwqm.png'
        },
       {   
           link:'/portfolio/ondace',
           description:'Ondace is a focused enterprise that strives on re-defining the way applications are built and tested. The believe in simplifying business solutions without compromising on Innovation. They are recognised for their commitment, and willingness to work at the pace of their customers.',
           bgcolor:'rgb(255,255,255)',
           color:'black',
           name:'ONDACE',
           src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680682/codler/Screenshot_65_hkhxvo.png'
        },
        {
            link:'/portfolio/ceanor',
            description:'EDEZA Trading LLC is a UAE based company providing the worlds most extensive modular pipe support line, offering technological solutions for all common industrial applications.',
            bgcolor:'rgb(11,127,171)',
            color:'white',
            name:'EDEZA',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655312133/codler/Screenshot_78_cni5ju.png',
        },
        {
            link:'/portfolio/ceanor',
            description:'ATK BUILD MART has a huge range of tiles,bathroom-fittings, and sanitary ware with 17 years of expertise.',
            bgcolor:'rgb(128,0,0)',
            color:'white',
            name:'ATK BUILD MART',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655312133/codler/Screenshot_77_dbne4a.png',
        },
    ]

    export const App=[
        {   
            link:'/portfolio/carchilli',
            fontcolor:'white',
            bgcolor:'rgba(128,128,128,0.1)',
            name:'CAR CHILLI',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653398945/codler/Car-01_nhxhkp.webp'
         },
         {  
            link:'/portfolio/krijay',
            fontcolor:'black',
            bgcolor:'rgb(255,255,255)', 
            name:'CRAVELLO',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654719773/codler/download_1_d2xt9o.png'
         },
    ]

    export const Faq=[
        {
            question:'Which city does CODLERR call home? What is the size of the workforce?',
            answer:`Since 2021, we've been a top software development IT company based in Bangalore, India. We have a fantastic staff of around 200 extraordinary people.`
        },
        {
            question:'Is there a paid assistance contract available at your company?',
            answer:`We do. After the project is done, you may request an extension of our project support. However, the cost varies from project to project. You can inquire about this once the company has determined the project's size and scope.`
        },
        {
            question:`What's the time duration to design a website on average?`,
            answer:`The total time it takes to build a website is determined by several factors. A static website often takes less time to design than an e-commerce or flash-based website. Complex websites, such as eCommerce websites, take a little longer due to their difficulty level.`
        },
        {
            question:'Do you have a website that is mobile friendly?',
            answer:`Hire web developers from Codlerr who have experience with the newest HTML5 technologies. It's called responsive web design. We create visually compelling and aesthetically appealing site designs that work on both mobile and desktops. However, these are to be included in your objectives before beginning a website or app development project.`
        },
        {
            question:'Do you create cross-platform mobile apps?',
            answer:`Yes, we produce mobile applications for all of the leading mobile platforms. In addition, we can help you design apps for either one or two media or an app that operates across all mobile devices.`
        },
    
    ]
    export const FaqTest=[
        {
            heading:'general',
            question:'question1',
            answer:'question 1'
        },
        {
            heading:'general',
            question:'question2',
            answer:'question 2'
        },
        {
            heading:'general',
            question:'question3',
            answer:'question 3'
        },

    ]

    export const Branding=[     
    {
        link:'/portfolio/ceanor',
        bgcolor:'rgb(247,202,24)',
        name:'ONDACE',
        description:'Ondace is a focused enterprise that strives on re-defining the way applications are built and tested. The believe in simplifying business solutions.',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655211495/codler/Web_capture_14-6-2022_18225__bn5nuk.jpg',
    },
    {
        link:'/portfolio/krijay',
        bgcolor:'rgb(205,209,228)',
        name:'MEMORIES',
        description:'Memories holdings LLC is a service oriented company which has been in the Property Management service since 1975 and are pioneers in enting and leasing.',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655211495/codler/Web_capture_14-6-2022_18270__jqgxkn.jpg'
    },
    {
        link:'/portfolio/krijay',
        bgcolor:'rgb(205,209,228)',
        name:'EDEZA',
        description:'EDEZA Trading LLC is a UAE based company providing the worlds most extensive modular pipe support line, offering technological solutions for all common industrial applications.',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655310043/codler/edeza2_adnknt.jpg'
    },
    {
        link:'/portfolio/krijay',
        bgcolor:'rgb(255,203,164)',
        name:'ODIKKO',
        description:'Odiko is an online application exclusively for sellers to sell their products and services in neighbourhood areas through one platform.',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655309973/codler/WhatsApp_Image_2022-06-15_at_12.15.39_PM_1_tb5ism.jpg'
    },
    {
        link:'/portfolio/krijay',
        bgcolor:'rgb(112,128,144)',
        name:'MOTORWAGON',
        description:'Motorwagon is a marketplace for petrolheads dealing in pre-owned luxury exotic cars located in the heart of Kerala.',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655309974/codler/WhatsApp_Image_2022-06-15_at_12.14.40_PM_g6jdgb.jpg'
    },
    {
        link:'/portfolio/krijay',
        bgcolor:'rgb(128,0,0)',
        name:'Neu Holz LLC',
        description:'Neu Holz LLC is a supplier of a wide range of interior and exterior wood panel products established and located in the Emirate of Dubai',
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655372710/codler/Web_capture_16-6-2022_15121__wklnzg.jpg'
    },
  
    ]

    export const All=[{
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281811/codler/91770ec6-c0fa-4e7e-8e5a-a3a5d3bffaa2_vndpee.jpg',
        name:'UGRAB',
        },
        {src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281811/codler/0f6690c8-9f80-4f25-982d-505fefa97e29_c3wfew.jpg',
        name:'ODIKKO',
        },
        {
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281817/codler/b22dcc29-fc2b-4aec-ba24-ba1d94e43285_kjewam.jpg',
        name:'SMART COSTECH',
        },
        {
        src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281805/codler/942b5c3e-eeb6-4ad3-8e12-51c80a3ad7b6_vioqxe.jpg',
        name:'KRIJAY',

       },
       {
            name:'CEANOR',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680703/codler/Screenshot_60_zxosay.png',
        },
       {   
           name:'ONDACE',
           src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680682/codler/Screenshot_65_hkhxvo.png'
        },
        {
            link:'/portfolio/ceanor',
            bgcolor:'rgb(247,202,24)',
            name:'ONDACE',
            description:'Ondace is a focused enterprise that strives on re-defining the way applications are built and tested. The believe in simplifying business solutions.',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655211495/codler/Web_capture_14-6-2022_18225__bn5nuk.jpg',
        },
        {
            link:'/portfolio/krijay',
            bgcolor:'rgb(205,209,228)',
            name:'MEMORIES',
            description:'Memories holdings LLC is a service oriented company which has been in the Property Management service since 1975 and are pioneers in enting and leasing.',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655211495/codler/Web_capture_14-6-2022_18270__jqgxkn.jpg'
        },
        {
            link:'/portfolio/krijay',
            bgcolor:'rgb(205,209,228)',
            name:'EDEZA',
            description:'EDEZA Trading LLC is a UAE based company providing the worlds most extensive modular pipe support line, offering technological solutions for all common industrial applications.',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655310043/codler/edeza2_adnknt.jpg'
        },
        {
            link:'/portfolio/krijay',
            bgcolor:'rgb(255,203,164)',
            name:'ODIKKO',
            description:'Memories holdings LLC is a service oriented company which has been in the Property Management service since 1975 and are pioneers in enting and leasing.',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655309973/codler/WhatsApp_Image_2022-06-15_at_12.15.39_PM_1_tb5ism.jpg'
        },
        {
            link:'/portfolio/krijay',
            bgcolor:'rgb(112,128,144)',
            name:'MOTORWAGON',
            description:'Memories holdings LLC is a service oriented company which has been in the Property Management service since 1975 and are pioneers in enting and leasing.',
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1655309974/codler/WhatsApp_Image_2022-06-15_at_12.14.40_PM_g6jdgb.jpg'
        },
      
    ]
   
    export const Portfoliolist=[
        {
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680703/codler/Screenshot_60_zxosay.png',
            website:'www.ceanor.in'
        },
        {
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680690/codler/Screenshot_63_hugwqm.png',
            website:'www.krijay.com/'
        },
        {
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654680682/codler/Screenshot_65_hkhxvo.png',
            website:'www.ugrab.in'
        },
        {
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281811/codler/0f6690c8-9f80-4f25-982d-505fefa97e29_c3wfew.jpg',
            website:'www.ceanor.in'
        },
        {
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654281811/codler/b9e5e354-12b5-4794-8a7c-54f3bd147aaf_qtpyfa.jpg',
            website:'www.ceanor.in'
        },
        {
            src:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653048104/codler/Image_gl7vsx.png',
            website:'www.ceanor.in'
        }

    ]

    export const Blogs=[
        {
            url_title:`is-ux-ui-important-in-a-mobile-application`,
            blog_title:'Is Ux/Ui Important In a Mobile Application ?',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658222006/codler/uxui_p5vmrd.jpg',
            blog_description:'Increasing sales is the primary goal of any business. Mobile applications effectively boost these sales by building direct connections with customers. To gain customers from a mobile app, UI/UX design plays a key role.A dynamic user interface makes the business more accessible. An intuitive user experience keeps the customers engaged and satisfied. Therefore, UI/UX design becomes essential to mobile app development',
            creator_name:"Manasa Mohan",
            creator_image: 'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/Image_4_qeidyk.webp',
            blog_date: 'June 27 2022',
            blog_link: '/blog/ux/ui-important-in-a-mobile-apllication',
            blog_title2:`UI Design`,
            blog_description2:`A mobile app's UI/UX design starts with designing the user interface. UI designs make the app more presentable and interactive. Responsive design elements improve the web performance of the mobile application. UI designers create a unique look for the app based on the targeted users' persona. From touchscreen movements to visual elements, UI design enables the users with new ways of using the mobile application`,
            blog_title3:`UX Design`,
            blog_description3:`The second part of a mobile app's UI/UX design is developing the user experience. UX design is a comprehensive process where app developers work with designers to improve the app's overall experience for users. In simple words, they make the application interact with the users in a better way. UX design primarily deals with maximizing customer satisfaction. It also improves the performance of the mobile application. UX designs use wireframing, information architecture, and visual interactions to create a seamless user flow for the mobile app.  `,
            blog_title4:`Importance of UI/UX Design in Mobile Application Development  `,
            blog_description4:`While developing a mobile app, UI/UX designers have a critical responsibility to deliver the best version of the app. Therefore, every top mobile app development company ensures that the UI/UX design process is executed flawlessly. Here are the main reasons that make UI/UX design important in building mobile apps.  `,
            blog_title5:`Clear Assessment of Target Audience`,
            blog_description5:`Before building a mobile app, UI/UX designers have to team up and understand the client business and their target audience. First, it means understanding the persona of the end-user. Then, it helps decide which elements can make the app more attractive to the targeted audience. A good UI/UX design always hits the right notes with its app audience. It offers features and visual appeal that can meet the prospects' expectations and turn them into customers.  `,
            blog_title6:`Improved ROI from Enhanced Customer Satisfaction  `,
            blog_description6:`Businesses expect mobile applications to provide high returns on their investments. To get that high RoI from a mobile app, UI/UX design teams create engaging content. The app content is tailor-made to help the users with easy navigation, quick access to features and services, and instant payment transactions. A great UI/UX design keeps the app customers satisfied. Therefore, the leads from such apps increase significantly. Customer loyalty and brand awareness also gain traction, thereby driving the overall ROI for your business.`,
            blog_title7:`Efficient Brand Building `,
            blog_description7:`Brand development is integral to mobile app development. UI/UX design garners high investments because they make the clients happy. Your mobile app builds your brand by helping the users relate to the app design theme. UI/UX design can build a brand with:  
            accessible introduction of new products,  
            strong customer recognition,  
            improved customer loyalty,  
            leveraged competitive edge,  
            enhanced credibility through shared values, and  
            Ease of purchase  
            UI/UX designs build good customer relationships and foster the connection between your organization and brand value.`,
            blog_title8:`Time and Cost Optimization`,
            blog_description8:`A mobile app with the best UI/UX design is the best investment. It can save your time on reworking a failed app design. Also, it saves you the costs of redesigning the app. It minimizes the chances of errors when you test the UI/UX designs with your client. You do not need frequent upgrades, and your app does not get stuck in the developing stage. In simple words, UI/UX designs save money and time by helping your business avoid future losses.`,
            blog_title9:`Business repercussions of a Good Mobile App UI/UX Design`,
            blog_description9:`In conclusion, an excellent mobile app UI/UX design is good for business. From a business perspective, it simultaneously emphasizes end-user satisfaction and builds brand value: 
            It improves the reputation of your business. 
            UI/UX designs provide a competitive advantage over your mobile app. 
            A good UI/UX design can attract consumers' attention and influence them to buy your products and services.  
            Codlerr, a leading mobile app developer, holds expertise in UI/UX designing. The app developer has unique UI designs and the most compelling UX elements for mobile apps. Partner with Codlerr and see how its UI designers and UX developers make the most captivating mobile app for you and your customers.`,

  
          },
        {
          url_title:'Branding-in-business',
          blog_title:'Branding in business',
          image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1658145702/codler/brand_ai84hg.jpg',
          blog_description:`Business is a significant pillar of society that allows the wheel of economics to run. It will enable an organization to gain and provide numerous advantages. When you're running a business, it's necessary that you're qualified to do what's needed for the business. 
          Branding is a vital element to any business as it allows the company of a business to upgrade. When you're branding, it's required that you take care of the necessary business principles. In addition, it'll allow you to generate a new audience. 
          We speak about the various approaches to the company that improves the image When we talk about branding in a business. Branding in a business relates to the perspective the public views you, from the description of stationery you use to the announcement scale utilized for your marketing purpose. 
          Branding relates to all plays and aspects of a massive part in the business. Anyone can have a company worldwide, but only a few brands exist. Consider using numerous different companies around you as an illustration. The famous companies around you will undoubtedly possess a distinct and vital role in your life. 
          This is due to the reality that genuinely though it's an essential product of your life, it means much more. When in a continued relationship with the outcomes of a brand, the effect tends to have a vital part in your life. 
          The emotional association and the space that a company has in the life of its consumer is what brands are made of. Therefore, branding in a business is the most effective form of marketing. This form of marketing allows you to generate a positive brand image in front of your consumers.`,
          creator_name:"Manasa Mohan",
          creator_image: 'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/Image_4_qeidyk.webp',
          blog_date: 'July 4 2022',
          blog_link: '/blog/branding-in-business',
          blog_title2:`Definition`,
          blog_description2:`Business branding can be defined in various ways. In addition, numerous other definitions have been solved for many different people. Nevertheless, the fact is that there's no one clear definition of business branding. 
          Significant like how your brand is unique, so is the definition of your business branding. The purpose of business branding is the substance that you'll produce for yourself. It'll allow you to embark upon an expedition to research your brand truly. 
          While at the same time, you'll also be competent in producing your definition of business branding. Depending on your product, business branding involves connecting with your consumers. This connection will allow you to be a part of their life. 
          There are multiple different ways in which business branding will permit it to do so. Branding can be related to an action taken by any company for creation in the simplest ways. The act can involve different forms of business branding. 
          Each of which will be targeted towards possessing a positive effect on the brand itself. To help you understand the business definition, Codlerr has managed to break it down into five different segments. Each of the segments is vital to the purpose of business branding. `,
          blog_title3:`1- Changing Process`,
          blog_description3:`The demand is constantly changing, so a business must be prepared to adjust to modifications. This indirectly and directly implies that a company's definition of business branding is frequently modified. A business should be prepared for transformation and advancement at all times. A company must change as per its requirements. `,
          blog_title4:`2- Identification, innovation, and management `,
          blog_description4:`Identification, innovation, and management are a path that is important to the business branding definition — appropriately administered in the proper order. They deliver the business with all the necessary elements to be successful. Most businesses depend heavily on identification, innovation, and management. `,
          blog_title5:`3- Assets and their application`,
          blog_description5:`A company has numerous different assets. The assets contain products, services, jobholders, and other allied categories. Applying these assets, like selling and client service, is significant. It allows for the capability of significantly influencing the definition of business branding. `,
          blog_title6:`4- Reputation `,
          blog_description6:`A considerable part of your business branding is the reputation you possess as a company. The brand image of a company is forever essential. For this very reason, you'll find that the importance of a company is a massive part of the definition. `,
          blog_title7:`5- Stakeholders `,
          blog_description7:`Stakeholders are vital people that straightway impact a business. Stakeholders will play a significant part when you're looking to reason the definition of your business branding. Stakeholders are all people who play an essential element in your business.Jobholders, shareholders, clients, and possessors, all of people, are part of what makes your branding definition. `,

        },
        {
          url_title:'What-is-Mobile-Application-Development',
          blog_title:'What is Mobile Application Development?',
          image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1658145837/codler/Mobile_o5b5lh.jpg',
          blog_description:'Mobile application development is the set of strategies and procedures involved with writing software for small, wi-fi computing gadgets, smartphones, and other gadgets....',
          creator_name:"Manasa Mohan",
          creator_image: 'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/Image_4_qeidyk.webp',
          blog_date: 'July 11 2022',
          blog_link: '/blog/what-is-mobile-application-development',
          blog_title2:``,
          blog_description2:``,
          blog_title3:``,
          blog_description3:``,
          blog_title4:``,
          blog_description4:``,
          blog_title5:``,
          blog_description5:``,
          blog_title6:``,
          blog_description6:``,
          blog_title7:``,
          blog_description7:``,

        },
        {
            url_title:'Branding–Narrating-a-story',
            blog_title:'Branding – Narrating a story ',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658222005/codler/branding2_l24sg2.jpg',
            blog_description:'Do you consistently buy the cheapest accessible product or service, regardless of the brand behind it? Even if you re guaranteed an equal quality level from both, you will probably still oblige a particular brand.....',
            creator_name:"Manasa Mohan",
            creator_image: 'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/Image_4_qeidyk.webp',
            blog_date: 'July 18 2022',
            blog_link: '/blog/Branding-Narrating-a-story',
            blog_title2:``,
            blog_description2:``,
            blog_title3:``,
            blog_description3:``,
            blog_title4:``,
            blog_description4:``,
            blog_title5:``,
            blog_description5:``,
            blog_title6:``,
            blog_description6:``,
            blog_title7:``,
            blog_description7:``,

          },
          

    ]

  export const technologies=[
    {
        title:'Mobile',
        image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653318340/codler/s0_wpdqp6.png',
        description:'Resources with average experience of 5 years and tech expertise across the domains of native iOS, Android, cross platform, and hybrid apps.',
        technology_inner:[
            {
            title:'iOS Apps Development',
            image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1658490505/codler/Vector_xgj05o.png',
            description:'Crafting apps for iPhone, iPad, Apple Watch, Apple TV, etc. with AI & ML integration and more, keeping users at the core.'
           },
           {
            title:'Android Apps Development',
            image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1658490505/codler/Vector_1_fxe3zk.png',
            description:'Our dev team helps you craft native Android apps for Smartphones, Tablets, Android Wear, and Android TV from the scratch'
           },
           {
            title:'React Native Apps Development',
            image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1658490505/codler/Vector_2_jztunb.png',
            description:'Delivering affordable yet fast, powerful, and high performing cross-platform apps with native look and feel.'
           },
           {
            title:'Flutter Apps Development',
            image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1658490505/codler/Vector_3_ewwgcw.png',
            description:'Ever since its existence, we have been crafting native and cross-platform apps using this open source mobile SDK.'
           },
           {
            title:'Ionic Apps Development',
            image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1658490503/codler/g12_bu4ujl.png',
            description:'Crafting fast and interactive hybrid applications that work perfectly on web, mobile and desktop devices using Ionic.'
           },
           {
            title:'CMS',
            image:'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1658490505/codler/Vector_4_zqxezi.png',
            description:'Our hands-on experience with popular CMS tools helps enterprises to create and manage their digital content with greater flexibility and ease.'
           },
    ]

    },
    {
        title:'Front-end',
        image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745476/codler/s4_zbpbai.png',
        description:'Delivering visually appealing, interactive, smooth, user-friendly, and high performing front ends with our 22+ years of industry experience.',
        technology_inner:[
            {
            title:'Angular',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745024/codler/angular_qbz1zh.png',
            description:'Angular is a structural front-end framework for dynamic apps. It is a client-side open-source platform that creates single-page web applications. The custom-based application is written in CSS, HTML, and Typescript. The framework is developed and supported by Google.'
           },
           {
            title:'Reacts',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745022/codler/react_nbtwth.png',
            description:'React is a UI development library and not a framework or a language. However, it is extensively used in development for handling view layers for mobile and web apps.Its primary purpose is to be extensive, fast, simple, and flexible the developer community can use that.'
           },
           {
            title:'Vuejs',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745024/codler/vue_bfowzs.png',
            description:'It is a JavaScript open-source front-end framework for building a user interface (UIs) and one/single-page applications (SPIs). It is adaptable and easy to integrate with existing projects or other libraries.It is perfect for running sophisticated SPA when used with modern tooling and supporting libraries.'
           },
           {
            title:'Typescript',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745023/codler/typescpt_ybbbpc.png',
            description:'Typescript is like a superset of JavaScript. It primarily focuses on classes, static typing, and interfaces. It uses JavaScript syntaxes and adds more syntaxes for support.It can be installed anywhere where JavaScript runs. And one needs to use a typescript compiler to compile TypeScript code into plain JavaScript.'
           },
           {
            title:'HTML',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745024/codler/html_cqoe3x.png',
            description:'HTML is an abbreviation for Hypertext Markup Language. It defines the basic building block of the web. It allows the users to create and modify the structure, paragraphs, sections, and links using Tags, elements, and other attributes.While hypertext allows linking web pages to each other on either a single website or between multiple sites.'
           },
           {
            title:'Progressive web developmen',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745024/codler/web_develop_h2c1xe.png',
            description:'Progressive Web App or PWA is a technology that integrates mobile app functionality to websites. It combines web and mobile applications for features like offline working, push notifications, etc.'
           },
    ]

    },
    {
        title:'Backend',
        image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745475/codler/s1_rpzrzd.png',
        description:'Great importance to backend is essential for a successful software and our expertise in Java, NodeJS, .NET, PHP, Python, etc. help you achieve it.',
        technology_inner:[
            {
            title:'Node',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745052/codler/node_n4e8ks.png',
            description:'Node is an open-source cross-platform used for server-side programming. The library is primarily installed for non-blocking, event-driven servers like backend API services and traditional websites.It is a compelling JavaScript-based platform for developing video streaming sites, SPAs, online chat applications, and other I/O-intensive web applications.'
           },
           {
            title:'Java',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658746806/codler/icons8-java-48_y6kqdu.png',
            description:'Java is a multi-platform, class-based, object-oriented, and network-centric programming language. It is known for lesser implementation dependencies and is the most popular programming language.Java is used as a computing platform for app development. It is fast, reliable, and secure and is used in developing java applications for data centers, game consoles, laptops, supercomputers, mobile phones, etc.'
           },
           {
            title:'Python',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745053/codler/python_gondbh.png',
            description:'One of the most recognized programming languages around the world. It is used to develop everything, i.e., machine learning to build websites, software testing, automated tasks, and data analysis.The programming language is beginner-friendly, and its versatility makes it popular among developers and non-developers for creating programs.'
           }
    ]

    },
    {
        title:'Database',
        image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745475/codler/s2_ohg7fa.png',
        description:'Our expertise in multiple database technologies helps you to find the perfect fit for your project based on the requirements and user base.',
        technology_inner:[
            {
            title:'MySQL',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745078/codler/mysql_q2wgqy.png',
            description:'MySQL is a popular, time-tested, open-source relational database management system (RDBMS). It is developed by Oracle that is based on structured query language (SQL).It helps to store data in tables in the form of rows and columns. As a result, users can easily define, manipulate, control, and query data using SQL.MySQL is used for storing and retrieving data of popular applications, websites, and services.'
           },
           {
            title:'MongoDB',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745078/codler/mongodb_wm8xsb.png',
            description:'MongoDB is a database used for storing high-volume data as it does not use the traditional way of storing data in rows or columns, so it is categorized as NoSQL. Instead, it uses collections and documents for storage.Popular languages such as C, C++, C#, and .NET, Go, Java, Node.js, Perl, PHP, Python, Motor, Ruby, Scala, Swift, and Mongoid use MongoDB official driver support.Many big names like Facebook, Nokia, eBay, Adobe, etc., use MongoDB to store large amounts of data.'
           },
           {
            title:'PostgresSql',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745078/codler/postgresql_dycgzm.png',
            description:'Also known as Postgres is an open-source relational database management system (DBMS).PostgreSQL runs on all operating systems, including HP-UX, UNIX (AIX, BSD, SGI IRIX, Linux, Mac OS X, Solaris, Tru64), and Windows. It handles various use cases, from single machines to data warehouses to web services with several parallel users.It supports all text, images, sounds, and video and includes programming interfaces for C / C++, Java, Perl, Python, Ruby, Tcl, and Open Database Connectivity (ODBC).'
           },
           {
           title:'Firebase',
           image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745078/codler/firebase_tiwbx5.png',
           description:'Firebase is a Backend-as-a-Service, popularly known as Baas. It is a toolset for developers to help them develop, improve quality apps, grow their user base, and earn profit. Firebase is categorized as a NoSQL database program.Firebase helps developers focus on app experience rather than creating everything independently from scratch.'
          }
    ]

    },
    {
        title:'Infra-and-DevOps',
        image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745475/codler/s5_gmhzxf.png',
        description:'We help you transform your business with automating infrastructure, software development and deployment processes using various DevOps tools.',
        technology_inner:[
            {
            title:'Azure',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745113/codler/azure_mlovio.png',
            description:'Azure is a Microsoft cloud computing service similar to google cloud. Azure is an online platform that allows users to access and manage all services offered by Microsoft. The services provided by this cloud computing platform include storing and transforming your data according to the need. You only need a stable and active internet connection and connection to the Azure portal to avail yourself of services.'
           },
           {
            title:'Google Cloud',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745114/codler/googlecloud_whzzb6.png',
            description:'Google Cloud is a public cloud computing platform that offers services like Computing, Networking, Machine Learning, AI, Big Data Processing, etc. Google provides the service and offers the same infrastructure used for its end-user products like YouTube, Gmail, etc.'
           }
    ]

    },
    {
        title:'CMS',
        image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745475/codler/s3_ordd3k.png',
        description:'Our hands-on experience with popular CMS tools helps enterprises to create and manage their digital content with greater flexibility and ease.',
        technology_inner:[
            {
            title:'WordPress',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745149/codler/wordpress_vvqu9e.png',
            description:'WordPress is an open-source content management system (CMS) that helps anyone create websites and blogs. It does not require prior coding knowledge; hence, it is popular among individuals without coding experience who want to build their websites and blogs.It is free to install and use for beginners. It also offers many paid plug-ins options for professional and business use.'
           },
           {
            title:'Strapi',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745149/codler/strapi_xeldkj.png',
            description:'Strapi is a free and open-source headless content management system (CMS). It helps develop websites, mobile applications, eCommerce sites, and APIs.The system builds the APIs automatically to link your front-end to the backend. Therefore, you do not need any knowledge about the backend or databases.It is simple to learn and use for beginners and can be used with React, Vue, Nuxt.js, Next.js, Angular, Svelte, Sapper, and Flutter.'
           },
           {
            title:'Magento',
            image:'https://res.cloudinary.com/dpsamxitw/image/upload/v1658745149/codler/magento_uguzw2.png',
            description:`Magento is open-source technology built in PHP that allows users to control their online store's interface, functionality, and content without compromising customers' shopping experience.It is presently one of the best e-commerce platforms for users, providing additional features and tools like marketing SEO (search engine optimization), catalogue management, etc.`
           }
    ]

    }
   


]