import react,{useEffect, useState} from 'react';
import { Helmet } from 'react-helmet'
import '../digital-marketing.css';
import SlideR from '../component/sliders/digital-market-slider';
import { DigitaGallery } from '../constants/digital-data';
import {toast} from 'react-toastify'
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import HubspotForm from 'react-hubspot-form';
import Popup from '../component/popup-from';


function Digitalmarketing() {

  

  // form submission

    const [data,setData] = useState([]);
    const [collection,setCollection] = useState([]);

    useEffect(() => {
      setData(DigitaGallery);
      setCollection([... new Set(DigitaGallery.map((item)=> item.title))])
    },[])
   

    const ImageFilter =(itemData) =>{
        const filterData = DigitaGallery.filter((item) => item.title == itemData)
        setData(filterData);
    }


    const navigate = useNavigate('');
    const [showPop, setshowPop] = useState(false);


        // ------------- //

        const[msg, setMsg] = useState({
          email: ""
      })
  
      const handleChange = (event) => {
          let name = event.target.name;
          let value = event.target.value;
  
          setMsg({...msg, [name]:value})
      }
  
  
      const handleSubmit = async (event)=> {
          event.preventDefault();
          const {email} = msg;
          try{
              const res = await fetch('https://codlerbackend.onrender.com/admin/email/message', {
                  method: "POST",
                  headers: {
                      "Content-Type" : "application/json"
                  },
                  body : JSON.stringify({
                       email
                  })
              })
              console.log(res.status);
              if(res.status === 400 || !res){
                  toast.error('mail not sent.',{
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  })
              }else{
                  toast.dark('Your mail has been sent successfully.',{
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  })
                  setMsg({
                      email: ""
                  })
              }
          }catch (error) {
              console.log(error);
          }
      }
      // ------------- //
  return (
    <div className='bg-white'>


          <div className='w-10/12 md:w-11/12 lg:w-10/12 mx-auto pt-6 md:pt-10 flex justify-between'>
                <img className='cursor-pointer h-[35px]' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/log_blackk_m2lwoy.png' alt='image'></img>
                <img className='cursor-pointer w-[30px] h-[20px]' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193218/codler%20branding%20page/humberger_slxg7f.png' alt='image'></img>
          </div>
    
          <section className='md:pl-10 lg:pl-32 '>
            <div className='grid grid-cols-1 md:grid-cols-5'>
                    <div className='w-10/12 mx-auto md:w-full col-span-2 pt-20 lg:pt-44'>
                      <h1 className='capitalize font-gilroybold text-4xl lg:text-5xl'>A creative Approach To Digital marketing</h1>
                      <p className='py-2 md:py-10 font-gilroyregular text-xl lg:w-[80%] capitalize'>Every business is different and requires individualised care, in our opinion.</p>

                         
                            
                            <div className="relative">
                                <a className="absolute no-underline hover:no-underline  text-transparent btn-effect border-2 border-[#D028DA] text-xs lg:text-base px-10 py-2.5 lg:px-14 lg:py-2.5 rounded-md cursor-pointer" onClick={() => setshowPop(true)}>let's Talk</a>
                                <a className="absolute no-underline hover:no-underline text-white hover:text-white hover:ml-1.5 hover:-mt-1.5  btn-effect border-2 border-[#D028DA] text-xs lg:text-base px-10 py-2.5 lg:px-14 lg:py-2.5 rounded-md bg-gradient-to-r from-[#D729DE] to-[#7521A8] cursor-pointer" onClick={() => setshowPop(true)}>let's Talk</a>
                         </div>
                         <div className="relative pl-36 lg:pl-52">
                                <a className="absolute no-underline hover:no-underline  text-transparent hover:text-white btn-effect border-2 border-gray-800 text-xs lg:text-base px-6 py-2.5 lg:px-7 lg:py-2.5 rounded-md bg-white" href="#">Success Stories</a>
                                <a className="absolute no-underline hover:no-underline text-gray-800  hover:text-white hover:ml-1.5 hover:-mt-1.5  btn-effect hover:border-2 border-[#D028DA] text-xs lg:text-base px-6 py-2.5 lg:px-7 lg:py-2.5 rounded-md hover:bg-gradient-to-r from-[#D729DE] to-[#7521A8] cursor-pointer" href='#contact'>Success Stories</a>
                        </div>
                        
                        <div className='mt-10 lg:mt-32'>
                            <div  className="grid grid-cols-3 lg:w-[450px] gap-5 place-items-center md:place-items-start mt-24  ">
                                <div className="grid place-items-center ">
                                <p className="text-3xl lg:text-5xl  font-gilroysemibold bg-black p-2 overflow-hidden text-white"><span className='bg-[#2e2d2d] p-1'>7</span><span className='bg-[#2e2d2d] p-1'>0</span><span className='bg-[#2e2d2d] p-1'>+</span></p>
                                <h6 className=" uppercase text-xs tracking-widest font-gilroysemibold">Clients</h6>
                                </div>
                                <div className="grid place-items-center  ">
                                <p className="text-3xl lg:text-5xl  font-gilroysemibold bg-black p-2 overflow-hidden text-white"><span className='bg-[#2e2d2d] p-1'>1</span><span className='bg-[#2e2d2d] p-1'>0</span><span className='bg-[#2e2d2d] p-1'>0</span><span className='bg-[#2e2d2d] p-1'>+</span></p>
                                <h6 className=" uppercase text-xs tracking-widest font-gilroysemibold">Projects</h6>
                                </div>
                                <div className="grid place-items-center -ml-[16px] lg:-ml-0">
                                <p className="text-3xl lg:text-5xl  font-gilroysemibold bg-black p-2 overflow-hidden text-white"><span className='bg-[#2e2d2d] p-1'>7</span><span className='bg-[#2e2d2d] p-1'>+</span></p>
                                <h6 className=" uppercase text-xs tracking-widest font-gilroysemibold">Countries</h6>
                                </div>
                              </div>

                              
                        </div>
                        
                    </div>
                    <div className='col-span-3 banner-image'>
                      <div className='w-10/12 mx-auto md:w-full pt-20 md:pt-36 '>
                        <iframe className=' bg-gradient-to-r from-[#D729DE] to-[#7521A8] p-2 rounded-[2rem] w-full h-44 md:h-60 lg:w-[660px] lg:h-[450px]' src="https://www.youtube.com/embed/PYP4ZGVpLTk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    </div>
            </div>

          </section>


          {/* sample */}
          <div className='grid place-items-center'>
                      <Popup isVisible={showPop} onClose={() => setshowPop(false)} />
          </div>
          {/* sample */}



            {/* we are */}
            <div className='md:hidden lg:block'>
            <section className='we-are grid place-items-center '>
                <div className='w-10/12 mx-auto grid grid-cols-1 md:grid-cols-3  lg:gap-20'>
                    <div className="bg-[#ffffff63] rounded-[2rem] text-white pl-8 my-10 md:my-0">
                      <h2 className="font-gilroybold text-4xl pt-6">We Are<br/> Here To Help.</h2>
                      <p className="font-gilroyregular">We're Here To Help You Align, Refine.</p>
                      <p className="font-gilroyregular">Design And Build.</p>
                      <LazyLoadImage src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193221/codler%20branding%20page/young-woman-yellow-warm-sweater-with-megaphone-speaker-screaming-left-pointing-index-finger_mdixkz.webp' alt=''></LazyLoadImage>
                  </div>


                    <div className='md:col-span-2 grid place-items-center '>
                          <div className='grid grid-cols-1 md:grid-cols-3 gap-10 mb-10 md:mb-0'>
                                <div className='relative p-3  border-2 border-[#ffffff80] rounded-[2rem] bg-white text-[#CC18BC]'>
                                      <h3 className="font-gilroybold text-2xl uppercase ">search<br/> engine<br/> optimization</h3>
                                      <p className=" text-sm  py-3">We make your job easier. We leverage cutting-edge SEO techniques to help your website reach the top of all search engines.</p>
                                      <img className="absolute top-5 right-5 h-10" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193215/codler%20branding%20page/Group_466_zq97jh.svg" alt="image" />
                                      <span className="absolute bottom-2 right-2 cursor-pointer"><img className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circle_p1kgom.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-3  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-2xl uppercase ">Pay per<br/> click<br/> management</h3>
                                      <p className=" text-sm  py-3">Codlerr's team of experienced professionals knows how to help you find the right platform, bid correctly, and track results to get the most out of your money.</p>
                                      <img className="absolute top-5 right-5 h-10" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193215/codler%20branding%20page/Group_464_lhjghh.svg" alt="image" />
                                      <span className="absolute bottom-0 right-0 cursor-pointer"><img className='h-14' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-3  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-2xl uppercase ">STRATEGIC<br/> DIGITAL<br/> MARKETING</h3>
                                      <p className=" text-sm  py-3">Strategic Digital Marketing Services. Codlerr is a full-service digital marketing agency helping small businesses and startups succeed online.</p>
                                      <img class="absolute top-5 right-5 h-10" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193215/codler%20branding%20page/Group_464_lhjghh.svg" alt="image" />
                                      <span class="absolute bottom-0 right-0 cursor-pointer"><img className='h-14' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-3  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-2xl uppercase ">SOCIAL MEDIA<br/> MARKETING </h3>
                                      <p className=" text-sm  py-3">We make social media work for you. Our social media marketing services are designed to help businesses of all sizes and industries grow their online presence.</p>
                                      <img className="absolute top-5 right-5 h-10" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193215/codler%20branding%20page/Group_465_s7wtbf.svg" alt="image" />
                                      <span className="absolute bottom-0 right-0 cursor-pointer"><img className='h-14' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-3  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-2xl uppercase ">BRANDING<br/><br/></h3>
                                      <p className=" text-sm  py-3">At Codlerr, we believe every business deserves the possibility to stand out from the crowd. We create & manage successful branding campaigns for businesses of all sizes.</p>
                                      <img className="absolute top-5 right-5 h-10" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193216/codler%20branding%20page/Group_467_a47rym.svg" alt="image" />
                                      <span className="absolute bottom-0 right-0 cursor-pointer"><img className='h-14' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-3  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-2xl uppercase ">WEB<br/> DEVELOPMENT<br/></h3>
                                      <p className=" text-sm  py-3">Expert web developers that care about delivering good value are what you'll find working with us.We value feedback as it helps us better serve our customers and achieve their objectives.</p>
                                      <img className="absolute top-5 right-5 h-10" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193216/codler%20branding%20page/Group_467_a47rym.svg" alt="image" />
                                      <span className="absolute bottom-0 right-0 cursor-pointer"><img className='h-14' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                               
                          </div>
                  </div>
                </div>
                
          </section>
          </div>


          {/* Mediam device code */}
          <section className='we-are grid place-items-center hidden md:block lg:hidden py-3'>
                <div className='w-11/12 mx-auto grid grid-cols-1 md:grid-cols-3 md:gap-5 lg:gap-20'>
                    <div className="bg-[#ffffff63] rounded-[2rem] text-white pl-8 my-10 md:my-0">
                      <h2 className="font-gilroybold text-2xl pt-6">We Are<br/> Here To Help.</h2>
                      <p className="font-gilroyregular">We're Here To Help You Align, Refine.</p>
                      <p className="font-gilroyregular">Design And Build.</p>
                      <LazyLoadImage src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193221/codler%20branding%20page/young-woman-yellow-warm-sweater-with-megaphone-speaker-screaming-left-pointing-index-finger_mdixkz.webp' alt=''></LazyLoadImage>
                  </div>


                    <div className='md:col-span-2 grid place-items-center'>
                          <div className='grid grid-cols-1 md:grid-cols-3 gap-3 mb-10 md:mb-0'>
                                <div className='relative p-1.5  border-2 border-[#ffffff80] rounded-[2rem] bg-white text-[#CC18BC]'>
                                      <h3 className="font-gilroybold text-base uppercase ">search<br/> engine<br/> optimization</h3>
                                      <p className=" text-[11px]  pb-2">We make your job easier. We leverage cutting-edge SEO techniques to help your website reach the top of all search engines.</p>
                                      <img className="absolute top-5 right-2 h-5" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193215/codler%20branding%20page/Group_466_zq97jh.svg" alt="image" />
                                      <span className="absolute bottom-2 right-2 cursor-pointer"><img className='h-7' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circle_p1kgom.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-1.5  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-base uppercase ">Pay per<br/> click<br/> management</h3>
                                      <p className=" text-[11px]  pb-2">Codlerr's team of experienced professionals knows how to help you find the right platform, bid correctly, and track results to get the most out of your money.</p>
                                      <img className="absolute top-5 right-2 h-5" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193215/codler%20branding%20page/Group_464_lhjghh.svg" alt="image" />
                                      <span className="absolute bottom-0 right-1 cursor-pointer"><img className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-1.5  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-base uppercase ">STRATEGIC<br/> DIGITAL<br/> MARKETING</h3>
                                      <p className=" text-[11px]  pb-2">Strategic Digital Marketing Services. Codlerr is a full-service digital marketing agency helping small businesses and startups succeed online.</p>
                                      <img class="absolute top-5 right-2 h-5" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193215/codler%20branding%20page/Group_464_lhjghh.svg" alt="image" />
                                      <span class="absolute bottom-0 right-1 cursor-pointer"><img className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-1.5  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-base uppercase ">SOCIAL MEDIA<br/> MARKETING </h3>
                                      <p className=" text-[11px]  pb-2">We make social media work for you. Our social media marketing services are designed to help businesses of all sizes and industries grow their online presence.</p>
                                      <img className="absolute top-5 right-2 h-5" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193215/codler%20branding%20page/Group_465_s7wtbf.svg" alt="image" />
                                      <span className="absolute bottom-0 right-1 cursor-pointer"><img className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-1.5  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-base uppercase ">BRANDING<br/><br/></h3>
                                      <p className=" text-[11px]  pb-2">At Codlerr, we believe every business deserves the possibility to stand out from the crowd. We create & manage successful branding campaigns for businesses of all sizes.</p>
                                      <img className="absolute top-5 right-2 h-5" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193216/codler%20branding%20page/Group_467_a47rym.svg" alt="image" />
                                      <span className="absolute bottom-0 right-1 cursor-pointer"><img className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                                <div className='relative p-1.5  border-2 border-[#ffffff80] rounded-[2rem] bg-[#ffffff63] text-white'>
                                      <h3 className="font-gilroybold text-base uppercase ">WEB<br/> DEVELOPMENT<br/></h3>
                                      <p className=" text-[11px]  pb-2">Expert web developers that care about delivering good value are what you'll find working with us.We value feedback as it helps us better serve our customers and achieve their objectives.</p>
                                      <img className="absolute top-5 right-2 h-5" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193216/codler%20branding%20page/Group_467_a47rym.svg" alt="image" />
                                      <span className="absolute bottom-0 right-1 cursor-pointer"><img className='h-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/Icon_feather-arrow-right-circleas_vysvok.svg' alt='image'></img></span>
                                </div>
                               
                          </div>
                  </div>
                </div>
                
          </section>

          {/* Mediam device code */}

          {/* we are */}

          {/* about us */}
          <section className='w-10/12 lg:w-full md:w-11/12 lg:pl-32 mx-auto'>
              <div className="pt-10 lg:pt-24">
                <img className="pb-1 w-[90px]" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/lineee_pcsxqy.png" alt="" />
                <h3 className="font-gilroysemibold text-lg">ABOUT US</h3>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:pb-16">
            
                  <div>
                      <h1 className="font-gilroybold text-4xl md:text-2xl lg:text-5xl py-3">OUR DIGITAL MARKETING:</h1>
                      <p className="text-blue-800 text-2xl md:text-base lg:text-2xl font-gilroysemibold">Our marketing approach is distinct from other organizations, and we provide superior Digital Marketing Solutions to boost your company's ROI. Our digital marketing professionals focus on customer satisfaction and will establish your brand online. Our Digital Marketing Methods aim to promote your company.</p>
                      
                          <LazyLoadImage effect='blur' className="lg:h-24 mt-10" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193221/codler%20branding%20page/partner-badges_rsqm4q.png" alt="" />
                  </div>
                  <div>
                      <LazyLoadImage effect='blur' className="md:-mt-10" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193217/codler%20branding%20page/Group_480_ro1znv.svg" alt="" />
                  </div>
              </div>
          </section>

          <section className='w-11/12 lg:w-10/12 mx-auto'>
              <div className="grid grid-cols-1 md:grid-cols-4 bg-gradient-to-r from-[#D029DA] to-[#9424B9] place-items-center py-4 rounded-[2rem] gap-5 md:gap-0 rounded-tr-none">
                <div className="md:flex">
                <a href="tel:+917012696630"><LazyLoadImage className='md:h-10 lg:h-full'  src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193213/codler%20branding%20page/Group_429_urowil.svg" alt="" /></a>
                    <a className="no-underline hover:no-underline grid place-items-center text-white" href="tel:+917012696630">+91 7012696630</a>
                </div>
                <div className="md:flex">
                    <a onClick={() => navigate('/Contactus')}><LazyLoadImage className='md:h-10 lg:h-full cursor-pointer'  src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193213/codler%20branding%20page/Group_425_ovq0gm.svg" alt="" /></a>
                    <a className="no-underline cursor-pointer hover:no-underline grid place-items-center text-white" onClick={() => navigate('/Contactus')}>info@codler.com</a>
                </div>
                <div className="md:flex">
                    <a href="https://instagram.com/codlerr?igshid=YmMyMTA2M2Y="><LazyLoadImage className='md:h-10 lg:h-full'  src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193213/codler%20branding%20page/Group_427_tit0wp.svg" alt="" /></a>
                    <a className="no-underline hover:no-underline grid place-items-center text-white" href="https://instagram.com/codlerr?igshid=YmMyMTA2M2Y=">Codlerr</a>
                </div>
                <div className="md:flex">
                    
                    <a className="no-underline hover:no-underline grid place-items-center text-black bg-white rounded-2xl rounded-tr-none hover:rounded-tr-2xl transition-all ease-in duration-300 px-16 md:px-10 lg:px-16 py-3 cursor-pointer" onClick={() => navigate('/Contactus')}>Let's Talk</a>
                </div>
            </div>
          </section>



          {/* success */}
          <section className='overflow-hidden'>
              <div>
                <LazyLoadImage effect='blur' className='w-[80%] z-0' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193218/codler%20branding%20page/Group_24630_jkq3c7.png'></LazyLoadImage>
              </div>

            {/* content */}
                <div className='md:-mt-[200px] lg:-mt-[370px] z-50 relative'>
                      <div className='w-10/12 mx-auto md:w-full grid place-items-center'>
                        <img src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/lineee_pcsxqy.png' alt=''></img>
                        <h1 className='font-gilroybold text-4xl lg:text-5xl text-center py-3'>Success Stories</h1>
                        <p className='text-xl lg:text-2xl font-gilroysemibold text-center md:w-[40%]'>See how we help our clients grow their business, achieve their goals, and dominate their markets by taking advantage of our multi-channel expertise.</p>
                      </div>

                      {/* grid */}

                      <div className='w-10/12 md:w-11/12 lg:w-8/12 mx-auto grid grid-cols-1 md:grid-cols-2 gap-5 success'>
                        <div className='bg-white border-2 border-[#8322AF] rounded-[2.5rem] grid place-items-center text-center'>
                          <LazyLoadImage  className=' md:h-28 mt-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193220/codler%20branding%20page/logo2323_uccdfg.png' alt=''></LazyLoadImage>
                            <div className='py-4'>
                              <h4 className='font-gilroybold text-2xl md:text-4xl'>Krijay Technologies</h4>
                              <h5 className='font-gilroysemibold text-xl md:text-3xl'>SMM, SEO</h5>
                            </div>

                            <div className='w-full grid place-items-center  pb-20 '>
                                <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'><span class=" font-gilroybold border-b-4 border-[#CC18BC]">100% increase</span> in LinkedIn followers</p>
                              <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'>Built SEO optimized website</p>
                              <p className='text-lg font-gilroymedium text-white'>Built SEO optimized website</p>
                            </div>
                        </div>
                        <div className='bg-white border-2 border-[#8322AF] rounded-[2.5rem] grid place-items-center text-center'>
                          <LazyLoadImage  className=' md:h-28  mt-16' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193220/codler%20branding%20page/Mask_Group_40_mrywex.png' alt=''></LazyLoadImage>
                            <div className='py-'>
                              <h4 className='font-gilroybold text-2xl md:text-4xl'>Ugrab</h4>
                              <h5 className='font-gilroysemibold text-xl md:text-3xl'>SMM, Influencer Marketing</h5>
                            </div>

                            <div className='w-full grid place-items-center md:-mb-12 pb-20 '>
                              <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'><span class=" font-gilroybold border-b-4 border-[#CC18BC]">99% Increase</span> in Instagram followers</p>
                              <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'>Increased growth <span class=" font-gilroybold border-b-4 border-[#CC18BC]">through performance marketing</span></p>
                              <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'><span class=" font-gilroybold border-b-4 border-[#CC18BC]">85k+ views</span> through influencer marketing</p>
                            </div>
                        </div>
                        <div className='bg-white border-2 border-[#8322AF] rounded-[2.5rem] grid place-items-center text-center'>
                          <LazyLoadImage  className='  mt-10 ' src='http://res.cloudinary.com/dvbplh4z9/image/upload/v1668156179/codler%20digital%20marketing/odikko_ivtd9q.png' alt=''></LazyLoadImage>
                            <div className='py-4'>
                              <h4 className='font-gilroybold text-2xl md:text-4xl'>Odikko</h4>
                              <h5 className='font-gilroysemibold text-xl md:text-3xl'>Social Media Marketing</h5>
                            </div>

                            <div className='w-full grid place-items-center  pb-20 '>
                                <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'><span class=" font-gilroybold border-b-4 border-[#CC18BC]">99% Increase</span> in Instagram followers</p>
                              <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'>Service expansion 10 cities</p>
                              <p className='text-lg font-gilroymedium text-white'>Service expansion 10 cities</p>
                            </div>
                        </div>
                        <div className='bg-white border-2 border-[#8322AF] rounded-[2.5rem] grid place-items-center text-center'>
                          <LazyLoadImage  className=' md:h-16 md:w-[60%] mt-[70px]' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668073874/codler%20digital%20marketing/Ondace_Logo_i6jzcy.png' alt=''></LazyLoadImage>
                            <div className='py-'>
                              <h4 className='font-gilroybold text-2xl md:text-4xl'>Ondace</h4>
                              <h5 className='font-gilroysemibold text-xl md:text-3xl'>SMM, Influencer Marketing</h5>
                            </div>

                            <div className='w-full grid place-items-center md:-mb-12 pb-20 '>
                              <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'><span class=" font-gilroybold border-b-4 border-[#CC18BC]">30% increase</span> in engagement on LinkedIn in a month</p>
                              <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'>Optimized LinkedIn <span class=" font-gilroybold border-b-4 border-[#CC18BC]">profiles for professional growth</span></p>
                              <hr class='bg-[#D028DA] h-[4px] w-[80%] '/>
                              <p className='text-lg font-gilroymedium'><span class=" font-gilroybold border-b-4 border-[#CC18BC]">Built search engine</span> optimized website</p>
                            </div>
                        </div>
                      </div>
                </div>
               
                    <div className="py-20 grid place-items-center">
                          <a className=" no-underline hover:no-underline text-white hover:text-white   btn-effect border-2 border-[#D028DA] text-xs lg:text-base px-10 py-2.5 lg:px-14 lg:py-2.5 rounded-md bg-gradient-to-r from-[#D729DE] to-[#7521A8] cursor-pointer" onClick={() => navigate('/Contactus')}>let's Talk</a>
                        </div>
                
          </section>

              {/* download */}
          <section className='w-10/12 md:w-full mx-auto lg:pr-28 relative  overflow-hidden'>
                <div className='grid grid-cols-1 md:grid-cols-3 place-items-center'>
                    <LazyLoadImage  className='md:-mr-40 lg:-mr-56 z-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667282428/codler%20branding%20page/Mask_Group_43_s3oz8s.webp' alt=''></LazyLoadImage>
                    <div className='-z-0 h-fit md:pl-32 py-8 px-10 md:col-span-2 text-white bg-gradient-to-r from-[#D729DE] to-[#7521A8] rounded-b-[2.5rem]'>
                      <h4 className='font-gilroysemibold text-3xl md:text-xl  lg:text-3xl'>Your eCommerce Holiday Guide - 2022 Edition</h4>
                      <h5 className='font-gilroylight text-2xl md:text-lg lg:text-2xl md:w-[90%]'>Make this your biggest holiday sales season yet with these latest strategies and tactics.</h5>
                          
                          <form className='pt-10 md:pt-0 lg:pt-10 flex flex-col md:flex-row gap-5'>
                            <input required className='w-full md:w-[70%] bg-transparent border-b-2 border-[#f7f7f76e] outline-none text-2xl' type='email' placeholder='Email ID'></input>
                            <button className='' type='submit'><a className="no-underline hover:no-underline grid place-items-center text-black bg-white rounded-2xl rounded-tr-none hover:rounded-tr-2xl transition-all ease-in duration-300 px-16 py-3" >Download</a></button>
                        </form>
                    </div>

                    
                </div>
          </section>


            {/* tools */}

            <section>
                  <div className="grid place-items-center pt-10 ">
                      <img className="pb-1 w-[90px]" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/lineee_pcsxqy.png" alt="" />
                      <h3 className="font-gilroysemibold text-xl">TOOLS WE USE</h3>
                    </div>

                    <LazyLoadImage effect='blur' className='w-11/12 mx-auto pt-10 pb-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193214/codler%20branding%20page/Group_424_rfbdmk.png' alt='image'></LazyLoadImage>
            </section>



            {/* Tabs */}
                <section className='tabs '>
                  <div className='gallerywrapper w-10/12 mx-auto py-10'>
                  <div className="md:pl-5">
                  <img className="pb-1 w-[90px]" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667307105/codler%20branding%20page/line23_awzgk3.png" alt="" />
                  <h3 className="font-gilroysemibold text-white text-xl">OUR WORK</h3>

                      <h3 className="font-gilroysemibold text-2xl md:text-5xl text-white py-4">See Our Recent Case Studies</h3>
                    </div>
                          <div className='filter '>
                            <ul className='flex flex-wrap list-none gap-10 '>
                              
                            <li className='text-[#ffffffb7] hover:text-white duration-500 text-xl md:text-2xl'><button onClick={() => setData(DigitaGallery)}>All</button></li>
                              {
                                collection.map((item) => <li className='text-[#ffffffb4] hover:text-white duration-500 text-xl md:text-2xl'><button onClick={() => {ImageFilter(item)}}>{item}</button></li>)
                              }
                              
                              
                            </ul>
                          </div>
                          <div className='galleryContainer grid grid-cols-1 md:grid-cols-3 transition-all duration-500 '>
                              {
                                data.map((item) => 
                                <div clasName='galleryitem'>
                                  <LazyLoadImage effect='blur' className='' src={item.imgUrl} key={item.id} />
                                </div>
                                )
                              }
                          </div>
                  </div>
              </section>

            {/* our client */}
              <section className='w-10/12 mx-auto'>
                  <div className="pt-10">
                      <img className="pb-1 w-[90px]" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/lineee_pcsxqy.png" alt="" />
                      <h3 className="font-gilroysemibold text-xl">OUR CLIENTS</h3>
                    </div>

                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-x-4 md:gap-y-10 py-10'>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#2dadbf]'><LazyLoadImage effect='blur' className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193221/codler%20branding%20page/MicrosoftTeams-image_my4qlm.png' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#8A6093]'><LazyLoadImage effect='blur' className='md:h-12 lg:h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1669021400/codler%20branding%20page/WhatsApp_Image_2022-11-21_at_2.16.55_PM_jlxy3g.jpg' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#EE2E2A]'><LazyLoadImage effect='blur' className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193220/codler%20branding%20page/Mask_Group_40_mrywex.png' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#B10606]'><LazyLoadImage effect='blur' className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193221/codler%20branding%20page/red_qt3nt0.png' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#E17E29]'><LazyLoadImage effect='blur' className='h-10 md:h-12 lg:h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193214/codler%20branding%20page/Group_435_u2jmuk.svg' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#6C73B5]'><LazyLoadImage effect='blur' className='h-10 md:h-12 lg:h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193220/codler%20branding%20page/Logo_aqpcr9.png' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#FFCC00]'><LazyLoadImage effect='blur' className='h-10 md:h-12 lg:h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193221/codler%20branding%20page/Asset_3_bpeow2.png' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#E52229]'><LazyLoadImage effect='blur' className='h-10 md:h-12 lg:h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/logo22_znmlt7.png' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[black]'><LazyLoadImage effect='blur' className='h-10 md:h-12 lg:h-16' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193213/codler%20branding%20page/ceanor_hpfyox.png' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[black]'><LazyLoadImage effect='blur' className='h-10 md:h-12 lg:h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193214/codler%20branding%20page/Group_432_jopjf6.svg' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#1d1d1d]'><LazyLoadImage effect='blur' className='h-10 md:h-12 lg:h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193214/codler%20branding%20page/Group_434_iftgal.svg' alt='image'></LazyLoadImage></div>
                          <div className='brands-img rounded-xl cursor-pointer grid place-items-center p-3 border-b-8 border-[#00A3DD]'><LazyLoadImage effect='blur' className='h-10 md:h-12 lg:h-20' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193220/codler%20branding%20page/logo2323_uccdfg.png' alt='image'></LazyLoadImage></div>
                    </div>
              </section>


             


              {/* expert */}
              <section className='expert'>
                <div className='text-white grid place-items-center text-center py-10'>
                  <h3 className='font-gilroybold text-3xl lg:text-5xl'>We are expert in our field.</h3>
                  <h5 className='font-gilroymedium text-xl lg:text-2xl md:w-[50%] leading-relaxed'>Codlerr aspires to be your ideal growth partner by incorporating state-of-the-art services, increasing your value offering and expanding your company globally.</h5>
                </div>
                <LazyLoadImage effect='blur' className='w-11/12 mx-auto' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193217/codler%20branding%20page/Group_24581_apyts3.webp' alt='image'></LazyLoadImage>
                      <div className="md:w-10/12 mx-auto grid grid-cols-1 md:grid-cols-4 gap-3 md:gap-0  place-items-center py-4 rounded-[2rem] rounded-tr-none">
                      <div className="md:flex">
                      <a href="tel:+917012696630"><img className='md:h-10 lg:h-full'  src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193213/codler%20branding%20page/Group_429_urowil.svg" alt="" /></a>
                          <a className="no-underline hover:no-underline grid place-items-center text-white" href="tel:+917012696630">+91 7012696630</a>
                      </div>
                      <div className="md:flex">
                          <a onClick={() => navigate('/Contactus')}><img className='md:h-10 lg:h-full cursor-pointer'  src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193213/codler%20branding%20page/Group_425_ovq0gm.svg" alt="" /></a>
                          <a className="no-underline cursor-pointer hover:no-underline grid place-items-center text-white" onClick={() => navigate('/Contactus')}>info@codler.com</a>
                      </div>
                      <div className="md:flex">
                          <a href="https://instagram.com/codlerr?igshid=YmMyMTA2M2Y="><img className='md:h-10 lg:h-full'  src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193213/codler%20branding%20page/Group_427_tit0wp.svg" alt="" /></a>
                          <a className="no-underline hover:no-underline grid place-items-center text-white" href="https://instagram.com/codlerr?igshid=YmMyMTA2M2Y=">Codlerr</a>
                      </div>
                      <div className="md:flex">
                          
                          <a className="no-underline hover:no-underline grid place-items-center text-black bg-white rounded-2xl rounded-tr-none hover:rounded-tr-2xl transition-all ease-in duration-300 px-16 md:px-10 lg:px-16 py-3 cursor-pointer" onClick={() => navigate('/Contactus')}>Let's Talk</a>
                      </div>
                  </div>
              </section>

              {/* slider */}
              <section className='py-20 lg:w-11/12 mx-auto'>
                    <div className="grid place-items-center pb-14 md:pb-8">
                      <img className="pb-1 w-[90px]" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/lineee_pcsxqy.png" alt="" />
                      <h3 className="font-gilroymedium tracking-widest text-lg md:text-xl">TESTIMONIAL</h3>
                      <h1 className='font-gilroybold text-2xl md:text-4xl'>What Our Clients Say About Us</h1>
                    </div>
                  <SlideR/>
              </section>
              {/* slider */}



              {/* contact us */}
              <section className='w-10/12 mx-auto grid grid-cols-1 md:grid-cols-2' id='contact'>
                    <div className="md:pt-10 ">
                      <img className="pb-1 w-[90px]" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/lineee_pcsxqy.png" alt="" />
                      <h3 className="font-gilroysemibold text-xl">CONTACT US</h3>
                      <h2 className='font-gilroybold text-2xl lg:text-3xl py-6'>OUR COMPANY HAS EXCELLENT<br/> DIGITAL MARKETERS.</h2>
                      <h5 className='font-gilroymedium text-[#1d1d1d] text-xl lg:w-[55%]'>At Codlerr, we strive to be your
                          ultimate growth partner by
                          integrating cutting-edge services
                          that will maximise your value
                          proposition and thus cause the
                          development Of your company on a
                          worldwide scale.</h5>
                          <LazyLoadImage effect='blur' className='h-28 lg:h-36 pt-10' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193219/codler%20branding%20page/log_blackk_m2lwoy.png' alt='image'></LazyLoadImage>
                    </div>

                     

                    <div className='xs:p-3 md:p-5 md:mt-8 md:w-[90%]' >
                       {/* hubspot */}
                            <HubspotForm
                        region="na1"
                        portalId="23124429"
                        formId='cd88dbfe-9730-4a91-97ea-6940e0aefbe1'
                        loading={<div>Loading...</div>}
                        />

                 {/* hubspot */}
                    </div>
              </section>



              {/* Footer */}
              <section className="w-10/12 mx-auto lg:pb-10 lg:pt-40">
                    <div className="grid grid-cols-1 lg:grid-cols-5 gap-10 ">
                        <div className="flex flex-col leading-8">
                          <a onClick={() => navigate('/Aboutus')}  className="text-[#666666] hover:text-[black] no-underline hover:no-underline cursor-pointer" >About Us</a>
                          <a onClick={() => navigate('/services')}  className="text-[#666666] hover:text-[black] no-underline hover:no-underline cursor-pointer" >Services</a>
                          <a onClick={() => navigate('/portfolio')}  className="text-[#666666] hover:text-[black] no-underline hover:no-underline cursor-pointer" >Portfolio</a>
                          <a onClick={() => navigate('/Contactus')}  className="text-[#666666] hover:text-[black] no-underline hover:no-underline cursor-pointer" >Contact Us</a>
                        </div>
                        <div className="flex flex-col leading-8">
                            <span className="hover:text-[#000000] text-[#111111]"><i className="fa-brands fa-instagram pr-2"></i><a className='no-underline hover:no-underline text-[#111111] hover:text-[#000000]' target='_blank' href='https://instagram.com/codlerr?igshid=YmMyMTA2M2Y='>Instagram</a></span>
                            <span className="hover:text-[#000000] text-[#111111]"><i className="fa-brands fa-facebook pr-2"></i><a className='no-underline hover:no-underline text-[#111111] hover:text-[#000000]' target='_blank' href='https://www.facebook.com/codlerrsolutions/'>Facebook</a></span>
                            <span className="hover:text-[#000000] text-[#111111]"><i class="fa-brands fa-linkedin-in pr-2"></i><a className='no-underline hover:no-underline text-[#111111] hover:text-[#000000]' target='_blank' href='https://www.linkedin.com/company/codlerr'>LinkedIn</a></span>
                            <span className="hover:text-[#000000] text-[#111111]"><i className="fa-brands fa-twitter pr-2"></i><a className='no-underline hover:no-underline text-[#111111] hover:text-[#000000]' target='_blank' href='https://twitter.com/Codler3?t=PYG-ShshZ3RTT68Z6F56Og&s=08'>Twitter</a></span>
                        </div>
                        <div className="hover:text-[#000000]">
                            <p>Codler Solutions Private Limited, Bengaluru | Mahe</p>
                        </div>
                        <div className="md:col-span-2 lg:pl-20 lg:flex justify-center pb-8 lg:pb-0">
                          <div className="">
                        <p className='text-base text-[#111111] md:text-xs lg:text-base'>Signup for newsletters</p>
                            <form className="flex" onSubmit={handleSubmit} method='POST'>
                              <input required pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}" className="outline-none border-b-2" type="email" placeholder="Samplemail@.com" name='email' value={msg.email} onChange={handleChange} />
                              <button type="submit"><img className="h-5" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1667193221/codler%20branding%20page/ar-sub_jlamlg.png" alt="" /></button>
                            </form>
                          </div>
                        </div>
                    </div>
              </section>

              <section className="bg-[#00000063]">
                <div className="w-10/12 mx-auto py-2 flex">
                  <img className="h-10 -ml-4" src="https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653337740/codler/White_2_kdz48g.webp" alt="" />
                  <p className="h-10 grid place-items-center text-[#666666] text-xs sm:text-sm">2022 codler Inc all rights reserved</p>
                </div>
            </section>

    </div>
  )
}

export default Digitalmarketing;