import react,{useEffect, useState} from 'react';
import {FaSquareFacebook,FaInstagram,faAngleUp,faAngleDown,faStar,faPlus,FaBrands,FaTwitter,faArrowRight,faQuoteLeft} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import {toast} from 'react-toastify';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Aos from 'aos';
import "aos/dist/aos.css";
import { Buffer } from "buffer";
import { Faq } from '../constants/constants';
import axios from "axios";
import dxb from '../images/dxb3.png';
import Shajmeer from '../images/Shajmeer.jpg';
import Shashidhar from '../images/Shashidhar.jpg';
import scratch1 from '../images/scratch1.png';
import { Typewriter } from 'react-simple-typewriter'
import 'react-simple-typewriter/dist/index';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import Recentwork from '../component/sliders/slider2'





function HomeMain() {
    const[show,setShow]=useState(true);
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    const navigate = useNavigate('');
    	//------------------Api-end axios------------------//
	const [items, setItems] = useState([]);
	useEffect(() => {
		const fetchItems = async () => {
			await axios.get("https://codlerbackend.onrender.com/admin/get/api/instagrampost").then((response) => {
				setItems(response.data);
			});
		};
		fetchItems();
	}, []);

	/**
	 * Function that transform the buffer to base64
	 * @param {*} image : Image buffer array
	 * @returns : Base64 image
	 */
	const getImage = (image) => Buffer.from(new Uint8Array(image)).toString("base64");

	console.log(items);
	//------------------Api-end axios------------------//



    // ---accordion--//
    const [accordion, setActiveAccordion] = useState(0);

    function toggleAccordion(index) {
      if (index === accordion) {
        setActiveAccordion(-1);
        return
      }
      setActiveAccordion(index);
    };
    // ----accordion---//


     // ------Contact us------- //

     const[msg, setMsg] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        setMsg({...msg, [name]:value})
    }


    const handleSubmit = async (event)=> {
        event.preventDefault();
        const {name, email, phone, message} = msg;
        try{
            const res = await fetch('https://codler-backend.herokuapp.com/admin/contact/message', {
                method: "POST",
                headers: {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify({
                    name, email, phone, message
                })
            })
            console.log(res.status);
            if(res.status === 400 || !res){
                toast.error('Message not sent.',{
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }else{
                toast.dark('Thank you for your message.It has been sent.',{
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setMsg({
                    name: "",
                    email: "",
                    phone: "",
                    message: ""
                })
            }
        }catch (error) {
            console.log(error);
        }
    }
    // ------Contact us------- //


  return(
    <div className='overflow:hidden'>
                <section className='bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661845394/codler/5026621_zpkfg8_fbe5iy.webp)]  md:mb-40'>
                <div className='w-10/12 lg:w-11/12 md:grid mx-auto grid-cols-1 lg:grid-cols-3 flex flex-col-reverse overflow-hidden '>
                    <div className='lg:ml-8 xs:pt-48 sm:pt-60 lg:pt-36 ' data-aos="fade-up">
                           
                        <p className='text-white font-gilroysemibold tracking-wide md:text-2xl mt-24  lg:mt-10'>We can do it all</p>
                        <div className='typing-text'>
                                <img className='absolute -z-10 -rotate-3' src='https://res.cloudinary.com/dpsamxitw/image/upload/v1661855711/codler/scratch1_yjlrbw_cqhogn.webp'></img>
                            <h1 className='text-white z-50 text-3xl md:text-4xl xl:text-5xl md:tracking-wide py-2 md:py-4  h-20 lg:h-fit  md:w-[700px]  md:leading-loose font-gilroysemibold  '>
                            <Typewriter
                                words={['Website Development', 'Mobile App Development', 'Digital Marketing', 'Branding', 'Content Writing']}
                                loop={0}
                                cursor
                                cursorStyle='|'
                                typeSpeed={200}
                                deleteSpeed={50}
                                delaySpeed={1500}
                            />
                            </h1>
                        </div>
                        <p className="relative text-white text-sm md:text-base xs:mt-2 md:mt-7 font-gilroymedium  mt-8">Our multichannel knowledge, as well as cost-effective methods, have led to the revolutionary online growth of hundreds of businesses. Your company could be the next!</p>

                                
                <div className='w-40 mt-4 h-20'>
                    <img  onClick={()=>navigate('/Contactus')}  src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                    <p onClick={()=>navigate('/Contactus')} className='text-white -mt-[48px] relative z-200 rounded-lg  text-center pt-[12px] w-40 h-12 cursor-pointer lets-btn hover:ml-1 hover:-mt-[52px]'>Lets Talk!</p>
                </div>  
                            <div className=" pb-10">
                                <div className='xs:mt-5 md:mt-0'>
                                    <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648557143/codler/arrw_jfawrk.png'} className='h-20 lg:mt-16'></img></a>
                                    <hr className=" w-72 text-gray-200 lg:mt-3"/>
                                    <div className="flex">
                                    <a href='https://www.facebook.com/codlerrsolutions/' target='_blank'><i className="fa-brands logo-colr text-4xl fa-facebook"></i></a>
                                    <a href='https://instagram.com/codlerr?igshid=YmMyMTA2M2Y=' target='_blank'><i className="fa-brands logo-colr text-4xl fa-instagram ml-4"></i></a>
                                    <a href='https://twitter.com/Codler3?t=PYG-ShshZ3RTT68Z6F56Og&s=08' target='_blank'><i className="fa-brands logo-colr text-4xl fa-twitter ml-4"></i></a>

                                </div>
                            </div>
                            </div>
                        </div>
                        <div className=' col-span-2 pt-12'> 
                            <img className='md:h-[300px] md:w-[50%] lg:h-[650px] xs:w-[80%] sm:w-[80%] lg:w-[50%] top-16 md:top-12 absolute right-0' src={'https://res.cloudinary.com/dpsamxitw/image/upload/v1661840624/codler/Asset_16_2x_al6kn6.webp'} alt="image1"  data-aos="zoom-in"/>
                        </div>
                        
                        
                       
                </div>
        </section>

      

       {/* <section>
        <div className="w-full z-0 -ml-6">
        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1658938149/codler/Asset_18_2x_pipuem.png'} alt="mobile" style={{ width: '1300px', height: '800px' }} className=" z-100 " />
        </div>
    </section> */}

    



    <section className='bg-cover bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661840794/codler/Asset_17_eqdhpa.webp)]'>
        <Recentwork/>
    </section>


    <section className="bg-center bg-no-repeat  bg-bottom bg-cover bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661841377/codler/Group_115_vrpj2a_aiq8xb.webp)] -mt-12 mx-12 bg-[rgba(0,0,0,0.1)] mb-24">
        <div className='  lg:px-24 py-16 '>
            <p className="md:text-5xl xs:text-xl font-bold font-gilroybold text-center text-white" >Everything you need is with us</p>
            <p className="text-sm md:text-lg md:px-20 lg:px-56 mt-6 text-center font-gilroythin text-white">codlerr is a digital transformation company that has expertise in branding, app development, website development, SEO and content. Whether you're looking for a complete overhaul of your marketing strategy or need a simple website update, Codlerr will help you achieve your goals.</p>
            <p className="text-sm md:text-lg mt-3 tracking-wider font-semibold astonish text-center uppercase">We are unstoppable. We don't stop at anything. </p>
            {/* Large device */}
            <div className='text-white md:flex justify-center gap-5 overflow-hidden hidden md:block -mb-5'>
                <div className='relative px-10'>
                    <p className='text-8xl font-bold count-num stk-count text-center '>60</p>
                    <p className='text-lg font-gilroymedium -mt-3 tracking-widest text-center'>CLIENTS</p>
                    <p className='plus-count text-5xl absolute top-0 right-2'>+</p>
                </div>
                <div className='relative px-10'>
                    <p className='text-8xl font-bold count-num text-center'>100</p>
                    <p className='text-lg font-gilroymedium -mt-3 tracking-widest text-center'>PROJECTS</p>
                    <p className='plus-count text-5xl absolute top-0 right-2'>+</p>
                </div>
                <div className='relative px-10'>
                    <p className='text-8xl font-bold count-num text-center'>7</p>
                    <p className='text-lg font-gilroymedium -mt-3 tracking-widest text-center'>COUNTRIES</p>
                    <p className='plus-count text-5xl absolute top-0 right-5'>+</p>
                </div>
            </div>
            {/* Large device */}
            {/* Mobile */}
            <div className='text-white  grid-cols-1  overflow-hidden block md:hidden -mb-5'>
                <div className='relative'>
                    <p className='text-6xl md:text-8xl font-bold count-num stk-count text-center '>60</p>
                    <p className='text-lg font-gilroymedium -mt-3 tracking-widest text-center'>CLIENTS</p>
                    <p className='plus-count text-4xl md:text-5xl absolute top-2 right-14'>+</p>
                </div>
                <div className='relative'>
                    <p className='text-6xl md:text-8xl font-bold count-num text-center'>100</p>
                    <p className='text-lg font-gilroymedium -mt-3 tracking-widest text-center'>PROJECTS</p>
                    <p className='plus-count text-4xl md:text-5xl absolute top-2 right-14'>+</p>
                </div>
                <div className='relative'>
                    <p className='text-6xl md:text-8xl font-bold count-num text-center'>7</p>
                    <p className='text-lg font-gilroymedium -mt-3 tracking-widest text-center'>COUNTRIES</p>
                    <p className='plus-count text-4xl md:text-5xl absolute top-2 right-14'>+</p>
                </div>
            </div>
            {/* Mobile */}
        </div>
    </section>

    <section className='mb-24  bg-no-repeat bg-center bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661843024/codler/ogback_ygwntl.webp)]'>
        <div className='grid md:grid-cols-2 gap-x-32 xl:gap-x-32 2xl:gap-x-0 w-10/12 xl:w-8/12 mx-auto place-items-center'>
            <div className='w-2/3 md:ml-10 '>
                <div className='relative'>
                <img className='absolute h-5 md:h-6 w-44 lg:w-60 -ml-4 mt-2.5 z-0 -rotate-6' src='https://res.cloudinary.com/dpsamxitw/image/upload/v1660806523/codler/scratch2_msy6bf.png'></img>

                <p className='text-2xl relative lg:text-4xl font-semibold text-white'>Our Services</p>
                </div>
                <p className='text-lg font-semibold text-white'>We develop, build and assist you in scaling.</p>
                <p className='text-sm md:text-base font-gilroythin text-white w-full lg:w-96'>We collaborate with the entrepreneurs of startups and growing brands on a level of strategic planning to help them build the capacity and sustainability of their business. By providing the best marketing and growth-oriented consulting strategies that align with your company's mission.</p>
            </div>
            

        
            <div className='rounded-3xl border-[1px] border-zinc-800 bg-[rgba(108,122,137,0.12)] bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661843618/codler/Group_116_ggtmtc_xsannx.webp)] md:w-[340px] xl:w-[400px]'>
                <div className='flex px-8 md:px-16  pt-4'>
                    <div className=''>
                        <p className='text-5xl font-gilroybold text-white float-right pr-2'>01</p>
                    </div>
                    <div className=''>
                        <p className='text-xl font-gilroyregular font-semibold text-white pt-3'>Web Development</p>
                   </div>
                </div>
                   <p className='text-xs md:text-base leading-5 md:leading-5 px-8 md:px-16 py-2 font-gilroythin  text-zinc-300 '>Unlike other agencies, we don't do cookie cutter designs for our clients - instead we focus on the basics and add our own unique touch to all projects. This makes us the perfect choice if you're looking for something different than what you've seen before! </p>
                    <div className='flex justify-center'>
                        <div className='w-28 h-16 mr-3'>
                            <img className=''   src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                            <p onClick={()=>navigate('/Contactus')}  className='text-white text-xs -mt-8 relative z-200 rounded text-center  pt-2 w-28 h-8 cursor-pointer every-btn-non hover:ml-1 hover:-mt-9'>Let's Talk</p>
                        </div>  
                        <div className='w-28 h-12'>
                            <img className=''  src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                            <p onClick={()=>navigate('/services')} className='text-white text-xs -mt-8 relative z-200 rounded text-center pt-2 w-28 h-8 cursor-pointer every-btn hover:ml-1 hover:-mt-9'>Learn More</p>
                        </div>   

                            
                        
                    </div>

                    
            </div>

        </div>

        <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-x-56 xl:gap-x-32 2xl:gap-x-0  xs:mt-10 md:mt-0 md:py-24 w-10/12  mx-auto  place-items-center'>
        <div className='mb-10 md:mb-0 rounded-3xl border-[1px] border-zinc-800 bg-[rgba(108,122,137,0.12)] bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661843618/codler/Group_116_ggtmtc_xsannx.webp)] md:w-[340px] xl:w-[400px]'>
                
                <div className='flex px-8 md:px-16  pt-4'>
                    <div className=''>
                        <p className='text-5xl font-gilroybold text-white float-right pr-2'>02</p>
                    </div>
                    <div className=''>
                        <p className='text-xl font-gilroyregular font-semibold text-white pt-3'>App Development</p>
                   </div>
                </div>
                   <p className='text-xs md:text-base leading-5 md:leading-5 px-8 md:px-16 py-2 font-gilroythin  text-zinc-300  '>We take care of all aspects of app development - from idea conception to design to marketing and everything in between. We use the latest technologies to ensure that we are providing the best user experience possible. </p>
                    
                
                    <div className='flex justify-center'>
                        <div className='w-28 h-16 mr-3'>
                            <img className=''   src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                            <p onClick={()=>navigate('/Contactus')}  className='text-white text-xs -mt-8 relative z-200 rounded text-center  pt-2 w-28 h-8 cursor-pointer every-btn-non hover:ml-1 hover:-mt-9'>Let's Talk</p>
                        </div>  
                        <div className='w-28 h-12'>
                            <img src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                            <p onClick={()=>navigate('/services')} className='text-white text-xs -mt-8 relative z-200 rounded text-center pt-2 w-28 h-8 cursor-pointer every-btn hover:ml-1 hover:-mt-9'>Learn More</p>
                        </div>   

                        
                    </div>
            </div>
            <div className='mb-10 md:mb-0 rounded-3xl border-[1px] border-zinc-800 bg-[rgba(108,122,137,0.12)] bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661843618/codler/Group_116_ggtmtc_xsannx.webp)] md:w-[340px] xl:w-[400px]'>
            <div className='flex px-8 md:px-16  pt-4'>
                    <div className=''>
                        <p className='text-5xl font-gilroybold text-white float-right pr-2'>03</p>
                    </div>
                    <div className=''>
                        <p className='text-xl font-gilroyregular font-semibold text-white pt-3'>Digital Marketing</p>
                   </div>
                </div>
                   <p className='text-xs md:text-base leading-5 md:leading-5 px-8 md:px-16 py-2 font-gilroythin  text-zinc-300 '>We're here to help with your branding, design, and other design-related needs. Our multichannel experience and cost-efficient strategies have brought innovative technological growth to hundreds of businesses.</p>
                    
                
               
                    <div className='flex justify-center'>
                        <div className='w-28 h-16 mr-3'>
                            <img className=''    src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                            <p onClick={()=>navigate('/Contactus')} className='text-white text-xs -mt-8 relative z-200 rounded text-center  pt-2 w-28 h-8 cursor-pointer every-btn-non hover:ml-1 hover:-mt-9'>Let's Talk</p>
                        </div>  
                        <div className='w-28 h-12'>
                            <img   src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                            <p onClick={()=>navigate('/services')} className='text-white text-xs -mt-8 relative z-200 rounded text-center pt-2 w-28 h-8 cursor-pointer every-btn hover:ml-1 hover:-mt-9'>Learn More</p>
                        </div>   

                        
                    </div>
            </div>
            <div className='mb-10 md:mt-24 lg:mt-0 md:mb-0 rounded-3xl border-[1px] border-zinc-800 bg-[rgba(108,122,137,0.12)] bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661843618/codler/Group_116_ggtmtc_xsannx.webp)] md:w-[340px] xl:w-[400px]'>
            <div className='flex px-8 md:px-16  pt-4'>
                    <div className=''>
                        <p className='text-5xl font-gilroybold text-white float-right pr-2'>04</p>
                    </div>
                    <div className=''>
                        <p className='text-xl font-gilroyregular font-semibold text-white pt-3'>Branding</p>
                   </div>
                </div>
                   <p className='text-xs md:text-base leading-5 md:leading-5 px-8 md:px-16 py-2 font-gilroythin  text-zinc-300 '>We take care of all your branding needs - from logo creation to UX design and website building. Our team will work hard to create a strong identity for your brand that can be seen and felt through every touchpoint with your customers.</p>
                    
            
                    <div className='flex justify-center'>
                        <div className='w-28 h-16 mr-3'>
                            <img className=''   src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                            <p onClick={()=>navigate('/Contactus')}  className='text-white text-xs -mt-8 relative z-200 rounded text-center  pt-2 w-28 h-8 cursor-pointer every-btn-non hover:ml-1 hover:-mt-9'>Let's Talk</p>
                        </div>  
                        <div className='w-28 h-12'>
                            <img src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                            <p onClick={()=>navigate('/services')} className='text-white text-xs -mt-8 relative z-200 rounded text-center pt-2 w-28 h-8 cursor-pointer every-btn hover:ml-1 hover:-mt-9'>Learn More</p>
                        </div>   

                        
                    </div>
            </div>
        </div>

        

        <div className='grid md:grid-cols-2 gap-x-32 xl:gap-x-32 2xl:gap-x-0 w-10/12 xl:w-8/12 mx-auto place-items-center'>
            <div className='mb-10 md:mb-0 rounded-3xl border-[1px] border-zinc-800 bg-[rgba(108,122,137,0.12)] bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661843618/codler/Group_116_ggtmtc_xsannx.webp)] md:w-[340px] xl:w-[400px]'>
            <div className='flex px-8 md:px-16  pt-4'>
                    <div className=''>
                        <p className='text-5xl font-gilroybold text-white float-right pr-2'>05</p>
                    </div>
                    <div className=''>
                        <p className='text-xl font-gilroyregular font-semibold text-white pt-3'>Content Writing</p>
                   </div>
                </div>
                   <p className='text-xs md:text-base leading-5 md:leading-5 px-8 md:px-16 py-2 font-gilroythin  text-zinc-300  '>Talk to us in your language with our experts and get any website's content up-to-date in less than 3 hours! We handle all forms of content such as copywriting, video production and social media management at competitive prices - so you can focus on what </p>
                    
                    
                        <div className='flex justify-center'>
                            <div className='w-28 h-16 mr-3'>
                                <img className=''    src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                                <p onClick={()=>navigate('/Contactus')} className='text-white text-xs -mt-8 relative z-200 rounded text-center  pt-2 w-28 h-8 cursor-pointer every-btn-non hover:ml-1 hover:-mt-9'>Let's Talk</p>
                            </div>  
                            <div className='w-28 h-12'>
                                <img   src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                                <p onClick={()=>navigate('/services')} className='text-white text-xs -mt-8 relative z-200 rounded text-center pt-2 w-28 h-8 cursor-pointer every-btn hover:ml-1 hover:-mt-9'>Learn More</p>
                            </div>   

                            
                        </div>
                </div>




            <div className='w-full mx-auto overflow-hidden grid place-items-center bg-[rgba(108,122,137,0.1)] md:w-[340px] xl:w-[400px] h-full   rounded-2xl py-20 bg-cover bg-no-repeat bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661843118/codler/group-diverse-people-with-joining-hands-teamwork_wka9s6.webp)]'>
            <div className='w-full my-6   bg-[rgba(0,0,0,0.8)] grid place-items-center'>
            
                            <div className='w-28 h-12 mt-3'>
                                <img   src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                                <p onClick={()=>navigate('/services')} className='text-white text-xs -mt-8 relative z-200 rounded text-center pt-2 w-28 h-8 cursor-pointer every-btn hover:ml-1 hover:-mt-9'>Learn More</p>
                            </div>  
            </div>
            </div>



        </div>

        
        

    </section>

    <section className='w-full h-full'>
            <div className='flex flex-col-reverse md:grid grid-cols-1 md:grid-cols-2 relative overflow-hidden'>
                <div className='z-20 h-full'>
                    <div className='  pb-2  client-bg '>
                    
                    <div className='grid grid-cols-2 w-96  '>
                    <hr className='text-white w-6 md:w-10 mt-[54px]  ml-10 lg:ml-24' style={{height:'3px'}}/> 

                    <h1 className=" -ml-28 lg:-ml-12 mt-[22%] text-base brand-text font-bold tracking-widest font-gilroymedium" >BRANDS</h1>
                    </div>
                    <h2 className="text-white ml-10  lg:ml-24  text-2xl md:text-3xl tracking-wider" data-aos="fade-right">Our Happy Clients</h2>
                        <p className="text-zinc-300  text-xs md:text-sm lg:text-base lg:leading-5 font-gilroythin tracking-wide mx-10 lg:mx-24 "
                        >Without our clients, we wouldn’t exist. We have a dedicated customer base happy with our strategic insights, innovative solutions, and product development. We are working with some of the leading companies across the globe and hope to add yours to our list. </p>
                    </div>
                    <div className='lg:my-10'>
                        <div className='grid grid-cols-4 lg:grid-cols-4 gap-4 w-11/12 md:w-8/12 mx-auto pt-10'>
                                <img className='w-16' src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242041/codler/cravello_1_qyhmid.png'}></img>
                                <img className='w-16 pt-2 ml-2' src={dxb}></img>
                                <img className='h-14' src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242042/codler/memories_1_jgyzvt.png'}></img>
                                <img className='mt-3 md:mt-5' src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242042/codler/iluzia_1_dvq2ys.png'}></img>
                        </div>
                        <div className='grid grid-cols-4 lg:grid-cols-4 gap-4 w-11/12 md:w-8/12 mx-auto pt-4'>
                                <img className='mt-1 h-8' src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242046/codler/ondaceimg_1_qg7zrj.png'}></img>
                                <img className='h-10' src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242041/codler/CEANOR_xslfif.png'}></img>
                                <img className='w-20 md:mt-2 lg:mt-0' src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242042/codler/jsk1_1_lxtej9.png'}></img>

                                <img className='w-28 md:mt-2 lg:mt-0' src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242052/codler/ugrabin_1_uqvlzt.png'} ></img>
                        </div>

                    </div>
                </div>
                <div>
                      <LazyLoadImage src={'https://res.cloudinary.com/dpsamxitw/image/upload/v1661843352/codler/shakehand_igzotw.webp'} className='md:h-[430px] lg:h-[480px] h-52 w-full' ></LazyLoadImage>
                </div>  
            </div>
        </section>

        <section className='overflow-hidden'>
            <div className='grid grid-cols-1 md:grid-cols-5 md:mt-24 xs:mb-20 sm:mb-20 lg:mb-32'>
                     <div className='md:pl-14 lg:pl-24 mt-10 hidden md:block '>
                      {/* <img src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659069288/codler/Asset_1_300x_agqpj1.png' className='md:h-[70%] xs:w-20 md:w-28' alt='img'></img> */}
                      <img src='https://res.cloudinary.com/dpsamxitw/image/upload/v1661843833/codler/Asset_1_300x_apnv8s_vkbvdb.webp' className='md:h-[75%] lg:h-[100%] md:w-28' alt='img'></img>
                   </div>
                
                <div className='md:col-span-2  lg:-ml-10 mx-10'>
                    <div className='snap-start'>
                    <div className='flex'>
                       <hr className='textlgcolor mt-12 md:-ml-12 w-6' style={{height:'3px'}}/>
                       
                       <p className="text-[#B125C9] text-base pl-3 md:pl-5  font-gilroysemibold tracking-wider mt-10 "> WHY YOU CHOOSE US</p>
                     </div>
                     <p className="text-white text-2xl lg:text-3xl font-bold " data-aos="fade-right" data-aos-delay="200">What makes<br /> Codler stand out ?</p>
                     <p className="text-white text-sm md:text-xs lg:text-lg font-gilroythin tracking-wide mt-3 lg:pr-16 md:w-3/4">Our impeccable advanced technological solutions provide the most acceptable marketing approach and the most effective development plans for your brand.</p>
                    </div>

                    <div className='snap-y scroll-main mb-3 md:mb-0 snap-mandatory  w-full xs:h-[300px] sm:h-[300px] md:h-[200px] lg:h-[300px]  overflow-scroll md:mr-24 md:px-10'>

                    <div className='ml-3 lg:py-2 pr-10 snap-start'>
                    <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648556430/codler/startergy_kvcx48.png'} className='w-10 mt-10'></img>
                    <h2 className="text-white pt-2 lg:pt-4 lg:pb-6 text-lg lg:text-3xl  font-semibold">Stratergy</h2>
                    <p className="text-white text-sm md:text-xs lg:text-lg   lg:tracking-wide font-gilroythin lg:pr-5">The human-focused approach to building the best strategy. It’s an extensive plan that outlines the use of technology to meet business goals.</p>
                    </div>
                    <div className='ml-3 lg:py-5 pr-10 snap-start'>
                    <img src={require('../images/design.png')} className='w-10 mt-10'></img>
                    <h2 className="text-white pt-2 lg:pt-4 lg:pb-6 text-lg lg:text-3xl  font-semibold">Design</h2>
                    <p className="text-white text-sm md:text-xs lg:text-lg  lg:tracking-wide font-gilroythin  lg:pr-5 ">With our meticulous research, we create innovative designs for that delight and engage users</p>
                    </div>
                    <div className='ml-3 lg:py-5 pr-10 snap-start'>
                    <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648556429/codler/marketing_wdpffh.png'} className='w-10 mt-10'></img>
                    <h2 className="text-white pt-2 lg:pt-4 lg:pb-6 text-lg lg:text-3xl  font-semibold">Marketing</h2>
                    <p className="text-white text-sm md:text-xs lg:text-lg  lg:tracking-wide font-gilroythin  lg:pr-5 ">With our meticulous research, we create innovative designs for that delight and engage users.</p>
                    </div>
                    <div className='ml-3 lg:py-5 pr-10 snap-start'>
                    <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648556743/codler/development_awauy4.png'} className='w-10 mt-10'></img>
                    <h2 className="text-white pt-2 lg:pt-4 lg:pb-6 text-lg lg:text-3xl  font-semibold">Development</h2>
                    <p className="text-white text-sm md:text-xs lg:text-lg  lg:tracking-wide font-gilroythin  lg:pr-5 ">Whether we have designed a new site or we’re looking to make updates to an existing web or print work.</p>
                    </div>
                    </div>

                </div>

                

                    <form onSubmit={handleSubmit} method="POST">

                    <div className='grid place-items-center'>
                        <div className='md:col-span-2 py-1 lg:py-5  w-[330px] md:w-[280px] lg:w-[480px] bg-[rgba(108,122,137,0.2)] rounded-2xl bg-cover bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661843901/codler/Asset_19_mgcw82.webp)]'>
                            <div data-aos="fade-up">
                                <p className='text-[#C027D1] font-bold text-center mt-2 md:mt-10 font-gilroysemibold text-xs md:text-sm'>CONTACT</p>
                                
                                <p className='text-zinc-200 font-semibold font-gilroyregular text-center text-xl md:text-2xl'>Get in Touch</p>
                            </div>
                            <div className='px-10'>
                                <div class="text-white" data-aos="fade-up">
                                    <input autoComplete='off' required class="w-full text-white font-gilroyregular  border-b-2 border-zinc-700 outline-none bg-transparent pt-7 md:pt-5 lg:pt-8 pb-2 text-sm md:text-sm lg:text-lg" type="text" placeholder="Name" name='name' value={msg.name} onChange={handleChange}/>
                                </div>

                                <div class="text-white" data-aos="fade-up">
                                    <input autoComplete='off' required class="w-full text-white font-gilroyregular  border-b-2 border-zinc-700 outline-none bg-transparent pt-7 md:pt-5 lg:pt-8 pb-2 text-sm md:text-sm lg:text-lg" type="number" placeholder="Phone" name='phone' value={msg.phone} onChange={handleChange}/>
                                </div>

                                <div class="text-white" data-aos="fade-up">
                                    <input pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}" title='Must be a valid email' autoComplete='off' required class="w-full text-white font-gilroyregular  border-b-2 border-zinc-700 outline-none bg-transparent pt-7 md:pt-5 lg:pt-8 pb-2 text-sm md:text-sm lg:text-lg" type="email" placeholder="Email" name='email' value={msg.email} onChange={handleChange}/>
                                </div>

                                <div class="text-white lg:pb-14" data-aos="fade-up">
                                    <input autoComplete='off' required  class=" w-full  text-white font-gilroyregular   border-b-2 border-zinc-700 outline-none bg-transparent pt-7 md:pt-5 lg:pt-8 pb-16 text-sm md:text-sm lg:text-lg" type="text" placeholder="Message" name='message' value={msg.message} onChange={handleChange}/>
                                </div>

        
                                
                            </div>


                                <div class="pt-8 mb-8 lg:mb-14 md:pb-0 lg:pt-14 text-center cont-btn px-5  mx-auto">
                                    <a href='' className=''><button class="text-center w-full  md:w-28 lg:w-36 py-2.5 lg:py-3 border-2 border-[#7521A8] font-gilroyregular  text-xs rounded-lg text-white every-btn" type="submit">Send Message</button></a>


                                </div>
                        </div>
                    </div>

                    </form>


                </div>
        
        </section>



        <section className=' md:mb-32 bg-left bg-no-repeat lg:py-20  bg-contain bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661844000/codler/Asset_13_300x_qshym3.webp)] '>
            <div className='md:px-12 lg:px-5 xl:px-12 xs:mx-5 xs:px-12 md:mx-12 lg:mx-24 pb-3 rounded-3xl md:py-16 bg-[rgba(108,122,137,0.2)] md:h-[400px] lg:h-[500px]'>
                <div className='grid md:grid-cols-2'>
                <div className='xs:pt-3 sm:pt-3 lg:pt-14'>
                <FontAwesomeIcon icon={faQuoteLeft} className="text-3xl  text-[#C027D1] lg:text-7xl  z-0" />
                    <div className='z-10 lg:-mt-32 xl:-mt-24'>
                      <hr className="hidden md:block w-5 h-12 textlgcolor ml-24 mt-12" style={{height:'3px'}}/> <span> 

                        <div className='relative'>
                            <img className='absolute -ml-4 lg:ml-28 h-5 md:h-6 w-44   z-0 -rotate-6' src='https://res.cloudinary.com/dpsamxitw/image/upload/v1660806523/codler/scratch2_msy6bf.png' ></img>
                        <p className="text-l relative lg:ml-32  tracking-widest md:-mt-10 lg:-mt-7 font-normal text-white" >OUR STRENGTH</p>
                        </div>
                        </span>
                        <p className="text-xl lg:text-3xl  lg:ml-24 mt-3 text-white font-bold tracking-wide" data-aos="fade-right" data-aos-delay="200">Get real world advice <br />from people<br />who have been there</p>
                        <p className="text-xs lg:text-base w-64 lg:ml-24 lg:pr-5 font-gilroythin tracking-wide text-white xl:w-[400px] ">We work thoroughly with our clients to design, develop, and define transformative user experiences across all platforms and brand touchpoints.</p>
                    </div>
                </div>
                <div>
                    <div className='grid  md:grid-cols-2 gap-4 md:-mt-32 lg:-mt-24'>
                        
                        <div className=''>
                            <div className='hover:bg-[#7521A8] testinomial-shape text-white carduser-bg bg-gray-800 pt-3 px-3'>
                                <p className='text-white font-gilroythin text-xs leading-6 md:leading-3 md:text-[10px]  lg:text-xs lg:leading-6'>It was a great experience working with Codler. They understood our requirements with minimal explanation and delivered exactly what we wanted. Looking forward to work with them again.</p>
                                <div className='flex'>
                                    <div>
                                        <p className="text-base  md:text-[10px]  lg:text-base mt-0.5 mr-3  text-white font-semibold">5.0</p>
                                    </div>
                                    <div className=''>
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1  text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    </div>
                                </div>

                                <div className='flex'>
                                <img src={'https://res.cloudinary.com/dpsamxitw/image/upload/v1661855712/codler/a5b989fc-828b-4ada-bee0-ba9faf58560e_cqxxaf_m5afzf.webp'} className='md:w-8 md:h-8 lg:w-16 rounded-full lg:h-16 w-14 h-14  -ml-10 md:mt-3 '></img>
                                     <div className='text-white text-[10px] md:text-sm leading-3 md:mt-3 ml-3'>
                                     <p className='text-base  md:text-sm lg:text-base'>Naseef</p>
                                    <p className='-mt-3 font-gilroythin text-base  md:text-[10px] lg:text-xs '>Director, Ceanor</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                        <div className='hover:bg-[#7521A8] testinomial-shape text-white carduser-bg bg-gray-800 pt-3 px-3'>
                                <p className='text-white font-gilroythin text-xs leading-6 md:leading-3 md:text-[10px]  lg:text-xs lg:leading-6'>Codler helped us with Digital marketing and Brand building for Odikko.Odikko being an online marketplace startup required good digital marketing presence . The plans were executed flawlessly.</p>
                                <div className='flex'>
                                    <div>
                                        <p className="text-base  md:text-[10px] lg:text-base mt-0.5 mr-3  text-white font-semibold">5.0</p>
                                    </div>
                                    <div className=''>
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1  text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    </div>
                                </div>

                                <div className='flex'>
                                <img src={'https://res.cloudinary.com/dvbplh4z9/image/upload/v1667981770/codler%20branding%20page/4438355b-b1a4-4d13-9ecd-bceed7fcb79d_ut2zoc_f90nlr_e66wrj.webp'} className='md:w-8 md:h-8 lg:w-16 rounded-full lg:h-16 w-14 h-14  -ml-10 md:mt-3 '></img>
                                     <div className='text-white text-[10px] md:text-sm leading-3 md:mt-3 ml-3'>
                                     <p className='text-base  md:text-sm lg:text-base'>Prabin BP</p>
                                    <p className='-mt-3 font-gilroythin text-base  md:text-[8px] lg:text-xs '>MD, Panac Systems Pvt Ltd</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                        <div className='hover:bg-[#7521A8] testinomial-shape text-white carduser-bg bg-gray-800 pt-3 px-3'>
                                <p className='text-white font-gilroythin text-xs leading-6 md:leading-3 md:text-[10px]  lg:text-xs lg:leading-6'>A very professional team who proactively listens to our needs, requirements and comes back to us with creative solutions.The outcome we got was beyond our expectations. Thanks to Codlerr for all of your support.</p>
                                <div className='flex'>
                                    <div>
                                        <p className="text-base  md:text-[10px] lg:text-base mt-0.5 mr-3  text-white font-semibold">5.0</p>
                                    </div>
                                    <div className=''>
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1  text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    </div>
                                </div>

                                <div className='flex'>
                                <img src={'http://res.cloudinary.com/dvbplh4z9/image/upload/v1667981525/codler%20branding%20page/Shajmeer_rng4qi_fbtrso_zwv3ne.webp'} className='md:w-8 md:h-8 lg:w-16 rounded-full lg:h-16 w-14 h-14 -ml-10 md:mt-3 '></img>
                                     <div className='text-white text-[10px] md:text-sm leading-3 md:mt-3 ml-3'>
                                     <p className='text-base  md:text-sm lg:text-base'>Shajmeer</p>
                                    <p className='-mt-3 font-gilroythin text-base  md:text-[10px] lg:text-xs '>MD, Ugrab</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                        <div className='hover:bg-[#7521A8] testinomial-shape text-white carduser-bg bg-gray-800 pt-3 px-3'>
                                <p className='text-white font-gilroythin text-xs leading-6 md:leading-3 md:text-[10px]  lg:text-xs lg:leading-6'>Codlerr has been very professional, comprehensive and competent throughout the process of working together. Their team worked closely with us to provide a website that met all our requirements.</p>
                                <div className='flex'>
                                    <div>
                                        <p className="text-base  md:text-[10px] lg:text-base mt-0.5 mr-3  text-white font-semibold">5.0</p>
                                    </div>
                                    <div className=''>
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1  text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    <FontAwesomeIcon icon={faStar} className="text-base  md:text-[10px] lg:text-base pr-1 text-yellow-400 " />
                                    </div>
                                </div>

                                <div className='flex'>
                                <img src={'https://res.cloudinary.com/dpsamxitw/image/upload/v1661855712/codler/Shashidhar_l0yung_fgzama.webp'} className='md:w-8 md:h-8 lg:w-16 rounded-full lg:h-16 w-14 h-14 -ml-10 md:mt-3 '></img>
                                     <div className='text-white text-[10px] md:text-sm leading-3 md:mt-3 ml-3'>
                                     <p className='text-base  md:text-sm lg:text-base'>Shashidhar GS</p>
                                    <p className='-mt-3 font-gilroythin text-base  md:text-[10px] lg:text-xs'>Head of Technology, Ondace</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            </div>
        </section>


     

        {/* <section className='bg-[rgba(108,122,137,0.1)]  md:py-4  py-10 lg:my-20 overflow-hidden ' >
            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653296935/codler/img4_2x_1_4_lavqsr.webp'} className='w-20 h-20 -ml-24 -mt-12'/>
            <div>
            <Imageslider/>
            </div>
    </section> */}

        <section className=' mb-10 md:mb-0 mt-10 md:mt-0 lg:mb-24 bg-[rgba(0,0,0,0.1)]  bg-[url(https://res.cloudinary.com/dpsamxitw/image/upload/v1661844124/codler/Group_118_msva19_qbbxid.webp)]'>
            <div className='grid grid-cols-1 md:grid-cols-4  lg:py-20 pl-5 lg:pl-10'>
                <div className='lg:ml-10'>

                <div className='relative xs:pt-4 sm:pt-4 md:pt-0'>
                            <img className='absolute h-4 lg:h-8 w-28 md:w-28 lg:w-40 mt-3 md:mt-2 lg:mt-5 z-0 -rotate-6 -ml-4' src='https://res.cloudinary.com/dpsamxitw/image/upload/v1660806523/codler/scratch2_msy6bf.png' ></img>
                    <h1 className='relative text-white text-4xl lg:text-6xl'>Blogs</h1>
                </div>

                    <p className='text-white font-gilroythin text-lg md:text-sm lg:text-lg mt-3 py-4'>Insights, thoughts, industry trends, marketing tips, eDesign news, nerdy stuff, it's all here.</p>
                    <button onClick={()=>navigate('/blog')} class=" text-center mb-2 px-14 py-3 border-[1px] border-[#7521A8] carduser-bg hover:bg-[#7521A8] font-gilroyregular  text-xs rounded-xl text-white" type="submit">Read More</button>
                </div>
                <div className='md:px-5 xs:py-5 carduser-bg hover:bg-[#7521A8] lg:px-5 md:border-l-2 md:border-zinc-800' onClick={()=>navigate('/blog')}>
                    <h1 className=' text-white text-3xl lg:text-5xl'>29</h1>
                    <p className='text-white font-gilroythin text-base mt-3'>June,2022</p>
                    <p className='text-white  font-gilroyregular text-xl md:text-xs lg:text-xl mt-3'>Is UX/UI Important in a mobile Apllication?</p>
                    <p className='text-zinc-300 font-gilroymedium text-sm md:text-xs lg:text-sm mt-3'>Increasing sales is the primary goal of any business. Mobile applications effectively boost these sales by building direct connections with customers. To gain customers from a mobile app, UI/UX design plays a key role. A dynamic user interface makes the business more accessible. An intuitive user experience keeps the customers engaged and satisfied. Therefore, UI/UX design becomes essential to mobile app developmentA mobile app's UI/UX design starts with designing the user interface.</p>
                </div>
                <div className='md:px-5 xs:py-5 carduser-bg hover:bg-[#7521A8] lg:px-5 md:border-l-2 md:border-zinc-800' onClick={()=>navigate('/blog')}>
                    <h1 className=' text-white text-3xl lg:text-5xl'>04</h1>
                    <p className='text-white font-gilroythin text-base mt-3'>July,2022</p>
                    <p className='text-white  font-gilroyregular text-xl md:text-xs lg:text-xl mt-3'>Branding in business.</p>
                    <p className='text-zinc-300 font-gilroymedium text-sm md:text-xs lg:text-sm mt-3'>Business is a significant pillar of society that allows the wheel of economics to run. It will enable an organization to gain and provide numerous advantages. When you're running a business, it's necessary that you're qualified to do what's needed for the business. Branding is a vital element to any business as it allows the company of a business to upgrade. When you're branding, it's required that you take care of the necessary business principles. </p>
                </div>
                <div className='md:px-5 xs:py-5 carduser-bg hover:bg-[#7521A8] lg:px-5 md:border-l-2 md:border-zinc-800' onClick={()=>navigate('/blog')}>
                    <h1 className=' text-white text-3xl lg:text-5xl'>09</h1>
                    <p className='text-white font-gilroythin text-base mt-3'>July,2022</p>
                    <p className='text-white  font-gilroyregular text-xl md:text-xs lg:text-xl mt-3'>What is Mobile Application Development?</p>
                    <p className='text-zinc-300 font-gilroymedium text-sm md:text-xs lg:text-sm mt-3'>Mobile application development is the set of strategies and procedures involved with writing software for small, wi-fi computing gadgets, smartphones, and other gadgets. Like website development, mobile application software development has its roots in extra traditional software program development. However, one critical difference is that cell apps are regularly written exceptionally to take advantage of the specific features of a particular tool of a cell.</p>
                </div>

            </div>
        </section>



    

        <section className='w-10/12 lg:w-8/12 mx-auto overflow:hidden'>
                <div className='grid grid-cols-1 lg:grid-cols-2'>
                        <div data-aos="fade-down">
                        <div className='grid grid-cols-2'>
                        <hr className='text-[#7521A8] w-8 h-24 mt-[55px] md:mt-[60px] md:mt-0 -ml-5 lg:ml-5 absolute opacity-60' style={{height:'3px'}}/>
                        </div>
                   <p className="text-[#7521A8] text-lg ml-6  lg:ml-16 mt-10 md:mt-12 font-semibold tracking-widest " data-aos="fade-right" data-aos-delay="200"> CREATIVE</p>
                          <p className="text-white text-2xl md:text-3xl xs:ml-5 md:ml-10 mt-4 lg:mt-0 font-bold tracking-wide" data-aos="fade-right" data-aos-delay="200">Find us on social<br />media</p>
                          <p className='text-zinc-400 text-sm xs:ml-5 md:ml-10 font-gilroythin tracking-[0.3rem] -mt-2'>#CODLERR</p>
                        </div>

                        <div data-aos="fade-up">
                            <div className='pt-10'>
                                <p className='text-sm md:text-lg font-gilroythin  -mt-8 md:mt-0 text-white xs:pl-5 md:pl-10 lg:pl-0 lg:pr-10 leading-6'>You don't just have to pick up the phone to ring us - we are on Facebook, LinkedIn as well Instagram! See you there!</p>
                                    <div className='pt-2 xs:pl-5 md:pl-10 lg:pl-0'>
                                        <a href='https://www.instagram.com/accounts/login/' className='flex no-underline hover:no-underline'> <p className='text-[#7521A8] testdecoration-none text-sm text-lg font-semibold -mt-2 '>Take me to Instagram</p>
                                     <FontAwesomeIcon icon={faArrowRight} className="text-base ml-2 text-white cursor-pointer" /></a>
                                    </div>
                            </div>
                        </div>
                     </div>

             </section> 

       


       <section>
           <div className='w-10/12 mx-auto relative z-10 hidden md:block'>
            <div className='grid grid-cols-2 place-items-center md:grid-cols-4  gap-5 mt-10 mb-12'>
            {items.slice(0,4).map((index)=>{
                                return(
                                    <a target="_blank" href={index.links}><LazyLoadImage effect='blur' src={`data:image/png;base64,${getImage(index.coverImage.data)}`} className='md:h-[250px] lg:h-[430px]' alt='img'></LazyLoadImage></a>
                                )
                            })}
            </div>
           </div>
       </section>

        {/* mobile */}
       <section>
           <div className='w-10/12 mx-auto relative z-10 block md:hidden'>
            <div className='grid grid-cols-2 place-items-center gap-4 mt-6 mb-10'>
            {items.slice(0,4).map((index)=>{
                                return(
                                    <a target="_blank" href={index.links}><img src={`data:image/png;base64,${getImage(index.coverImage.data)}`} className='w-72 md:w-80 md:h-[250px] lg:h-[420px]' alt='img'></img></a>
                                )
                            })}
            </div>
           </div>
       </section>
       {/* mobile */}

       <p className='hidden md:block w-full insta-bg h-52 -mt-[280px] lg:-mt-[360px] -z-10 mb-40'></p>

        <section className='md:mb-32 lg:mx-auto overflow:hidden'>
            <div className='grid xs:grid-cols-1 lg:grid-cols-2 xs:mx-12  lg:mx-0 lg:ml-36 relative'>
                {/* <div>
                    
                    <div className='flex'>
                      <hr className='text-[#7521A8] mt-12 w-6' style={{height:'4px'}}/>
                      <p className="text-[#7521A8] font-bold text-lg ml-2 font-gilroythin tracking-wider mt-10 ">FAQ</p>
                    </div>
                    <p className='w-56 text-white font-gilroyregular text-3xl'>Get Every Single Answers Here.</p>
                    <div className="flex justify-between">
                    <p className='col-span-2  text-white text-xl text-left'>Which city does CODLERR call home? What is the size of the workforce?</p>
                    <FontAwesomeIcon icon={faAngleUp} className="text-xl text-purple-500"/>
                    </div>
                    <div><p className='text-white text-sm font-gilroythin text-left'>Since 2021, we've been a top software development IT company based in Bangalore, India. We have a fantastic staff of around 200 extraordinary people</p>
                     <hr className='text-gray-200 w-96 mt-12 xs:w-[230px] lg:w-6' style={{height:'3px'}}/>  
                    </div>
                    {Faq.map(display)}
                </div> */}

                

                    <div className="accordion__faq ">
                    <div className='flex'>
                      <hr className='text-[#7521A8]  w-6' style={{height:'5px'}}/>
                      <h4 className="text-[#7521A8] text-base ml-2 font-semibold tracking-wider pt-1">FAQ</h4>
                    </div>
                    <h2 className='xs:w-56 md:w-72 text-white md:text-4xl font-gilroysemibold md:pb-5'>Get Every Single Answers Here.</h2>
                    {Faq.map((item, index) =>
                        <div key={index} onClick={() => toggleAccordion(index)}>
                            <div className="accordion__faq-heading acco-m">
                            <h3 className={accordion === index ? "active" : ""}>{item.question}</h3>
                            <div>
                                {accordion === index ?
                                <span className="verticle"><FontAwesomeIcon icon={faAngleUp} className="text-xl text-[#EC2BEA]"/></span> : <span className="horizental"><FontAwesomeIcon icon={faAngleDown} className="text-xl text-[#EC2BEA]"/></span>}
                            </div>
                            </div>
                            <div className='acco-m'><p className={accordion === index ? "active" : "inactive"} >{item.answer}</p></div>
                            <hr className='text-zinc-400 w-full'></hr>
                        </div>
                        )
                    }
                    <p onClick={()=>navigate('/faq')} className='text-white font-gilroysemibold view-all-btn cursor-pointer'>View all <FontAwesomeIcon icon={faArrowRight} className="text-base ml-2  mt-0.5 text-white cursor-pointer" /></p>
        </div>


                        {/* xs:pl-10 lg:pl-0 xl:px-28 lg:px-16 */}
                <div className='grid place-items-center md:place-items-start pb-10 md:pb-0 md:pt-16 md:pl-14 md:pr-32'>
                    <img src='https://res.cloudinary.com/dpsamxitw/image/upload/v1661844482/codler/big-quest_sw41sa.webp' className='hidden lg:block' alt="img"/>
                    <img src='https://res.cloudinary.com/dpsamxitw/image/upload/v1661844534/codler/samll-quet_kikwgl.webp' className='absolute top-0 right-0 w-20 md:hidden' alt="img"/>
                </div>
            </div>
        </section>

        
    </div>
  )
}
export default HomeMain;