import Aos from 'aos';
import "aos/dist/aos.css";
import  { useEffect,useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../App.css';
import {Helmet} from 'react-helmet';
import axios from 'axios';
function Services() {
     //------------------Api-end axios------------------//
     const [items, setItems] = useState([])

     useEffect(() => {
         const fetchItems = async () => {
             await axios.get('https://codlerbackend.onrender.com/admin/get/api/service').then((response)=>{
                 setItems(response.data.result);
                 console.log(response.data.result)
             } )}
         fetchItems();
         console.log(items)
     }, [])
 
     //------------------Api-end axios------------------//
 
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    const navigate = useNavigate('');
    return (
        <div>
            <Helmet>
            <title>Services</title>
            <meta name="description" content="Have a look at services we offer" />
           </Helmet>
            <section className='bg-[url(https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654930309/codler/bg-wave_h2kgso.jpg)] bg-center sm:h-[550px] lg:h-[610px] '>
                    <div className=' mx-5 lg:pl-12 h-full text-white xs:pt-24 sm:pt-56 md:ml-16 lg:ml-0 md:pt-56 lg:pt-48' data-aos="fade-up">
                        <p className='text-xl md:text-2xl md:-mt-10 xl:mt-0 md:ml-12 lg:ml-0 lg:text-[34px] xl:text-[44px] lg:leading-[3rem] font-gilroybold font-extrabold lg:w-2/4' data-aos="fade-right" data-aos-delay="100">It's your choice to choose<br/> what’s relevant to your <br/>business</p>
                        <p className='md:w-[37%] md:text-sm lg:text-lg font-medium md:ml-12 lg:ml-0  font-gilroylight '>Delivering high-quality software development services and leading the way in digital transformation are our strengths. Our skilled staff have demonstrated their talents and are ready to assist you. </p>
                    </div>
            </section>

            <section className='w-11/12 lg:w-10/12 mx-auto mt-16'>
                <div className='s-bg2 rounded-lg' data-aos="fade-up">
                    <div className='grid grid-cols-1 md:grid-cols-2 relative md:h-96'>
                        
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/Ellipses_62_lwwbms.png'} className='hidden lg:block absolute bottom-0 left-0 w-40 rounded-bl-lg '></img>
                        <div className='py-8 lg:py-16 text-white relative'>
                            
                           <div className='relative pl-10 pr-4md:mb-3 lg:mb-0 md:mt-5 lg:mt-0 lg:pl-24'>
                            <p className='text-2xl xl:text-[32px] font-gilroyregular font-semibold leading-[2rem] tracking-wide lg:w-3/4'>Mobile app developement</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-italic font-light'>Building amazing apps that create exceptional experiences for your customers</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-gilroythin font-light'>Our creative team values your brand and makes the best user experience possible to increase the emotions between your brand and customers. We are experts at building intuitive apps using android native, flutter and react native. Our developers work closely with you from the initial design of your app to ongoing maintenance to create unique applications with strategy, creation and engineering. Codler offers one of India's best mobile app development services, including app development on native or cross-platform.</p>
                                <a  onClick={() => navigate('/services/servicedetails')} className='flex no-underline hover:no-underline hover:bg-violet-800 text-white sbg1-btn w-36 md:h-10 md:w-32  shadow-xl rounded-full'><p className='my-2.5 lg:my-3 pl-4 pr-2 text-xs'>Learn more</p><i class="fa-solid fa-arrow-right text-xl pt-2 lg:pt-3"></i></a>

                        </div>
                        </div>
                        <div className='relative'>
                            <span className='flex justify-center'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/p2_obqr2f.png'} className='lg:my-10 py-4  lg:absolute z-10 pl-8 right-0 md:w-10/12 md:mt-12 md:ml-12'></img></span>
                            <img src={require('../images/services/Ellipse 60.png')} className='absolute  hidden lg:block right-0 top-0 w-72 rounded-tr-lg'></img>
                        </div>
                        
                    </div>
                </div>
            </section>


            <section className='w-11/12 lg:w-10/12 mx-auto mt-20' data-aos="fade-up">
                <div className='s-bg1 rounded-lg md:h-96' >
                    <div className='grid grid-cols-1 md:grid-cols-2 relative'>
                        <div><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/p1_ivmmeh.png'} className='md:w-10/12 md:mt-20 lg:mt-12 md:ml-12'></img></div>
                        <img src={require('../images/services/Ellipse 62.png')} className='absolute mt-72 left-0 w-40 rounded-bl-lg hidden lg:block'></img>
                        <div className=' lg:py-12 text-white relative'>
                            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629514/codler/shade1_ypr4ql.png'} className='absolute hidden lg:block right-0 top-0 w-72 rounded-tr-lg'></img>
                           <div className='relative lg:mr-10 ml-8 md:mt-5 lg:-mt-7 xl:mt-0  lg:ml-0 mb-5 lg:mb-0 lg:p-0'>
                            <p className='text-2xl xl:text-[32px] font-gilroyregular font-bold leading-[2.5rem] tracking-wide lg:w-full'>Web Development</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-italic md:w-[90%]'>Creating visually striking websites that make users keep coming back to it</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-gilroythin md:w-[90%]'>Take your brand online and lead your business with the help of one of the best teams in India. We will walk you through every nook and corner of the project. Our team will brainstorm, plan, research and organise data to meet your goals. Our accurate market understanding allows us to identify the market demands and integrate them into the design for a better user experience. Our tech team focuses on developing logical, intuitive, and appealing websites for all users.</p>
                                {/* <a href='#'  onClick={() => navigate('/services/servicedetails')} className='flex no-underline hover:no-underline hover:bg-violet-800 text-white sbg1-btn w-36 md:h-10 md:w-32  shadow-xl rounded-full'><p className='my-2.5 lg:my-3 pl-4 pr-2 text-xs'>Learn more</p><i class="fa-solid fa-arrow-right text-xl pt-2 lg:pt-3"></i></a> */}
                           </div>
                           
                        </div>
                    </div>
                </div>

                <div className='hidden lg:block w-10/12 mx-auto -mt-10 relative'>
                    <div className='flex justify-between serv-brand p-4 rounded-md'>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629841/codler/Group_2196_e6kcre.png'}></img></a>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629842/codler/Vector_1_ozfheo.png'}></img></a>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629841/codler/Group_1_fq78va.png'}></img></a>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629841/codler/Group_2197_vd55gm.png'}></img></a>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629841/codler/Group_2200_eyss1m.png'}></img></a>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629841/codler/Group_2267_s81h3u.png'}></img></a>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629842/codler/Group_2268_zwaz6j.png'}></img></a>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629842/codler/Group_2269_kwh14t.png'}></img></a>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629842/codler/Group_f171zl.png'}></img></a>
                        <a href='#'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629841/codler/Group_2208_ivyoeu.png'}></img></a>
                    </div>
                </div>

            </section>

            

            <section className='w-11/12 lg:w-10/12 mx-auto mt-16'>
                <div className='s-bg3 rounded-lg' data-aos="fade-up">
                    <div className='grid grid-cols-1 md:grid-cols-2  relative md:h-96'>
                        <div className='flex justify-center lg:mt-16'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/p3_veavhn.png'} className='p-6 h-60 md:h-72 md:mt-16 lg:mt-0 md:w-96 md:ml-12'></img></div>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/Ellipse_62_1_rgwqva.png'} className='hidden lg:block absolute bottom-0 left-0 w-40 rounded-bl-lg'></img>
                        <div className='lg:py-16 text-white relative'>
                            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629512/codler/Ellipse_60_1_aawdaq.png'} className='absolute hidden lg:block right-0 top-0 w-72 rounded-tr-lg'></img>
                           <div className='relative lg:p-4 ml-8 lg:ml-0 mb-3 lg:mb-0 md:mt-12 lg:-mt-5 xl:mt-0 mr-5 lg:p-0'>
                            <p className='text-2xl xl:text-[32px] font-gilroyregular font-bold leading-[2rem] tracking-wide lg:w-full'>Digital marketing</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0  font-light font-italic lg:w-full'>Looking for ways to generate high-quality leads? We were born to do that!</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0  font-light font-gilroythin lg:w-full'>With a strong digital DNA, Codler steps into your marketing world and works as a partner in your growth. We help you plan your marketing ideas effectively and provide an optimal brand roadmap for your journey across the digital landscape. Our tailor-made digital marketing services help you build your brand across every social media platform. Our creative heads transform your ideas into eye-catchy designs, creating a visual treat for your users.</p>
                             {/*                               <a href='#' className='flex no-underline text-white sbg3-btn w-36 rounded-full  md:h-10 md:w-32 shadow-lg'><p className='my-2.5 lg:my-3 pl-4 pr-2 text-xs'>Learn more</p><i class="fa-solid fa-arrow-right text-xl pt-2 lg:pt-3"></i></a>
                            */}
                           </div>
                           
                        </div>
                    </div>
                </div>
            </section>
            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/img4_2x_1_gbzd8l.png'} className='hidden lg:block absolute w-16 right-0 -mt-52'></img>

            <section className='w-11/12 lg:w-10/12 mx-auto mt-16'>
                <div className='s-bg4 rounded-lg' data-aos="fade-up">
                    <div className='grid grid-cols-1 md:grid-cols-2 relative md:h-96'>
                        
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/Ellipse_62_2_lcipqb.png'} className='hidden lg:block absolute bottom-0 left-0 w-40 rounded-bl-lg'></img>
                        <div className='py-8 lg:py-16 text-white relative'>
                            
                           <div className='relative pl-10 pr-4 md:mt-5 lg:-mt-5 xl:mt-0 lg:pl-24'>
                            <p className='text-2xl xl:text-[32px] font-gilroyregular font-semibold leading-[2rem] tracking-wide lg:w-full'>Product Development</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-italic font-light'>We assist you in selecting the platforms and right tools necessary to build a compelling product with our technical expertise.</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-gilroythin font-light'>At Codler, we work as problem solvers, recognising issues and discussing potential solutions to make our customers' life more straightforward than before. We have assisted several companies in India and overseas develop innovative products, performing business analyses, and pitching to investors. When it comes to developing tech products, our gigantic head digs into the industry to find the most innovative ideas possible. Every day, our team works towards finding better solutions for our clients.</p>
                        {/*                                <a href='#' className='flex no-underline text-white sbg4-btn w-36 rounded-full md:h-10 md:w-32 shadow-lg'><p className='my-2.5 lg:my-3 pl-4 pr-2 text-xs'>Learn more</p><i class="fa-solid fa-arrow-right text-xl pt-2 lg:pt-3"></i></a>
                        */}
                       </div>
                           
                        </div>
                        <div className='relative'>
                            <span className='flex justify-center'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/p4_qk5q5y.png'} className=' py-4 relative z-10 h-60 md:mt-12 md:w-64 md:h-72 md:ml-12'></img></span>
                            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629512/codler/Ellipse_60_2_rvq5on.png'} className='absolute  hidden lg:block right-0 top-0 w-72 rounded-tr-lg'></img>
                        </div>
                        
                    </div>
                </div>
            </section>


            <section className='w-11/12 lg:w-10/12 mx-auto mt-16 mb-20'>
                <div className='s-bg5 rounded-lg' data-aos="fade-up">
                    <div className='grid grid-cols-1 md:grid-cols-2 relative md:h-96'>
                        <div className='flex justify-center'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/p5_fslbfx.png'} className=' lg:py-0 py-2 h-60 md:h-72 md:w-[60%] md:mt-12 md:ml-12'></img></div>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/Ellipse_62_3_r1xmfa.png'} className='hidden lg:block absolute bottom-0 left-0 w-40 rounded-bl-lg'></img>
                        <div className='lg:py-16 text-white relative'>
                            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629512/codler/Ellipse_60_3_eealyi.png'} className='absolute hidden lg:block right-0 top-0 w-72 rounded-tr-lg'></img>
                           <div className='relative p-4 mr-8 md:mt-5 lg:-mt-5 xl:mt-0 lg:p-0'>
                            <p className='text-2xl xl:text-[32px] font-gilroyregular font-semibold leading-[2rem] tracking-wide lg:w-full'>Branding</p>
                                <p className='text-xs md:text-sm lg:text-sm xs:pr-5 md:pr-0  font-light font-italic lg:w-full lg:mb-6'>Focusing on the power of the design to connect people with your brand in a meaningful way</p>
                                <p className='text-xs md:text-sm lg:text-sm xs:pr-5 md:pr-0  font-light font-gilroythin lg:w-full lg:mb-6'>Our creative heads are continually questioning and eternally curious, be it examining the technical details of the product or getting inside the minds of consumers. We bravely combine creativity with identity to create brands that live in the hearts of many. We craft stories that let you tell your story in a way that connects the gap between you and your spectators, allowing you to engage with them more quickly and efficiently.</p>
                            {/*                                <a href='#' className='flex no-underline text-white sbg5-btn w-36 rounded-full md:h-10 md:w-32 shadow-lg'><p className='my-2.5 lg:my-3 pl-4 pr-2 text-xs'>Learn more</p><i class="fa-solid fa-arrow-right text-xl pt-2 lg:pt-3"></i></a>
 */}
                           </div>
                           
                        </div>
                    </div>
                </div>
            </section>

            <section className='w-11/12 lg:w-10/12 mx-auto mt-16'>
                <div className='s-bg6 rounded-lg' data-aos="fade-up">
                    <div className='grid grid-cols-1 md:grid-cols-2 relative md:h-96'>
                        
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629514/codler/sss1_it1gpf.png'} className='hidden lg:block absolute bottom-0 left-0 w-40 rounded-bl-lg'></img>
                        <div className='py-8 lg:py-16 text-white relative'>
                            
                           <div className='relative pl-10 md:mt-5 lg:-mt-5 xl:mt-0 pr-4 lg:pl-24'>
                            <p className='text-2xl xl:text-[32px] font-gilroyregular font-semibold leading-[2rem] tracking-wide lg:w-3/4'>Search Engine Optimisation ( SEO )</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-italic font-light'>Increase your search rankings & get discovered more online with our SEO services</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-gilroythin font-light'>Codler uses strategies to get your business in the top searches and earn more revenue. We tailor SEO strategies according to your business model, making it easier to increase the business's credibility and boost your business growth. Earn qualified search traffic to your website with our search engine optimization services.</p>
                           {/*                                <a href='#' className='flex  no-underline text-white sbg6-btn w-36 rounded-full md:h-10 md:w-32 shadow-lg'><p className='my-2.5 lg:my-3 pl-4 pr-2 text-xs'>Learn more</p><i class="fa-solid fa-arrow-right text-xl pt-2 lg:pt-3"></i></a>
 */}                           </div>
                           
                        </div>
                        <div className='relative'>
                            <span className='flex justify-center'><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629514/codler/p6_tlfxla.png'} className=' py-4 relative z-10 h-60 md:h-72 md:w-[65%] md:mt-12 md:ml-0'></img></span>
                            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629514/codler/ss2_fhznjf.png'} className='absolute  hidden lg:block right-0 top-0 w-72 rounded-tr-lg'></img>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section className='w-11/12 lg:w-10/12 mx-auto mt-20' data-aos="fade-up">
                <div className='s-bg1 rounded-lg md:h-[450px] overflow-hidden' >
                    <div className='grid grid-cols-1 md:grid-cols-2 relative'>
                        <div><img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629513/codler/p1_ivmmeh.png'} className='md:w-10/12 md:mt-20 lg:mt-12 md:ml-12'></img></div>
                        <img src={require('../images/services/Ellipse 62.png')} className='absolute mt-72 bottom-10 left-0 w-40 rounded-bl-lg hidden lg:block'></img>
                        <div className=' lg:py-10 text-white relative'>
                            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629514/codler/shade1_ypr4ql.png'} className='absolute hidden lg:block right-0 top-0 w-72 rounded-tr-lg'></img>
                           <div className='relative lg:mr-10 ml-8 md:mt-5 lg:-mt-7 xl:mt-0  lg:ml-0 mb-5 lg:mb-0 lg:p-0'>
                            <p className='text-2xl xl:text-[32px] font-gilroyregular font-bold leading-[2.5rem] tracking-wide lg:w-full'>Mobile App Development Company</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-italic md:w-[90%]'>Codler has been engaged in custom software and app development across multiple industries.</p>
                                <p className='text-xs md:text-sm xs:pr-5 md:pr-0 font-gilroythin md:w-[90%]'>Our agile teams build scalable, world-class digital products with a focus on delivering the highest business value at every stage of the project.The idea of an app isn't enough to make an app successful unless you possess a solid digital strategy and a roadmap. From authenticating app ideas to choosing profit models, you require guidance from an accomplished consultant to avoid risks.<br></br>Codlerr is a leading web and mobile app development company in India. We assist clients in tallying their idea's feasibility, catalyzing a strategy, choosing the right tech stacks and laying out the probable compliances and current trends to get the best results.<br></br>Influence our web, Android, or iOS mobile app development services to find risks & resoluteness issues beforehand to make your app successful. Contact us with your innovative ideas to drive maximum results, whether you're a startup or an enterprise business.</p>
                           </div>
                           
                        </div>
                    </div>
                </div>
            </section>

            <section className='w-11/12 lg:w-10/12 mx-auto py-24'>
                <div className='w-10/12 mx-auto' data-aos="fade-up">
                    <p className='text-zinc-800 text-lg text-color leading-[4rem] font-gilroybold font-bold cursor-pointer md:text-[30px] lg:text-[50px] hover:text-purple-500 font-bold'>Developing a top-notch product is the result of researching and defining the current market as well as the goals you have in mind.</p>
                </div>
            </section>


            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629512/codler/bubhalf_bufeg5.png'} className='hidden  lg:block absolute w-28 left-0 -mt-40'></img>
            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629514/codler/tbubble_uuck1c.png'} className='hidden lg:block absolute w-14 left-40 -mt-20'></img>

            <section className='w-10/12 mx-auto bg-gradient-to-r from-zinc-900 via-zinc-800 to-zinc-900 rounded-xl mb-36' data-aos="zoom-in">
                <div className='w-10/12 mx-auto py-16'>
                    <div>
                    <p className='text-white text-lg font-semibold font-gilroythin leading-7'>We proudly work with<hr className='w-16 h-56 font-bold -mt-3 ml-48 md:ml-48 h-24 textlgcolor'></hr></p>
                        <div className='grid grid-cols-2 lg:grid-flow-col gap-6 mt-2' >
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242052/codler/ugrabin_1_uqvlzt.png'} className='w-56 h-20 mt-7'></img>
                       <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654512828/codler/car_chilly_au9rkh.png'} className='lg:w-36 lg:h-16 mt-10 xs:mt-12 lg:mt-9'></img>
                       <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242041/codler/CEANOR_xslfif.png'} className='w-64 h-20 xl:-ml-12 mt-4 '></img>
                       <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654242046/codler/ondaceimg_1_qg7zrj.png'} className='w-56 mt-4 h-16'></img>
                        </div>
                    </div>
                </div>
            </section>


            

           
       </div>
        )
}
export default Services;