import './App.css';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Header from './component/header';
import Home from './component/home';
import Footer from './component/footer';
import { Router, Switch, Route, useNavigate, Routes, useLocation } from "react-router-dom";
import Aboutus from './component/about-us';
import Contact from './component/contact-us';
import Technology from './component/technology';
import Services from './component/services';
import Servicedetails from './component/service-detail';
import Blog from './component/blog/blog';
import Bloginner from './component/blog/blog-inner';
import Faq from './component/faq';
import Portfolio from './component/portfolio';
import Portfolioinner from './component/portfolio/portfolio-inner';
import TechnologyInner from './component/technology/technology_inner';
import DigitalMarketing from './component/digital-marketing';
import Website from './component/website';
import Clients from './component/Clients/clients-details';
import HomeMain from './component/Index';
import Teams from './component/teams';
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PagenotFound from './component/pageNotFound'
function App() {

  return (
    <div className="bg-black  h-full w-full  ">
      <Header />
      <Helmet>
        <title>Codler | Best Digital marketing and Web & App Development company</title>
        <meta name="description" content="best software company in banglore" />
        <meta
          name="keywords"
          content="Best digital marketing company,Web Development in UAE,Best web Development company in UAE,Best app development
,Website design and development dubai, Website development abu dhabi, Website development abudhabi,Web Development company in
Riyadh,website design and development,best app development,best web development,web developemnt company,Web app development in singapore,Top IT companies in singapore
,Best IT companies in singapore,Website Design company singapore,Web development company in singapore"
        />
      </Helmet>
      <Routes>
        <Route element={<HomeMain />} path='/' />
        <Route element={<Aboutus />} path='/Aboutus' />
        <Route element={<Contact />} path='/Contactus' />
        <Route element={<DigitalMarketing />} path='/digital-marketing' />
        <Route element={<Website />} path='/website' />
        <Route element={<Clients />} path='/clients/:name' />
        <Route element={<Portfolio />} path='/portfolio' />
        <Route element={<Portfolioinner />} path='portfolio/:title' />
        <Route element={<Technology />} path='/technology' />
        <Route element={<TechnologyInner />} path='/technology/:title' />
        <Route element={<Services />} path='/services' />
        <Route element={<Servicedetails />} path='/services/servicedetails' />
        <Route element={<Faq />} path='/faq' />
        <Route element={<Blog />} path='/blog' />
        <Route element={<Bloginner />} path='/blog/:title' />
        <Route element={<Teams />} path='/teams' />
        <Route element={<PagenotFound />} path='*' />
        <Route element={<HomeMain />} path='/homemain' />

      </Routes>
      <ToastContainer />
      <Footer />

    </div>


  );
}

export default App;
