import Aos from "aos";
import "aos/dist/aos.css";
import { Router, Switch, Route, useNavigate, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import bub from "../../images/bloglist/tbubble.jpg";
import bubr from "../../images/bloglist/bubble1@2x 2 (1).png";
import bubl from "../../images/bloglist/bubble1@2x 2.png";
import line from "../../images/bloglist/Line 6.png";
import { Helmet } from "react-helmet";
import { Blogs } from "../../constants/constants";
import RecentlyAdded from "../blog/recently-added";
import axios from "axios";
import { Buffer } from "buffer";
import "../../App.css";
import { axiosInstance } from "../../component/api/apiConfig";
function Blog() {
	//------------------Api-end axios------------------//
	const [items, setItems] = useState([]);
	useEffect(() => {
		const fetchItems = async () => {
			await axios.get("https://codlerbackend.onrender.com/admin/get/api/blog").then((response) => {
				setItems(response.data);
			});
		};
		fetchItems();
	}, []);

	/**
	 * Function that transform the buffer to base64
	 * @param {*} image : Image buffer array
	 * @returns : Base64 image
	 */
	const getImage = (image) => Buffer.from(new Uint8Array(image)).toString("base64");

	console.log(items);
	//------------------Api-end axios------------------//

	function displayMainBlog(index) {
		console.log(index)
		return (
			<>
				<div className="grid grid-cols-1 lg:grid-cols-2 py-10" data-aos="fade-up">
					<div>
						<img src={`data:image/png;base64,${getImage(index.coverImage.data)}`} onClick={() => navigate(`/blog/${index.heading.slice(0,1)}`, { state: { blog: index } })} className="cursor-pointer  w-[100%] md:w-[90%]"></img>
					</div>
					<div className="xs:mt-4 md:mt-0 ">
						{/* <span className="flex">
							<img src={line} className="h-0.5 w-9 mt-[13px] pr-2"></img>
							<p className="text-white font-gilroyregular tracking-widest font-semibold">UI/ UX DESIGN</p>
						</span> */}
						<p className="cursor-pointer text-white text-xl lg:text-[23px] font-normal" >
							{index.heading.slice(0,1)}
						</p>
						<p className=" text-zinc-300 pr-10 h-32 overflow-hidden text-xs">{index.content}</p>
						<div className="md:flex  pt-3">
							<img src={`data:image/png;base64,${getImage(index.userImage.data)}`} className="cursor-pointer h-8 w-8 rounded-[50%]"></img>
							<div className="flex  md:pl-2">
								<p className="pl-0 lg:pl-10 pt-2  text-[12px] font-semibold cursor-pointer font-gilroythin text-zinc-400">{index.name}</p>
								<p className="pl-2.5 lg:pl-3 pt-2 text-[12px]  cursor-pointer font-gilroythin text-zinc-400">5 Mint read</p>
								<p className="pl-2.5 lg:pl-3 pt-2 text-[12px]  cursor-pointer font-gilroythin text-zinc-400"></p>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	function displayBlogs(index) {
		return (
			<div data-aos="fade-up">
				<img src={`data:image/png;base64,${getImage(index.coverImage.data)}`} onClick={() => navigate(`/blog/${index.heading.slice(0,1)}`, { state: { blog: index } })} className="cursor-pointer rounded w-96 h-48 md:h-40"></img>
				<p className="text-white font-gilroylight tracking-wide font-semibold pt-4 cursor-pointer">{index.heading.slice(0, 1)}</p>
				<div className="h-36 overflow-hidden">
					<p className="text-white font-gilroylight text-xs" limit={10}>
						{index.content.slice(0, 1)}
						{/* {index.creatorImage} */}
					</p>
				</div>
				<div className="flex lg:mt-10">
					<img src={`data:image/png;base64,${getImage(index.userImage.data)}`} className="h-8 w-8 rounded-[50%]"></img>
					<p className="text-zinc-600 ml-2 textlbg font-gilroythin font-semibold text-[12px]">{index.name}</p>
				</div>
				<div className="flex ml-8 -mt-4 font-gilroythin text-zinc-200  text-[10px]">
					<p className="px-2">{index.date}</p>
					<p>5 Mint read</p>
				</div>
			</div>
		);
	}

	useEffect(() => {
		Aos.init({ duration: 2000 });
	}, []);
	const navigate = useNavigate("");
	return (
		<div>
			<Helmet>
				<title>Blog</title>
				<meta name="description" content="Read our blogs" />
			</Helmet>
			<section>
				<div className="blogbg">
					<img src={bub} className="hidden lg:block pt-96 absolute w-8 right-14"></img>
					<img src={bub} className="hidden lg:block pt-96 absolute w-16 right-28"></img>
					<img src={bubr} className="hidden lg:block pt-40 absolute w-32 right-0"></img>
					<div className="text-white text-center sm:pt-[60%] md:pt-48" data-aos="fade-up">
						<span className="flex sm:ml-28 md:ml-[43%]">
							<img src={line} className="w-8 h-0.5 mt-[12px] pr-2"></img>
							<p className="font-semibold font-gilroyregular tracking-wider sm:mt-0 md:mt-10 lg:mt-0">OUR BLOGS</p>
						</span>
						<p className="text-3xl lg:text-[40px] font-bold -mt-4 md:mt-20 lg:mt-0" data-aos="fade-up-right" data-aos-delay="400">
							This is where we tell stories
						</p>
					</div>
					<img src={bub} className="hidden lg:block -mt-40 absolute w-8 left-20"></img>
					<img src={bub} className="hidden lg:block -mt-40 absolute w-16 left-32"></img>
					<img src={bubl} className="hidden lg:block -mt-40 absolute w-28 left-0"></img>
				</div>
			</section>

			<section className="md:mb-16">
				<div className="w-11/12 mx-auto">
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pb-20">
						{/* left start */}
						<div className="lg:col-span-3">
							{items.slice(0, 1).map(displayMainBlog)}
							{/* center */}
							<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">{items.slice(1).map(displayBlogs)}</div>
						</div>

						{/* left end */}
						{/* right */}
						<div className="pl-2 ">
							<div className="pt-10 relative" data-aos="fade-up">
								<input className="rounded-full h-10 w-full bg-zinc-700 outline-none text-white pl-10 pr-36 " type="text" placeholder="Search"></input>
								<span className="absolute left-5 pt-2">
									<i class="fa-solid fa-magnifying-glass text-white"></i>
								</span>
							</div>

							<div className="pt-3" data-aos="fade-up">
								<p className="text-white lg:text-lg font-semibold">Recommended Topics</p>
								<div className="grid grid-cols-3 grid-rows-3 gap-x-2">
									<div>
										<a href="#" className="no-underline hover:no-underline text-zinc-300 hover:text-white">
											<p className="bg-zinc-900 rounded-xl text-xs w-16 h-7 pt-1 text-center">Design</p>
										</a>
									</div>
									<div>
										<a href="#" className="no-underline hover:no-underline text-zinc-300 hover:text-white">
											<p className="bg-zinc-900 rounded-xl text-xs w-16 h-7 -ml-5 pt-1 text-center">Creativity</p>
										</a>
									</div>
									<div>
										<a href="#" className="no-underline hover:no-underline text-zinc-300 hover:text-white">
											<p className="bg-zinc-900 rounded-xl text-xs w-20 -ml-10 h-7 pt-1 text-center">Technology</p>
										</a>
									</div>
									<div>
										<a href="#" className="no-underline hover:no-underline text-zinc-300 hover:text-white">
											<p className="bg-zinc-900 rounded-xl text-xs w-20 h-7 pt-1 text-center">Productivity</p>
										</a>
									</div>
									<div>
										<a href="#" className="no-underline hover:no-underline text-zinc-300 hover:text-white">
											<p className="bg-zinc-900 rounded-xl text-xs w-16 h-7 pt-1 -ml-3 text-center">React</p>
										</a>
									</div>
									<div>
										<a href="#" className="no-underline hover:no-underline text-zinc-300 hover:text-white">
											<p className="bg-zinc-900 rounded-xl text-xs w-16 h-7 pt-1 -ml-8 text-center">UI Design</p>
										</a>
									</div>
									<div>
										<a href="#" className="no-underline hover:no-underline text-zinc-300 hover:text-white">
											<p className="bg-zinc-900 rounded-xl text-xs w-16 h-7 pt-1 text-center">Java script</p>
										</a>
									</div>
									<div>
										<a href="#" className="no-underline hover:no-underline text-zinc-300 hover:text-white">
											<p className="bg-zinc-900 rounded-xl text-xs w-28 -ml-6 h-7 pt-1 text-center">Artificial Intelligence</p>
										</a>
									</div>
									<div>
										<a href="#" className="no-underline hover:no-underline text-zinc-300 hover:text-white">
											<p className="bg-zinc-900 rounded-xl text-xs w-12  h-7 pt-1 text-center">Art</p>
										</a>
									</div>
								</div>
							</div>
							{/* Recently added */}
							<div className="pt-2" data-aos="fade-up">
								<p className="text-white lg:text-lg font-semibold" data-aos="fade-up">
									Recently added
								</p>
								<RecentlyAdded data={items}/>
							</div>
							{/* Recently added */}
						</div>
						{/* right end */}
					</div>
				</div>
			</section>
		</div>
	);
}
export default Blog;
