import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect,useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Slider2 from '../sliders/slider2';
import Slider3 from '../sliders/slider3';
import '../../App.css';
import axios from "axios";
import { Buffer } from "buffer";
import {Portfoliolist} from '../../constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ClienSlider from '../sliders/client-slider';

function PortfolioInner() {
    const location=useLocation();
    	//------------------Api-end axios------------------//
	const [items, setItems] = useState([]);
	useEffect(() => {
		const fetchItems = async () => {
			await axios.get("https://codlerbackend.onrender.com/admin/get/api/portfolioinner").then((response) => {
				setItems(response.data);
			});
		};
		fetchItems();
	}, []);

	/**
	 * Function that transform the buffer to base64
	 * @param {*} image : Image buffer array
	 * @returns : Base64 image
	 */
	const getImage = (image) => Buffer.from(new Uint8Array(image)).toString("base64");

	console.log(items);
	//------------------Api-end axios------------------//
    
    function display(index){
        return(
            <div className='w-96 h-96 bg-white rounded-lg'>
               <a href='https://www.ugrab.in/' className='portfolio-image-main' style={{opacity:'1',transform:'matrix3d(1, 0.000174533, 0, 0, -0.000174533, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0.1, 1'}}>
               <div className='potfolio-image-box w-56 h-56 ml-12'>
               <div className='portfolio-image-outerbox ' style={{background:`url(${index.src})`}}>
               <div className='portfolio-image'>
                </div> 
               <div className='link-box w-96 h-10 '>
               <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654709573/codler/link-icon_djrfm3.png'} className='-ml-4 mt-2' alt='img'/>
               <p className='link-text ml-2 -mt-4'>{index.website}</p>
             </div> 
        <div className='show-eye '>
              <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1654709545/codler/eye_kggmye.png'} className='mt-2 ml-1' alt=''/>
        </div>   
            <p className='text-white'></p> 
        </div>
        </div>
        </a>
            </div>
        )
    }
    function displayItems(index){
        console.log(index.title);
        console.log(data.name)
        if(index.title===data.name){
            console.log("working")
        }
        
        return(
            <>
                {(index.title===data.name)?(<div>


                    {/*  */}
                    
                    {/*  */}
                <section className=''>
                {/* <img src={shade} className='pt-60 lg:pt-12 blur-xl  lg:blur-lg z-0 absolute' data-aos="zoom-in"></img> */}
                    <div className='w-10/12 mx-auto  xl:mx-20 grid grid-cols-1 md:grid-cols-2 blur-0 lg:pb-32'>
                        <div className='lg:pt-72 lg:pb-40 grid place-items-center md:place-items-start' data-aos="fade-up">
                            <p className='text-white text-2xl md:mt-5 lg:text-7xl font-gilroybold'>{index.title}</p>
                           
                        </div>
                        <div className=' client-banner-side grid place-items-center' data-aos="fade-up">
                         <img src={`data:image/png;base64,${getImage(index.bnimage.data)}`} className=''></img>
                        </div>
                    </div>
                
                </section>
    
                <section className='port-sec2-bg'>
                        <div className='w-10/12 mx-auto   py-16 lg:py-32'>
                            <div className='text-white'>
                                <p className='text-2xl text-center lg:text-4xl font-semibold'>About {index.title}</p>
                                <div data-aos="fade-up">
                                    <p className='lg:w-[80%] mx-auto text-center text-sm lg:text-lg text-zinc-300 capitalize'>{index.aboutdesc}</p>
                                </div>
                            </div>
    
                            {/* <div className=' flex justify-center lg:py-5' data-aos="fade-up">
                                <img src={`data:image/png;base64,${getImage(index.bnimage.data)}`} className='w-36 h-24'></img>
                            </div> */}
                        </div>
                </section>
    
                {/* <section className='port-sec3-bg bg-ceanor'>
    
                    <div className='w-10/12 mx-auto py-20 text-white' data-aos="fade-up">
                        <p className='text-3xl lg:text-4xl md:mt-36 lg:mt-0 font-semibold'>Our role</p>
                        <p className='lg:w-2/4 text-zinc-300'>{index.role}</p>
                    </div>
                       
                   
                       
                </section>
                <section>
                <div className='hidden lg:block port-sec4-bg lg:-mt-32 lg:mb-20'>
                        <p className='mx-auto lg:py-8 text-gray-700  text-2xl md:text-5xl font-bold lg:w-4/5' data-aos="zoom-in">Delivering a high-quality<br></br> user-experience.</p>
                    </div>
                    <img src={drop} className='hidden lg:block absolute left-0 -mt-28 w-20'></img>
                </section> */}
                
                <section className='w-10/12 mx-auto pt-32 lg:pt-0'>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                            <div className=' flex justify-center p-5' data-aos="fade-up">
                                <img src={`data:image/png;base64,${getImage(index.opimage.data)}`} className='w-96'></img>
                            </div>
                            <div className='lg:py-32 lg:px-10' data-aos="fade-up">
                                <p className='text-white text-3xl lg:text-4xl font-semibold'>The Oppertunity</p>
                                <p className='text-[#bdbaba] text-lg font-gilroymedium'>{index.opportunity}</p>
                            </div>
                    </div>
                </section>
                    
                <section className='w-10/12 mx-auto pt-24 lg:pt-0 lg:-mt-14 pb-10'>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                            <div className='lg:py-32 lg:px-10' data-aos="fade-up">
                                <p className='text-white text-3xl lg:text-4xl font-semibold'>What we delivered</p>
                                <p className='text-[#bdbaba] text-lg font-gilroymedium'>{index.delivered}</p>
                            </div>
                            <div className=' flex justify-center p-5' data-aos="fade-up">
                                <img src={`data:image/png;base64,${getImage(index.deimage.data)}`} className='w-96'></img>
                            </div>
                    </div>
                </section>

                <section className='w-9/12 md:w-10/12 mx-auto py-20'>
                    <ClienSlider/>
                </section>


                {/* <section className='py-3  mb-56 hidden lg:block '>
                <Slider2/>
                </section> */}

    {/* <section className='py-3 md:py-12 mb-12'>
    <div className='wavy ml-16'>
        <span style={{'--i':1}}>O</span>
        <span style={{'--i':2}}>U</span>
        <span style={{'--i':3}}>R</span>
        <span style={{'--i':4}} className='ml-3' >R</span>
        <span style={{'--i':5}}>e</span>
        <span style={{'--i':6}}>c</span>
        <span style={{'--i':7}}>e</span>
        <span style={{'--i':8}}>n</span>
        <span style={{'--i':9}}>t</span>
        <span style={{'--i':10}} className='ml-3'>W</span>
        <span style={{'--i':11}}>o</span>
        <span style={{'--i':12}}>r</span>
        <span style={{'--i':13}}>k</span>
        <span style={{'--i':14}}>s</span>

    </div>

    <Slider3/>
    </section> */}
    </div>):(<div></div>)}
    </>
        )
    }
    
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    const navigate = useNavigate('');
    const data=location.state.portfolio;
   return (
    <>
            {items.map(displayItems)}
            </>
   )}
                
export default PortfolioInner;
