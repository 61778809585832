import React from 'react'
import { Link } from 'react-router-dom'

export default function PageNotFound() {
  return (
    <div >
        <main class="h-screen w-full flex flex-col justify-center items-center bg-[#1A2238]">
	<h1 class="text-9xl font-extrabold text-white tracking-widest">404</h1>
	<div class="bg-gradient-to-r from-fuchsia-500 to-violet-700 px-2 text-sm rounded text-white rotate-12 absolute">
		Page Not Found
	</div>
	<button class="mt-5">
      <a
        class="relative inline-block text-sm font-medium text-[#FF6A3D] group active:text-orange-500 focus:outline-none focus:ring"
      >
        <span
          class="absolute inset-0 transition-transform translate-x-1 translate-y-1 bg-gradient-to-r from-fuchsia-500 to-violet-700 group-hover:translate-y-0 group-hover:translate-x-0"
        ></span>

        <span class="relative block px-8 py-3 bg-[#1A2238] border border-current">
          <Link to="/" className='no-underline hover:no-underline text-fuchsia-500 hover:text-[#FFFF]'>Go Home</Link>
        </span>
      </a>
    </button>
</main>
    </div>
  )
}
