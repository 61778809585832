import React, {useRef,useState} from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick";
import {BsArrowLeft, BsArrowRight,} from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
// import { images } from "../constants/constants";


let NFTVID = 'https://res.cloudinary.com/dvbplh4z9/video/upload/v1668490869/codler%20branding%20page/DX3_NFT_Studios_Web_Page_Video_2_yr4xdy.mp4'



function SampleNextArrow({onClick}) {
  return (
    <div className='arrow2 arrow-right2' onClick={onClick}>
      <BsArrowRight/>
    </div>
  );
}

function SamplePrevArrow({onClick}) {
  return (
    <div className='arrow2 arrow-left2' onClick={onClick}>
      <BsArrowLeft/>
    </div>
  );
}
function EmptyArrow({onClick}) {
  return (
    <div></div>
  );
}


function Slider2() {

  const navigate = useNavigate('');
    const [slideIndex, setSlideIndex] = useState(0);

    const settings = {
      dots: false,
      infinite: true,
      speed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 40000,
      beforeChange: (current, next)=>setSlideIndex(next),
      centerMode: false,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      appendDots: (dots) => (
        <div>
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: (current, next) => (
        <div className={current === slideIndex ? 'dot dot-active' : 'dot'}>
        </div>
      ),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          }
        },
      ]
    };
  
    return (
      <div>

<div className="container">
        <div className="slider2 ">
      <Slider {...settings}>

      {/* item 1 */}
      <div className='w-11/12 lg:w-10/12 rounded-xl lg:h-[500px] mb-5 z-200   relative bg-zinc-800 bg-[rgba(108,122,137,0.2)] mx-auto'>
            <div className='md:grid grid-cols-1 md:grid-cols-2  z-200 relative '>

               <div className='xl:pl-24 px-10 xs:pt-24 md:pt-20 '>
                <div className='relative'>
                <img className='absolute h-5 md:h-6 w-40 md:w-56 -ml-3  z-0 -rotate-6' src='https://res.cloudinary.com/dpsamxitw/image/upload/v1660806523/codler/scratch2_msy6bf.png'></img>

                <h4 className="text-sm md:text-lg leading-2  tracking-widest relative  text-white font-gilroymedium" >OUR RECENT WORK</h4><hr className='w-10 md:w-16 h-24 text-[#CC8899] font-bold text-3xl -mt-[25px] ml-48 md:ml-56' style={{height:'3px'}}/>
                </div>
                                <h1 className="lg:text-4xl text-2xl font-bold text-white tracking-wider" data-aos="fade-right">Car Rental App – CAR CHILLI</h1>
                                <p className="md:text-sm lg:text-lg text-sm font-light  font-gilroylight mt-3 text-zinc-300 xl:pr-20 xl:w-[530px]">A Singapore based car rental mobile app where a user can rent a car from its owner quickly and easily. We were able to change the UI design from a basic structure to a better UI that interacts with the customers—researched how the app should be, and changed the logo to a better one. </p>
                              <div className='pt-2'>
                                <div className='w-40 h-20'>
                                    <img  onClick={()=>navigate('/portfolio')}  src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                                    <p onClick={()=>navigate('/portfolio')} className='text-white -mt-[48px] relative z-200 rounded-lg  text-center py-3 text-sm w-40 h-12 cursor-pointer lets-btn hover:ml-1 hover:-mt-[52px]'>View All Projects<FontAwesomeIcon icon={faArrowRight} className="text-base ml-2  mt-0.5 text-white cursor-pointer" /></p>
                                </div>
                                </div> 
                    </div>
                    <div className="w-full grid place-items-center" >
                              <LazyLoadImage effect='blur' src={'https://res.cloudinary.com/dpsamxitw/image/upload/v1661855712/codler/Image_gl7vsx_kvbrxb_xjsi8m.webp'} alt="mobile"  className="p-2 md:p-5 lg:p-16 md:p-0 opacity-70  md:w-[500px] md:h-[400px] lg:h-[500px]"/>
                   </div>
            </div>
        </div> 
        {/* item 1 */}


        {/* item 2 */}
      <div className='w-11/12 lg:w-10/12 rounded-xl lg:h-[500px] mb-5 z-200   relative bg-zinc-800 bg-[rgba(108,122,137,0.2)] mx-auto'>
            <div className='md:grid grid-cols-1 md:grid-cols-2 md:gap-5  z-200 relative xs:py-14 xs:px-5 md:py-10 md:px-5 lg:p-20'>

               <div className='xs:pt-10 md:pt-0'>
                <div className='relative'>
                <img className='absolute h-5 md:h-6 w-40 md:w-56 -ml-3  z-0 -rotate-6' src='https://res.cloudinary.com/dpsamxitw/image/upload/v1660806523/codler/scratch2_msy6bf.png'></img>

                <h4 className="text-sm md:text-lg leading-2  tracking-widest relative  text-white font-gilroymedium" >OUR RECENT WORK</h4><hr className='w-10 md:w-16 h-24 text-[#CC8899] font-bold text-3xl -mt-[25px] ml-48 md:ml-56' style={{height:'3px'}}/>
                </div>
                                <h1 className="lg:text-4xl text-2xl font-bold text-white tracking-wider" data-aos="fade-right">Dubai NFT studios</h1>
                                <p className="md:text-sm lg:text-lg text-sm font-light  font-gilroylight mt-3 text-zinc-300 xl:pr-20 xl:w-[530px]">DITS is a Hong kong based startup built and launched in bringing you the latest and trending concepts in the world of NFT. We were able to develop a SEO website for their startup which helped them in organic growth of their business.</p>
                              <div className='pt-2'>
                                <div className='w-40 h-20'>
                                    <img  onClick={()=>navigate('/portfolio')}  src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                                    <p onClick={()=>navigate('/portfolio')} className='text-white -mt-[48px] relative z-200 rounded-lg  text-center py-3 text-sm w-40 h-12 cursor-pointer lets-btn hover:ml-1 hover:-mt-[52px]'>View All Projects<FontAwesomeIcon icon={faArrowRight} className="text-base ml-2  mt-0.5 text-white cursor-pointer" /></p>
                                </div>
                                </div> 
                    </div>
                    <div className="w-full grid place-items-center ">
                          <video className='w-full ' loop="true"  controls muted>
                            <source type="video/mp4" src={NFTVID}></source>
                          </video>
                   </div>
            </div>
        </div> 
        {/* item 2 */}

        {/* item 3 */}
        <div className='w-11/12 lg:w-10/12 rounded-xl lg:h-[500px] mb-5 z-200   relative bg-zinc-800 bg-[rgba(108,122,137,0.2)] mx-auto'>
                    <div className='md:grid grid-cols-1 md:grid-cols-2  z-200 relative '>

               <div className='xl:pl-24 px-10 xs:pt-24 md:pt-20'>
                <div className='relative'>
                <img className='absolute h-5 md:h-6 w-40 md:w-56 -ml-3  z-0 -rotate-6' src='https://res.cloudinary.com/dpsamxitw/image/upload/v1660806523/codler/scratch2_msy6bf.png'></img>

                <h4 className="text-sm md:text-lg leading-2  tracking-widest relative  text-white font-gilroymedium" >OUR RECENT WORK</h4><hr className='w-10 md:w-16 h-24 text-[#CC8899] font-bold text-3xl -mt-[25px] ml-48 md:ml-56' style={{height:'3px'}}/>
                </div>
                                <h1 className="lg:text-4xl text-2xl font-bold text-white tracking-wider" data-aos="fade-right">NMS- New Middle east solutions</h1>
                                <p className="md:text-sm lg:text-lg text-sm font-light  font-gilroylight mt-3 text-zinc-300 xl:pr-20 xl:w-[530px]">New Middle east Solutions is an Oman based company, who provide services such as  ERP & POS software, VAT consultancy to various startups in various fields. We were able to provide our Digital Marketing services to them which helped them in marketing their services.</p>
                              <div className='pt-2'>
                                <div className='w-40 h-20'>
                                    <img  onClick={()=>navigate('/portfolio')}  src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659425310/codler/Subtraction_1_zetl4a.svg'/>
                                    <p onClick={()=>navigate('/portfolio')} className='text-white -mt-[48px] relative z-200 rounded-lg  text-center py-3 text-sm w-40 h-12 cursor-pointer lets-btn hover:ml-1 hover:-mt-[52px]'>View All Projects<FontAwesomeIcon icon={faArrowRight} className="text-base ml-2  mt-0.5 text-white cursor-pointer" /></p>
                                </div>
                                </div> 
                    </div>
                    <div className="w-full grid place-items-center" >
                              <LazyLoadImage effect='blur' src={'https://res.cloudinary.com/dvbplh4z9/image/upload/v1675404379/codler%20branding%20page/Frame_38_eysuql.webp'} alt="mobile"  className="p-2 md:p-5 lg:p-16 md:p-0 opacity-70  md:w-[450px] md:h-[400px] lg:h-[500px]"/>
                   </div>
            </div>
        </div> 
        {/* item 3 */}
        

      </Slider>
        </div>
    </div>


        
      </div>
    );
}
export default Slider2;