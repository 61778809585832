import React, {useRef,useState,useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../../digital-marketing.css'
import {BsArrowLeft, BsArrowRight,} from 'react-icons/bs' 
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from 'axios';
import { Buffer } from 'buffer'
import { useLocation } from 'react-router'
import { useNavigate } from "react-router-dom";


function SampleNextArrow({onClick}) {
    return (
      <div className='arrow arrow-right  shadow-none  -mt-10' onClick={onClick}>
        <LazyLoadImage className="" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1668254577/codler%20branding%20page/Icon_awesome-arrow-alt-circle-left_1_mixhyp.svg"></LazyLoadImage>
      </div>
    );
  }
  
  function SamplePrevArrow({onClick}) {
    return (
      <div className='arrow arrow-left  shadow-none  -mt-10' onClick={onClick}>
        <LazyLoadImage className="" src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1668254577/codler%20branding%20page/Icon_awesome-arrow-alt-circle-left_rpelud.svg"></LazyLoadImage>
      </div>
    );
  }


function Clientslider() {

    const location = useLocation();
    const clientDetails = location.state.clients;
    const [items, setItems] = useState([]);
    
	useEffect(() => {
		const fetchItems = async () => {
			await axios.get("https://codlerbackend.onrender.com/admin/get/api/clients").then((response) => {
				setItems(response.data);
			});
		};
		fetchItems();
	}, []);

   /**
	 * Function that transform the buffer to base64
	 * @param {*} image : Image buffer array
	 * @returns : Base64 image
	 */
	const getImage = (image) => Buffer.from(new Uint8Array(image)).toString("base64");
    const navigate = useNavigate('');


    function displaySliderView(index){
        return(
            <div className="">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-5 text-white">

                            <div className="grid place-items-center">
                                <img src={`data:image/png;base64,${getImage(index.testiLogo.data)}`}></img>
                            </div>
                            <div className="md:col-span-3">
                                <div className="py-2 flex gap-2 justify-center md:justify-start">
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                </div>
                                <p className="text-xl lg:text-2xl font-gilroysemibold w-[95%] xs:text-center md:text-left">{index.testiDesc}</p>
                                    <p className="text-base md:text-xl font-gilroysemibold xs:text-center md:text-left">{index.testiName}</p>
                                    <p className="text-base md:text-xl font-gilroysemibold xs:text-center md:text-left">{index.testiDesig}</p>
                            </div>
                    </div>

                    
                </div>
        )
    }


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      

      };
  
    return (
     <div className="page-inner-slider">
        <Slider {...settings}>
            
        {items.map(displaySliderView)}
                {/* <div className="">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-5 text-white">

                            <div className="grid place-items-center">
                                <img src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1668254577/codler%20branding%20page/Group_1_icjikf.png"></img>
                            </div>
                            <div className="md:col-span-3">
                                <div className="py-2 flex gap-2 justify-center md:justify-start">
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                </div>
                                <p className="text-xl lg:text-4xl font-gilroysemibold w-[95%] xs:text-center md:text-left">Major Tom integrated their agency into our
                                    existing infrastructure to help us scale our
                                    digital marketing strategy quickly and
                                    efficiently. We needed to reach a broad
                                    market with many competitors, and Major
                                    Tom made our strategy feel personal.</p>
                                    <p className="text-base md:text-xl font-gilroysemibold xs:text-center md:text-left">Jane Doe</p>
                                    <p className="text-base md:text-xl font-gilroysemibold xs:text-center md:text-left">CEO, DXB NFT Studios</p>
                            </div>
                    </div>

                    
                </div>




                <div className="">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-5 text-white">

                            <div className="grid place-items-center">
                                <img src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1668254577/codler%20branding%20page/Group_1_icjikf.png"></img>
                            </div>
                            <div className="md:col-span-3">
                                <div className="py-2 flex gap-2 justify-center md:justify-start">
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                </div>
                                <p className="text-xl lg:text-4xl font-gilroysemibold w-[95%] xs:text-center md:text-left">Major Tom integrated their agency into our
                                    existing infrastructure to help us scale our
                                    digital marketing strategy quickly and
                                    efficiently. We needed to reach a broad
                                    market with many competitors, and Major
                                    Tom made our strategy feel personal.</p>
                                    <p className="text-base md:text-xl font-gilroysemibold xs:text-center md:text-left">Jane Doe</p>
                                    <p className="text-base md:text-xl font-gilroysemibold xs:text-center md:text-left">CEO, DXB NFT Studios</p>
                            </div>
                    </div>

                    
                </div>




                <div className="">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-5 text-white">

                            <div className="grid place-items-center">
                                <img src="https://res.cloudinary.com/dvbplh4z9/image/upload/v1668254577/codler%20branding%20page/Group_1_icjikf.png"></img>
                            </div>
                            <div className="md:col-span-3">
                                <div className="py-2 flex gap-2 justify-center md:justify-start">
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                    <span><i class="fa-solid fa-star text-[#7521A8] text-2xl"></i></span>
                                </div>
                                <p className="text-xl lg:text-4xl font-gilroysemibold w-[95%] xs:text-center md:text-left">Major Tom integrated their agency into our
                                    existing infrastructure to help us scale our
                                    digital marketing strategy quickly and
                                    efficiently. We needed to reach a broad
                                    market with many competitors, and Major
                                    Tom made our strategy feel personal.</p>
                                    <p className="text-base md:text-xl font-gilroysemibold xs:text-center md:text-left">Jane Doe</p>
                                    <p className="text-base md:text-xl font-gilroysemibold xs:text-center md:text-left">CEO, DXB NFT Studios</p>
                            </div>
                    </div>

                    
                </div> */}
           
            </Slider>
     </div>
    );
}

export default Clientslider;