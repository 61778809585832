import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect,useState } from 'react';
import { Blogs } from '../../constants/constants';
import { useLocation} from 'react-router';
import RecentlyAdded from "../blog/recently-added";
import { Buffer } from "buffer";
import axios from "axios";

function Bloginner() {

    const location =useLocation();
    const blogDetails=location.state.blog;
    const [items, setItems] = useState([]);
	useEffect(() => {
		const fetchItems = async () => {
			await axios.get("https://codlerbackend.onrender.com/admin/get/api/blog").then((response) => {
				setItems(response.data);
			});
		};
		fetchItems();
	}, []);


    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    const getImage = (image) => Buffer.from(new Uint8Array(image)).toString("base64");
    return (
        <div>

            <section className='mx-6 lg:ml-20 lg:mr-20 pt-28 pb-10'>
                <div className='grid grid-cols-1 lg:grid-cols-4'>
                    <div className='col-span-3 text-white'>
                        <div className=''>
                            <div className='md:flex py-4' data-aos="fade-up">
                                <img src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653064095/codler/Image_4_qeidyk.webp' className='w-8 h-8 mr-3 rounded-[50%]'></img>
                                <div className='flex'>
                                    <p className='text-xs lg:text-xs font-light text-zinc-400 pl-0 lg:pl-5 pt-2'>{blogDetails.name}</p>
                                    <p className='text-xs lg:text-xs font-light text-zinc-400 pl-2.5 lg:pl-5 pt-2'></p>
                                    <p className='text-xs lg:text-xs font-light text-zinc-400 pl-2.5 lg:pl-5 pt-2'>5 Mint read</p>
                                </div>
                            </div>
                            <p className='text-2xl lg:text-4xl font-bold text-white '>{blogDetails.heading.slice(0,1)}</p>
                            <img src={`data:image/png;base64,${getImage(blogDetails.coverImage.data)}`}  className='my-4 h-44 lg:h-96 w-[100%] lg:w-[750px]' data-aos="fade-up"></img>
                            <p className='text-sm lg:text-sm lg:w-[90%] text-white font-gilroymedium'>{blogDetails.content.slice(0,1)}</p> 

                      
                        </div>

                       

                        <div className='pt-3' data-aos="fade-up">
                            <p className='lg:text-2xl font-semibold'>{blogDetails.heading.slice(1,2)}</p>
                            <p className='lg:w-[90%]   text-sm lg:text-sm font-gilroymedium text-zinc-200'>{blogDetails.content.slice(1,2)}</p>
      
                        </div>

                        <div className='pt-3' data-aos="fade-up">
                            <p className='lg:text-2xl font-semibold'>{blogDetails.heading.slice(2,3)}</p>
                            <p className='lg:w-[90%]  text-sm lg:text-sm font-light text-zinc-200'>{blogDetails.content.slice(2,3)}</p>
      
                        </div>

                        <div className='pt-3' data-aos="fade-up">
                            <p className='lg:text-2xl font-semibold'>{blogDetails.heading.slice(3,4)}</p>
                            <p className='lg:w-[90%]  text-sm lg:text-sm font-light text-zinc-200'>{blogDetails.content.slice(3,4)}</p>
      
                        </div>

                        <div className='pt-3' data-aos="fade-up">
                            <p className='lg:text-2xl font-semibold'>{blogDetails.heading.slice(4,5)}</p>
                            <p className='lg:w-[90%]  text-sm lg:text-sm font-light text-zinc-200'>{blogDetails.content.slice(4,5)}</p>
      
                        </div>

                        <div className='pt-3' data-aos="fade-up">
                            <p className='lg:text-2xl font-semibold'>{blogDetails.heading.slice(5,6)}</p>
                            <p className='lg:w-[90%]  text-sm lg:text-sm font-light text-zinc-200'>{blogDetails.content.slice(5,6)}</p>
      
                        </div>

                        <div className='pt-3' data-aos="fade-up">
                            <p className='lg:text-2xl font-semibold'>{blogDetails.heading.slice(6,7)}</p>
                            <p className='lg:w-[90%]  text-sm lg:text-sm font-light text-zinc-200'>{blogDetails.content.slice(6,7)}</p>
      
                        </div>

                        <div className='pt-3' data-aos="fade-up">
                            <p className='lg:text-2xl font-semibold'>{blogDetails.heading.slice(7,8)}</p>
                            <p className='lg:w-[90%]  text-sm lg:text-sm font-light text-zinc-200'>{blogDetails.content.slice(7,8)}</p>
      
                        </div>

                       

                       





                        

                        
                    </div>


                    <div className=''>
                    <div className='pl-2 ' >
                            <div className='pt-10 relative' data-aos="fade-up">
                                <input className='rounded-full h-10 w-full bg-zinc-700 outline-none text-white pl-10 pr-36 ' type='text' placeholder='Search'></input>
                               <span className='absolute left-5 pt-2'><i class="fa-solid fa-magnifying-glass text-white"></i></span>

                            </div>

                            <div className='pt-3' data-aos="fade-up">
                                <p className='text-white lg:text-lg font-semibold'>Recommended Topics</p>
                                <div className='grid grid-cols-3 grid-rows-3 gap-x-2' >
                                    <div><a href='#' className='no-underline hover:no-underline text-zinc-300 hover:text-white'><p className='bg-zinc-900 rounded-xl text-xs w-16 h-7 pt-1 text-center'>Design</p></a></div>
                                    <div><a href='#' className='no-underline hover:no-underline text-zinc-300 hover:text-white'><p className='bg-zinc-900 rounded-xl text-xs w-16 h-7 -ml-5 pt-1 text-center'>Creativity</p></a></div>
                                    <div><a href='#' className='no-underline hover:no-underline text-zinc-300 hover:text-white'><p className='bg-zinc-900 rounded-xl text-xs w-20 -ml-10 h-7 pt-1 text-center'>Technology</p></a></div>
                                    <div><a href='#' className='no-underline hover:no-underline text-zinc-300 hover:text-white'><p className='bg-zinc-900 rounded-xl text-xs w-20 h-7 pt-1 text-center'>Productivity</p></a></div>
                                    <div><a href='#' className='no-underline hover:no-underline text-zinc-300 hover:text-white'><p className='bg-zinc-900 rounded-xl text-xs w-16 h-7 pt-1 -ml-3 text-center'>React</p></a></div>
                                    <div><a href='#' className='no-underline hover:no-underline text-zinc-300 hover:text-white'><p className='bg-zinc-900 rounded-xl text-xs w-16 h-7 pt-1 -ml-8 text-center'>UI Design</p></a></div>
                                    <div><a href='#' className='no-underline hover:no-underline text-zinc-300 hover:text-white'><p className='bg-zinc-900 rounded-xl text-xs w-16 h-7 pt-1 text-center'>Java script</p></a></div>
                                    <div><a href='#' className='no-underline hover:no-underline text-zinc-300 hover:text-white'><p className='bg-zinc-900 rounded-xl text-xs w-28 -ml-6 h-7 pt-1 text-center'>Artificial Intelligence</p></a></div>
                                    <div><a href='#' className='no-underline hover:no-underline text-zinc-300 hover:text-white'><p className='bg-zinc-900 rounded-xl text-xs w-12  h-7 pt-1 text-center'>Art</p></a></div>
                                </div>
                            </div>
                            <div className='pt-2' data-aos="fade-up">
                                <p className='text-white lg:text-lg font-semibold' data-aos="fade-up">Recently added</p>    
                                <RecentlyAdded data={items}/>
                            </div>
                            </div>
                    </div>
                </div>
            </section>
           


        </div>
    )
}
export default Bloginner;