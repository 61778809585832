import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect,useState } from 'react';
import dp from '../images/faq/dp.png';
import shade from '../images/faq/lineshade.png';
import bub from '../images/faq/bubble1@2x 2.png';
import h1 from '../images/faq/General.png';
import h2 from '../images/faq/Web development..png';
import h3 from '../images/faq/Mobile development.png';
import h4 from '../images/faq/Our Devoted TEAM.png';
import drop1 from '../images/faq/drop1.png';
import drop2 from '../images/faq/drop2.png';
import line from '../images/faq/Line 6.png';
import {Helmet} from 'react-helmet';
import axios from 'axios';
function Faq() {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    //------------------Api-end axios------------------//
    const [items, setItems] = useState([]);
    useEffect(() => {
        const fetchItems = async () => {
            await axios.get('https://codlerbackend.onrender.com/admin/get/api/faq').then((response)=>{
                response.data.map((index)=>{
                     setItems(response.data); 
                  })
                })
              }
        fetchItems();
    }, [])
    //------------------Api-end axios------------------//
    return (
        <div>
           <Helmet>
            <title>FAQ</title>
            <meta name="description" content="what you want to know about us?" />
           </Helmet>
            <section>
                <img src={shade} className='blur-lg z-0 absolute' data-aos="zoom-in"></img>
                <div className='w-8/12 mx-auto grid grid-cols-1 lg:grid-cols-2'>
                    <div className='pt-36 pb-28 blur-none' data-aos="fade-up">
                        <span className='flex'><img src={line} className='w-6 h-0.5 mt-2.5'></img><p className='text-white font-semibold pl-2'>FAQ</p></span>
                        <p className='text-white text-xl xl:text-4xl tracking-wide xl:w-[600px] font-gilroyregular font-semibold'>Do you have any question or queries, then please ask</p>
                       {/* <div class="pt-4 lg:pt-8">
                            <form className=''>
                                 <input className='rounded-full h-10 w-full bg-zinc-700 outline-none text-white pl-10 pr-36' type='text' placeholder='Search'></input>
                                 <span className='text-zinc-300 absolute left-2 p-2'><i class="fa-solid fa-magnifying-glass"></i></span>
                                 <a href='#' className='absolute right-0 rounded-full no-underline bg-violet-800 h-10 w-28'><p className='text-white py-1.5 px-4'>Search</p></a>
                            </form>   
                        </div>*/} 
                    </div>
                    <div className='hidden lg:block grid grid-cols-2' >
                        <div className='bg-zinc-800 opacity-50 h-[455px] ml-36 -z-200 -mt-20 w-96'>
                        <img src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1652779631/codler/Group_790_gnmokn.png' alt='img' className='w-56 -ml-12 h-64 mt-20 pt-36  ml-10 z-200 relative hidden lg:block'/>
                        </div>
                      <img src='https://res.cloudinary.com/dbwyrrrqo/image/upload/v1649618103/codler/businessman-with-laptop-thinking-at-office-2021-08-26-22-49-15-utc_1_lzbmzs.png' className='h-96 -mt-[400px]  ml-12  relative z-200 blur-none'></img>
                     </div>
                </div>
            </section>

            <section className='bg-zinc-900'>
            <img src={drop2} className='absolute hidden lg:block right-0 h-40 mt-80' data-aos="fade-up"></img>
                <div className='w-9/12 lg:w-8/12 mx-auto  py-10'>
                    <img src={h1} className='w-20 lg:w-24' data-aos="fade-up"></img>
                    {items.map((index)=>{
                        return(
                            <div className='mt-12'>
                                {(index.heading==='General')?
                                    <div className='' data-aos="fade-up">
                                    <p className='text-white font-semibold font-gilroyregular lg:text-[22px]'>{index.question}</p>
                                    <p className='text-zinc-400 lg:w-4/5 text-xs  -mt-1 font-gilroyregular lg:text-base'>{index.answer}</p>
                                    <hr class="my-4 w-[80%] text-zinc-200"/>
                                </div>:<div></div>}
                             </div>
                         )  
                    })}
                </div>
            </section>

            <section className='bg-black'>
            <img src={drop1} className='absolute hidden lg:block left-0 h-40 mt-96' data-aos="fade-up"></img>
                    <div className='w-9/12 lg:w-8/12 mx-auto py-14'>
                    <img src={h2} className='w-40 lg:w-56'  data-aos="fade-up"></img>
                    {items.map((index)=>{
                        return(
                            <div className='mt-12'>
                                {(index.heading==='Web development')?
                                    <div className='' data-aos="fade-up">
                                    <p className='text-white font-semibold font-gilroyregular lg:text-[22px]'>{index.question}</p>
                                    <p className='text-zinc-400 lg:w-4/5 text-xs  -mt-1 font-gilroyregular lg:text-base'>{index.answer}</p>
                                    <hr class="my-4 w-[80%] text-zinc-200"/>
                                </div>:<div></div>}
                             </div>
                         )  
                    })}
                    </div>
            </section>

            <section className='bg-zinc-900'>
                <div className='w-9/12 lg:w-8/12 mx-auto py-14'>
                <img src={h3} className='w-40 lg:w-56'  data-aos="fade-up"></img>
                {items.map((index)=>{
                        return(
                            <div className='mt-12'>
                                {(index.heading==='Mobile development')?
                                <div className='' data-aos="fade-up">
                                <p className='text-white font-semibold font-gilroyregular lg:text-[22px]'>{index.question}</p>
                                <p className='text-zinc-400 lg:w-4/5 text-xs  -mt-1 font-gilroyregular lg:text-base'>{index.answer}</p>
                                <hr class="my-4 w-[80%] text-zinc-200"/>
                                </div>:<div></div>}
                             </div>
                         )  
                    })}
                </div>
            </section>

            <section className='bg-black mb-5'>
                    <div className='w-9/12 lg:w-8/12 mx-auto pt-14 pb-20'>
                    <img src={h4} className='w-40 lg:w-56'  data-aos="fade-up"></img>
                    {items.map((index)=>{
                        return(
                            <div className='mt-12'>
                                {(index.heading==='Our devoted team')?
                                <div className='' data-aos="fade-up">
                                <p className='text-white font-semibold font-gilroyregular lg:text-[22px]'>{index.question}</p>
                                <p className='text-zinc-400 lg:w-4/5 text-xs  -mt-1 font-gilroyregular lg:text-base'>{index.answer}</p>
                                <hr class="my-4 w-[80%] text-zinc-200"/>
                                </div>:<div></div>}
                             </div>
                         )  
                    })}
                    </div>
            </section>
           

       </div>
        )
}
export default Faq;