
import { Buffer } from "buffer";
function RecentlyAdded(props){
    const getImage = (image) => Buffer.from(new Uint8Array(image)).toString("base64");
    return(
        <>
        {props.data.map((index)=>{
                return(
                    <div key={index.id}>
                    <div className='flex'>
                 <img  src={`data:image/png;base64,${getImage(index.userImage.data)}`}className='h-6 w-6 rounded-[50%]'></img>
                    <p className='text-zinc-300 text-xs font-light pt-1 pl-2'>{index.name}</p>
                    </div>
                    <p className='text-white text-lg  font-semibold'>{index.heading.slice(0, 1)}</p>
                    <div className='flex text-gilroythin'>
                        <p className='text-zinc-500 text-xs font-light '>5 Mint read</p>
                        <p className='text-zinc-500 text-xs font-light pl-4'></p>
                        </div>
                        </div>
                ) 
        
            })}
       


        </>
    )
}

export default RecentlyAdded;