import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect, useState } from 'react';
import {toast} from 'react-toastify';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { faArrowRight, faStar, faQuoteLeft} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import drop from '../images/serviceinner/img4@2x 1.png';
import '../App.css'



function Servicedetails() {

     // ------From submit------- //

     const[msg, setMsg] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        setMsg({...msg, [name]:value})
    }


    const handleSubmit = async (event)=> {
        event.preventDefault();
        const {name, email, phone, message} = msg;
        try{
            const res = await fetch('https://codlerbackend.onrender.com/admin/contact/message', {
                method: "POST",
                headers: {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify({
                    name, email, phone, message
                })
            })
            console.log(res.status);
            if(res.status === 400 || !res){
                toast.error('Message not sent. Please fill the form correctly',{
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }else{
                toast.dark('Thank you for your message.It has been sent.',{
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setMsg({
                    name: "",
                    email: "",
                    phone: "",
                    message: ""
                })
            }
        }catch (error) {
            console.log(error);
        }
    }
    // ------From Submit------- //

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    const navigate = useNavigate('');
    return (
        <div>



            <section className="s-inner-bg">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="w-10/12 mx-auto  lg:w-full text-white md:py-96 pt-80 lg:pt-64 lg:pl-32">
                        <h1 className='font-bold text-3xl gilroysemibold lg:leading-[3rem] md:text-[43px] 'data-aos="fade-right" data-aos-delay="1000">Mobile application development company</h1>
                        <p className='text-sm md:text-lg mt-3 font-light font-gilroyregular tracking-wider lg:w-3/4' data-aos="fade-right" data-aos-delay="2000">Codlerr's mobile app development service uses world-class technologies.</p>
                    </div>
                    <div className='hidden lg:block'>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319140/codler/p2_tun7us.png'} className='lg:pt-28 absolute right-0'></img>
                    </div>
                </div>
            </section>

            <section className='bg-black'>
                <div className='w-10/12 lg:w-8/12 mx-auto pt-20'>
                    <img src={drop} className='hidden lg:block absolute right-0 h-32 mt-96'></img>
                    <div className='text-white text-center'>
                        <h1 className='font-semibold font-gilroysemibold text-4xl' data-aos="fade-right" data-aos-delay="200">We Think Mobile First.</h1>
                        <p className='text-sm lg:text-sm lg:mt-3 font-light font-gilroythin lg:w-3/4 mx-auto'>Codlerr's mobile app development services use world-class technologies. We use React Native, Flutter and other tools to make your app scalable and future-proof on both native and cross-platform. Our backbone is our professional mobile developers, who will interact with you at every project stage.</p>
                    </div>
                    <div>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319134/codler/t1_vez2ak.png'}></img>
                    </div>
                </div>
                
            </section>

            <section className='bg-black noise'>
                <div className='w-10/12 mx-auto pb-20'>
                <div>
                    <h1 className='text-white text-2xl lg:text-4xl text-center font-gilroysemibold font-semibold pt-8 lg:pt-0' data-aos="fade-right" data-aos-delay="200">We help you create Apps for</h1>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 text-white pt-10 lg:pt-20'>
                    <div className='bg-gradient-to-r from-zinc-700 to-zinc-800 rounded-xl h-72  px-4'>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319142/codler/Vector_gwbxfv.png'} className='h-9 mt-4'></img>
                        <h4 className='pt-3'>Enterprise</h4>
                        <p className='text-sm mt-3 font-gilroythin'>Enterprise Mobile apps help streamline the organization's workflow according to departments and employee levels. Codlerr gives you complete access to overall enterprise mobility by providing the best features in enterprise apps.</p>
                    </div>
                    <div className='bg-gradient-to-r from-zinc-700 to-zinc-800 rounded-xl h-72 px-4'>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319143/codler/Vector1_sw0og4.png'} className='h-9 mt-4'></img>
                        <h4 className='pt-3'>Business</h4>
                        <p className='text-sm mt-3 font-gilroythin'>Mobile applications for Business are beneficial in many ways. They help the Business in building a solid brand valve. Applications also help the Business build a competitive edge according to their Niche.<span className='hidden lg:block'>They can also be used to channel social media and create direct and personalized marketing channels.</span></p>
                    </div>
                    <div className='bg-gradient-to-r from-zinc-700 to-zinc-800 rounded-xl  h-72 px-4'>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319127/codler/Group_2527_y66tvy.png'} className='h-9 mt-4'></img>
                        <h4 className='pt-3 font-gilroyregular'>Consumers</h4>
                        <p className='text-sm mt-3 font-gilroythin'>Consumer Mobile application connects your Business with its customers quickly, which provides value to the customers. In addition, it improves customer engagement and loyalty to the product or the brand.</p>
                    </div>
                </div>
                </div>
            </section>

            <section className='bg-zinc-900 noise'>
                <div className='grid grid-cols-1 lg:grid-cols-2 py-5 lg:pt-0'>
                    <div className='text-white mx-10 lg:py-28 lg:px-20'>
                        <h2 className='lg:w-3/4 font-gilroysemibold' data-aos="fade-right" data-aos-delay="200">Native Mobile App Development</h2>
                        <p className='text-sm lg:text-sm pt-4 font-gilroythin  lg:pt-8'>Codlerr, a top-notch native mobile app development company, builds customer-centric, indefectible, innovative, and high quality native mobile apps as a result for any of their software of iOS and Android.Our top-notch mobile app developers provide secure and adaptable mobility solutions for your business.</p>
                    </div>
                    <div>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319143/codler/t2_mcqk9f.jpg'}></img>
                    </div>
                </div>
            </section>

            <section className='bg-black'>
                <div className='grid grid-cols-1 lg:grid-cols-2 pt-10 lg:pt-0'>
                    <div>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319138/codler/t3_ojww2j.jpg'}></img>
                    </div>
                    <div className='text-white mx-10 lg:py-36 lg:px-20'>
                        <h2 className='pt-8 lg:pt-0 font-gilroysemibold' data-aos="fade-right" data-aos-delay="200">iOS Mobile App development</h2>
                        <p className='text-sm lg:text-sm pt-2 font-gilroythin lg:pt-8'>Codlerr, a top-notch native mobile app development company, builds customer-centric, indefectible, innovative, and high quality native mobile apps as a result for any of their software of iOS and Android.Our top-notch mobile app developers provide secure and adaptable mobility solutions for your business.</p>
                    </div>
                </div>
            </section>

            <section className='bg-black'>
                <div className='w-10/12 lg:w-8/12 mx-auto mt-3'>
                    <p className='text-zinc-800 lg:text-5xl font-gilroybold font-semibold lg:leading-[4rem]  text-center text-color font-bold py-12'>Get our skilled iOS developers to create full-featured, robust, and responsive iOS mobile apps.</p>
                </div>
            </section>

            <section className='bg-black mb-12 md:mb-16'>
                <div className='grid grid-cols-1 lg:grid-cols-2 lg:-mt-4'>
                    <div className='text-white mx-10 lg:pt-6 lg:px-20'>
                        <h2 className=' font-bold tracking-wider mt-5' data-aos="fade-right" data-aos-delay="200">Cross-platform/Hybrid mobile app development</h2>
                        <p className='text-sm lg:text-sm font-gilroythin pt-4  lg:pt-8'>Codlerr is the premier cross-platform/hybrid mobile app development company, providing businesses with a variety of benefits while also allowing them to reach a larger audience via devices running different operating systems. Our cross-platform developers have a track record of creating feature-rich, cross-platform compatible hybrid apps using cross-platform and technologies like React Native and Flutter, as well as HTML5 and JavaScript.
                        </p>
                        <p className='text-lg font-bold sectionbg4'>Features</p>
                        <div className='lg:flex' data-aos="fade-right">
                    <p className='text-zinc-400 pr-8 text-3xl opacity-30 font-bold'>01</p>
                        <p className='text-white font-gilroythin text-xs lg:text-sm ' >Whereas web and mobile apps have the same code base in the case of hybrid apps.</p>
                    </div>
                    <hr className="textlgcolor ml-16 text-6xl" style={{height:'3px'}}/>
                    <div className='lg:flex ' data-aos="fade-right">
                        <p className='text-zinc-400 pr-8 text-3xl opacity-30 font-bold'>02</p>
                        <p className='text-white font-gilroythin text-xs lg:text-sm '>In brief, we are using various plugins, you may control numerous hardware and software capabilities.
                    </p></div>
                    <hr className="textlgcolor  ml-16 w-46  text-6xl"style={{height:'3px'}} />
                    <div className='lg:flex ' data-aos="fade-right">
                        <p className='text-white lg:text-xl '>
                    <div className='lg:flex ' data-aos="fade-right">
                        <p className='text-zinc-400  text-3xl pr-8 opacity-30 font-bold'>03</p>
                        <p className='text-white font-gilroythin text-xs lg:text-sm '>A simple and quick approach to creating mobile applications with different types of features and functions.
</p>
                    </div></p>
                    </div>

                    </div>
                    <div>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319140/codler/t4_kyww6p.jpg'} className="mt-16"></img>
                    </div>
                </div>
            </section>

            <section className='bg-black'>
                <div className='grid grid-cols-1 lg:grid-cols-2 pt-10 lg:pt-0'>
                    <div>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319139/codler/t5_kmt72s.jpg'}></img>
                    </div>
                    <div className='text-white mx-10 lg:py-12 lg:px-20'>
                        <h2 className='pt-8 lg:pt-0' data-aos="fade-right" data-aos-delay="200">Progressive Web Apps <br/>
                        (PWA) Development:</h2>
                        <p className='text-sm lg:text-sm pt-4 font-gilroythin lg:pt-0'>Our outstanding Progressive Web Applications (PWA) development services construct apps with the same smooth performance as any native mobile apps. CODLERR produces progressive web apps that run quickly across all devices with minimal effort in order to maximise user engagement. We include unique features in web apps, such as offline browsing, push alerts, data analysis, and simple distribution methods.
                        </p>
                    </div>
                </div>
            </section>
            <section className='bg-black'>
                <div className='grid grid-cols-1 lg:grid-cols-2 py-10 lg:pt-0'>
                    <div className='text-white mx-10 lg:mt-28 lg:py-24 lg:px-20'>
                        <h2 className='lg:w-3/4' data-aos="fade-right" data-aos-delay="200">Android  Mobile App development.</h2>
                        <p className='text-sm lg:text-sm pt-4 font-gilroythin lg:pt-8'>Codlerr is a leading Android app development business. When our cutting-edge technology is blended with the expertise of our developers, we create an unrivalled unique Android mobile app solution for all sorts of businesses. Creating Android apps for Android devices and across the entire Android platform is what we do.
                        Hire the finest Android app developers from Codlerr. Who has expertise in developing bespoke Android apps with 100% client satisfaction is what we ensured about.
                        </p>
                    </div>
                    <div>
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653319142/codler/t6_wt7jsa.jpg'} className='mt-24'></img>
                    </div>
                </div>
            </section>
            <section className='w-10/12 mt-5 mx-auto bg-gradient-to-r from-zinc-900 via-zinc-800 to-zinc-900 rounded-xl mb-20' data-aos="zoom-in">
                <div className='w-10/12 mx-auto py-14'>
                    <div>
                        <p className='text-white text-lg font-semibold font-gilroythin leading-7'>We proudly work with<hr className='w-16 h-56 font-bold -mt-3 ml-56 h-24 textlgcolor'></hr></p>
                        <div className='grid grid-cols-2 lg:grid-flow-col gap-6 mt-2' >
                        <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648556429/codler/jsk_tjljta.png'} className='w-48 h-16 mt-12'></img>
                       <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648556430/codler/odikko_bzk7bo.png'} className='w-56 h-36'></img>
                       <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648556430/codler/ondace_yjiyg9.jpg'} className='w-32 h-28 md:mt-4 md:ml-10'></img>
                       <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648556430/codler/ugrab_fbpxud.png'} className='w-56 h-36 -mt-5'></img>
                        </div>
                    </div>
                </div>
            </section>
        
            <section className='bg-zinc-900 mb-20'>
                    <div className='w-11/12 lg:w-10/12 mx-auto'>
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 '>
                            <div className='py-20'>
                                <div className='text-white pb-10' data-aos="fade-up">
                                    <p className='text-2xl lg:text-3xl lg:w-4/5 font-gilroyregular font-semibold'>See what our valuable clients says about us</p>
                                    <p className='text-zinc-200 lg:w-4/5 text-xs font-gilroythin '>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra quis bibendum adipiscing cum mauris, faucibus nulla. Sed adipiscing sollicitudin </p>
                                </div>

                                <div className='w-11/12 mx-auto lg:w-full grid grid-cols-1 lg:grid-cols-2 gap-4' data-aos="fade-up">
                                          <div>
                                              <div className='bg-zinc-800 z-10 h-72 lg:h-64' data-aos="fade-up">
                                                    <div className='pl-6 pt-6 flex'>
                                                    <p className="text-xl pr-4 text-white font-semibold">5.0</p>
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500 " />
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500 " />
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500 " />
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500 " />
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500" />
                                                        
                                                    </div>
                                                    <p className='text-white leading-5 text-xs font-light font-gilroythin px-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 1500s, when an Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                    <div className='flex'>
                                                            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629515/codler/user1_uss6f0.png'} className='w-7 h-7 mx-6 mt-1'></img>
                                                            <div className='text-zinc-200 text-xs  font-semibold font-gilroythin -ml-3 mt-[3px]'>
                                                                <p>Arjun nabiyar</p>
                                                                <p className='-mt-4 text-xs font-normal font-gilroythin'>Director,lolipop desings</p>
                                                            </div>
                                                        </div>
                                                </div>
                                         </div>      
                                          <div>
                                                <div className='bg-zinc-800 z-10 h-72 lg:h-64 ' data-aos="fade-up">
                                                    <div className='pl-6 pt-6 flex'>
                                                    <p className="text-xl pr-4 text-white font-semibold">5.0</p>
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500 " />
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500 " />
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500 " />
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500 " />
                                                            <FontAwesomeIcon icon={faStar} className="text-l mt-1 text-yellow-500 " />
                                                        
                                                    </div>
                                                    <p className='text-white leading-5 text-xs font-light font-gilroythin px-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 1500s, when an Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                    <div className='flex'>
                                                            <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1648629515/codler/user1_uss6f0.png'} className='w-7 h-7 mx-6 mt-1'></img>
                                                            <div className='text-zinc-200 text-xs  font-semibold font-gilroythin -ml-3 mt-[3px]'>
                                                                <p>Arjun nabiyar</p>
                                                                <p className='-mt-4  text-xs font-normal font-gilroythin'>Director,lolipop desings</p>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>   





                                </div>
                            </div>


                            {/* form */}
                           <div className='grid place-items-center xs:px-3 md:px-0 xs:pb-10 md:pb-0' data-aos="fade-up">
                                    <form onSubmit={handleSubmit} method="POST">
                                        <div class="lg:ml-20 border-2 border-zinc-600 lg:w-[90%] rounded-md">
                                        <div class="lg:px-8 px-3 py-2">
                                            <div class="text-white">
                                            <p class="text-2xl font-semibold">Ready to start a Project?</p>
                                            <p class="tracking-wider text-zinc-300 -mt-3 md:text-sm">To start, use form below to tell us about you and the project</p>
                                            </div>

                                           <div className='flex flex-col gap-4'>
                                            <div class=" text-zinc-400">
                                                    <p className='text-zinc-200 opacity-50 mb-1 text-xs'>Name*</p>
                                                    <input class="w-full border-b-2 border-zinc-600 opacity-50 outline-none bg-transparent  text-sm -mt-20" type="text" name='name' value={msg.name} onChange={handleChange}  placeholder="Enter your good name" required/>
                                                </div>

                                                <div class=" text-zinc-400">
                                                    <p className='text-zinc-200 opacity-50 mt-2 mb-1 text-xs'>Email*</p>
                                                    <input class="w-full border-b-2 border-zinc-600 outline-none bg-transparent opacity-50 text-sm " type="email" name='email' value={msg.email} onChange={handleChange} placeholder="Enter email ID" required/>
                                                </div>

                                                <div class=" text-zinc-400">
                                                    <p className='text-zinc-200 opacity-50 mt-2 mb-1 text-xs'>Phone no:*</p>
                                                    <input class="w-full border-b-2 border-zinc-600 outline-none bg-transparent opacity-50 text-sm " type="number" name='phone' value={msg.phone} onChange={handleChange} placeholder="Enter Phone.no" required/>
                                                </div>

                                                
                                            
                                                
                                                <div class=" text-zinc-400 opacity-50 text-xs">
                                                    <p>Breif about your project</p>
                                                    <input class="text-zinc-200 w-full border-b-2 border-zinc-600 outline-none bg-transparent  text-sm md:text-base" type="text" name='message' value={msg.message} onChange={handleChange}  placeholder="We are happy to hear" required/>

                                                </div>

                                                <div class="">
                                                    <button type='submit' class="bg-violet-800 h-10 w-28 text-white text-xs px-2 py-2 rounded-lg">Let's start</button>
                                                </div>
                                           </div>
                                        </div>
                                    </div>
                                </form>   
                           </div>
                            {/* form */}
                        </div>
                    </div>
            </section>




        </div>
    )

}
export default Servicedetails;