import Aos from 'aos';
import "aos/dist/aos.css";
import {toast} from 'react-toastify'
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../App.css';
import Arrw from '../images/ar-sub.png';
import { useLocation } from 'react-router-dom'



function Footer() {

          // ------------- //

          const[msg, setMsg] = useState({
            email: ""
        })
    
        const handleChange = (event) => {
            let name = event.target.name;
            let value = event.target.value;
    
            setMsg({...msg, [name]:value})
        }
    
    
        const handleSubmit = async (event)=> {
            event.preventDefault();
            const {email} = msg;
            try{
                const res = await fetch('https://codlerbackend.onrender.com/admin/email/message', {
                    method: "POST",
                    headers: {
                        "Content-Type" : "application/json"
                    },
                    body : JSON.stringify({
                         email
                    })
                })
                console.log(res.status);
                if(res.status === 400 || !res){
                    toast.error('mail not sent.',{
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }else{
                    toast.dark('Your mail has been sent successfully.',{
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    setMsg({
                        email: ""
                    })
                }
            }catch (error) {
                console.log(error);
            }
        }
        // ------------- //
    


    const navigate = useNavigate('');
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);


    // to hide footer
    const { pathname } = useLocation();
    if (pathname === "/digital-marketing") return null;
    if (pathname === "/website") return null;
   

    
    return (
           <div>
                <section>
                        <div className='w-10/12 mx-auto md:pb-20'>
                            <div className='flex relative z-[99999]'>
                            <img src={'https://res.cloudinary.com/dpsamxitw/image/upload/v1661844892/codler/tbubble_hkidop.webp'} className=' lg:block w-16 mt-16 lg:w-24 lg:h-24 ml-2 bubble'></img>
                            <img src={'https://res.cloudinary.com/dpsamxitw/image/upload/v1661844892/codler/tbubble_hkidop.webp'} className=' lg:block w-8 h-8 mt-56 lg:w-16  lg:h-16 ml-2 lg:ml-4 bubble2'></img>
                            <img src={'https://res.cloudinary.com/dpsamxitw/image/upload/v1661844892/codler/tbubble_hkidop.webp'} className='lg:block w-4 h-4 mt-80 lg:w-8 lg:h-8  ml-2 lg:ml-4  bubble3'></img>
                            </div>


                            {/* <div className='footerbg bg-contain bg-url[(https://res.cloudinary.com/dbwyrrrqo/image/upload/v1659512237/codler/Group_116_1_tzh1nd.png)] -mt-10 md:py-5 xl:px-32 w-full xs:py-3  lg:rounded-lg'>
                                <div className=' mx-auto grid grid-cols-2 lg:grid-cols-2 gap-4'>
                                    <div className='md:p-[15%] xl:-ml-16  xs:pl-5 xs:pt-3 xl:p-12  lg:ml-24'>
                                        <p className='text-white text-xs lg:-mt-8 font-gilroybold lg:text-xl xl:text-2xl xl:-mt-4 xs:text-[8px]'  data-aos="fade-right">We believe in changing the world digitally</p>
                                        <a href='#'><button class="man-btn border-slate-100 sm:-mt-16 md:mt-0 md:mb-0 xs:text-[7px] m:h-16 hover:bg-slate-800 text-xs xs:w-20 lg:text-sm w-32 h-[30%] lg:h-10 lg:w-44 lg:h-18 text-white border-nuetral-800 rounded lg:px-4 ">Discuss your project</button></a>
                                    </div>
                                    <div>
                                        
                                       
                                    </div>
                                </div>

                            </div> */}


                            <div className=' bg-[rgba(108,122,137,0.1)] rounded-2xl footer-body'>
                                <div className='flex flex-col-reverse md:grid grid-cols-2 place-items-center w-11/12 mx-auto'>
                                    <div className=' grid place-items-start py-5'>
                                        <p className='text-white lg:text-3xl font-gilroysemibold  lg:w-96'>We believe in changing the world digitally</p>
                                        <a onClick={()=>navigate('/Contactus')} href='' className=''><button class="text-white bg-[#A81FAA] hover:bg-[#a81faaad] py-2 px-2 md:py-2.5 md:px-3 rounded text-xs md:text-sm">Discuss your project</button></a>

                                        </div>
                                    <div className='grid place-items-center'>
                                        <img src={'https://res.cloudinary.com/dpsamxitw/image/upload/v1661844220/codler/Asset_2_300x_xeldac.webp'} className='w-60 py-2'></img>
                                    </div>
                                </div>
                            </div>

                            
                            
                        </div>
                 </section>


                 <footer className='w-10/12 md:mx-auto p-10 md:p-0 lg:pb-16 pb-10'>
                     <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                            <div  className=''>
                                <p className='text-white text-base md:text-xs lg:text-base font-semibold'>Quick links</p>
                                <div>
                                    <a href='' onClick={() => navigate('/Aboutus')} className='text-zinc-500 text-base md:text-xs lg:text-sm hover:text-zinc-300 duration-500 font-semibold tracking-wide no-underline hover:no-underline'><p className=''>About us</p></a>
                                    <a href='' onClick={() => navigate('/services')} className='text-zinc-500 text-base md:text-xs lg:text-sm hover:text-zinc-300 duration-500 font-semibold tracking-wide no-underline hover:no-underline'><p className='-mt-2 md:mt-0'>Services</p></a>
                                    <a href='' onClick={() => navigate('/portfolio')} className='text-zinc-500 text-base md:text-xs lg:text-sm hover:text-zinc-300 duration-500 font-semibold tracking-wide no-underline hover:no-underline'><p className='-mt-2 md:mt-0'>Portfolio</p></a>
                                    <a href='' onClick={() => navigate('/Contactus')} className='text-zinc-500 text-base md:text-xs lg:text-sm hover:text-zinc-300 duration-500 font-semibold tracking-wide no-underline hover:no-underline'><p className='-mt-2 md:mt-0'>Contact us</p></a>
                                </div>
                            </div>
                            <div  className=''>
                                <p className='text-white text-base md:text-xs lg:text-base  font-semibold'>Social media</p>
                                <div>
                                    <a target='_blank' href='https://instagram.com/codlerr?igshid=YmMyMTA2M2Y=' className='text-zinc-500 text-base md:text-xs lg:text-sm hover:text-zinc-300 duration-500 tracking-wide no-underline hover:no-underline font-semibold'><p className=''><span className='pr-2 '><i class="fa-brands fa-instagram"></i></span>Instagram</p></a>
                                    <a target='_blank' href='https://www.facebook.com/codlerrsolutions/' className='text-zinc-500 text-base md:text-xs lg:text-sm hover:text-zinc-300 duration-500 tracking-wide no-underline hover:no-underline font-semibold -mt-2 md:mt-0'><p className=''><span className='pr-2 '><i class="fa-brands fa-facebook-square"></i></span>Facebook</p></a>
                                    <a target='_blank' href='https://www.linkedin.com/company/codlerr' className='text-zinc-500 text-base md:text-xs lg:text-sm hover:text-zinc-300 duration-500 tracking-wide no-underline hover:no-underline font-semibold -mt-2 md:mt-0'><p className=''><span className='pr-2 '><i class="fa-brands fa-linkedin-in"></i></span>LinkedIn</p></a>
                                    <a target='_blank' href='https://twitter.com/Codler3?t=PYG-ShshZ3RTT68Z6F56Og&s=08' className='text-zinc-500 text-base md:text-xs lg:text-sm hover:text-zinc-300 duration-500 tracking-wide no-underline hover:no-underline font-semibold -mt-2 md:mt-0'><p className=''><span className='pr-2 '><i class="fa-brands fa-twitter"></i></span>Twitter</p></a>
                                </div>
                            </div>
                            <div className='lg:-ml-10'>
                                <p className='text-white text-base md:text-xs lg:text-base  font-semibold'>Registered Office Address</p>
                                <p className='text-zinc-500 text-base md:text-xs lg:text-sm hover:text-zinc-300 tracking-wide duration-500 no-underline font-semibold'>Codler Solutions Private Limited,<br></br>
                                    Bengaluru | Mahe
                                </p>
                            </div>
                            <div className='overflow-hidden'>
                                <p className='text-white text-base md:text-xs lg:text-base  font-semibold'>Signup for newsletters</p>
                                <p className='text-zinc-200 text-xs'>Email</p>
                                <div className=''>
                                <form onSubmit={handleSubmit} method='POST' className="max-w-full">
                                    <div className='flex'>
                                        <input pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}" title='Must be a valid email' className="bg-transparent text-white font-semibold opacity-50  border-b-2 h-10  text-xs md:text-sm border-0 outline-none" required type="email" placeholder="Samplemail@.com" name='email' value={msg.email} onChange={handleChange}/>
                                        {/* <button type='submit'><i class="fas fa-arrow-circle-right text-pink-500 text-3xl md:text-base lg:text-3xl" ></i></button> */}
                                        <button type='submit'><img src={Arrw} className='h-10 pb-1 pl-2'></img></button>
                                    </div>
                                    <hr className="text-gray-200 opacity-20 text-2xl w-60 mt-2" />
                                    </form>
                                    

                                </div>
                            </div>
                     </div>
                 </footer>
                 <section className=' bg-zinc-800'>
                     <div className='w-10/12 mx-auto h-14 py-2 flex'>
                        
                         <img src={'https://res.cloudinary.com/dbwyrrrqo/image/upload/v1653337740/codler/White_2_kdz48g.webp'} className='w-16 h-7 lg:mt-[3px] lg:w-24 xl:h-8 lg:-ml-4 cursor-pointer'></img>
                         <a href='' className='no-underline text-zinc-500 hover:text-zinc-300 xs:py-1.5 sm:py-1.5 md:py-2.5  pl-2 text-xs lg:text-xs hover:no-underline'><p>2021 codler Inc. all rights reserved</p></a>
                     </div>
                 </section>
           </div>
        )
}
export default Footer;