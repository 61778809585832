import react,{useEffect, useState ,useRef} from 'react';
import '../../digital-marketing.css';
import {toast} from 'react-toastify';
import axios from 'axios';
import { Buffer } from 'buffer'
import { useLocation } from 'react-router'
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ClienSlider from '../sliders/client-slider'

function Clientsdetails() {

    const location = useLocation();
    const clientDetails = location.state.clients;
    const [items, setItems] = useState([]);
    
	useEffect(() => {
		const fetchItems = async () => {
			await axios.get("https://codlerbackend.onrender.com/admin/get/api/clients").then((response) => {
				setItems(response.data);
			});
		};
		fetchItems();
	}, []);

   /**
	 * Function that transform the buffer to base64
	 * @param {*} image : Image buffer array
	 * @returns : Base64 image
	 */
	const getImage = (image) => Buffer.from(new Uint8Array(image)).toString("base64");
    const navigate = useNavigate('');


    function ourRecentsWork(index) {
        console.log(index);
    
        return (
                <>
                    <LazyLoadImage effect='blur' className='h-fit w-full' src={`data:image/png;base64,${getImage(index.recentImages.data)}`} ></LazyLoadImage>
                </>
            )
    }

  return (
    <div className=''>
         {/* nav */}
            {/* sticky top-0 */}
         {/* <section className='bg-slate-600 bg-opacity-25 backdrop-blur-sm '>

            <div className='w-11/12 md:w-11/12 lg:w-[88%] mx-auto  flex justify-between '>
                    <div className='xs:mt-3 md:mt-8'>
                        <img className='cursor-pointer h-[45px] md:h-[60px]' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668245921/codler%20branding%20page/white-1_ckuhb5.webp' alt='image'></img>
                    </div>
                    <div className='xs:mt-5 md:mt-12 '>
                        <a onClick={() => navigate('/Contactus')} className='mt-3 md:mt-0 bg-gradient-to-r from-[#D729DE] to-[#7521A8]  cursor-pointer no-underline hover:no-underline text-white font-gilroysemibold px-6 md:px-14 xs:py-2 md:py-3 rounded-md'>Let's Talk</a>
                    </div>
                </div>
         </section> */}

            {/* Banner */}
         <section className='xs:pt-10 md:pt-0 text-white lg:h-screen'>
                <div className='grid grid-cols-1 md:grid-cols-3'>
                    <div className='grid place-items-center pt-16 md:pt-0 xs:pl-0 md:pl-14'>
                        <LazyLoadImage className='h-40' effect='blur' src={`data:image/png;base64,${getImage(clientDetails.testiLogo.data)}`}></LazyLoadImage>
                    </div>
                    <div className='client-banner-side grid place-items-center md:col-span-2'>
                        <LazyLoadImage effect='blur' src={`data:image/png;base64,${getImage(clientDetails.clientImage.data)}`}></LazyLoadImage>
                    </div>
                </div>
         </section>


            {  /* about */}
         <section className='w-10/12 mx-auto'>
                <div className='grid place-items-center'>
                    <h2 className='text-white text-3xl md:text-5xl text-center font-gilroysemibold capitalize'>About {clientDetails.testiName}</h2>
                    <p className='text-[#bdbaba] text-lg md:text-xl font-gilroymedium text-center w-full lg:w-[80%] mx-auto py-5 md:py-10'>{clientDetails.aboutDesc}</p>
                </div>


                <div className='grid grid-cols-1 md:grid-cols-2'>
                        <div className=' '>
                            <LazyLoadImage className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668489780/codler%20branding%20page/download_7_2x_wl4q8l.webp'></LazyLoadImage>
                        </div>
                        <div className='flex flex-col align-middle justify-center md:w-[80%] mx-auto'>
                            <h3 className='text-white font-gilroysemibold text-3xl lg:text-4xl'>The Challenge</h3>
                            <p className='text-[#bdbaba] text-lg font-gilroymedium'>{clientDetails.challegDesc}</p>
                        </div>
                        
                        <div className='flex flex-col align-middle justify-center md:w-[80%] mx-auto pt-10 md:pt-0'>
                            <h3 className='text-white font-gilroysemibold text-3xl lg:text-4xl'>What we deliverd</h3>
                            <p className='text-[#bdbaba] text-lg font-gilroymedium'>{clientDetails.deliverdDesc}</p>
                        </div>
                        <div className=' '>
                            <LazyLoadImage className='' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668489780/codler%20branding%20page/download_8_2x_gm7bcb.webp'></LazyLoadImage>
                        </div>
                </div>

                <div className=' grid grid-cols-1 md:grid-cols-3 text-white gap-5 md:gap-0 place-items-center py-10 lg:py-0'>
                        <div className='grid place-items-center'>
                            <h4 className='font-gilroybold text-6xl lg:text-8xl'>200%</h4>
                            <p className='font-gilroylight xs:text-sm md:text-[7px] lg:text-sm'>INCREASE IN ORGANIC SEO TRAFFIC</p>
                        </div>
                        <div className='grid place-items-center'>
                            <h4 className='font-gilroybold text-6xl lg:text-8xl'>85%</h4>
                            <p className='font-gilroylight xs:text-sm md:text-[7px] lg:text-sm'>INCREASE IN WEBSITE CONVERSIONS</p>
                        </div>
                        <div className='grid place-items-center'>
                            <h4 className='font-gilroybold text-6xl lg:text-8xl'>350+</h4>
                            <p className='font-gilroylight xs:text-sm md:text-[7px] lg:text-sm'>VIDEOS OPTIMIZED FOR YOUTUBE</p>
                        </div>
                </div>
         </section>

         <section className='w-10/12 mx-auto '>
            <video className='w-full ' loop="true"  controls >
                <source type="video/mp4" src={clientDetails.clientVideo}></source>
            </video>
         </section>

         <section className='w-9/12 md:w-10/12 mx-auto pt-48 pb-20'>
                <ClienSlider/>
         </section>


         <section className='w-10/12 mx-auto text-white pb-10'>
                <div className=''>
                        <h4 className='font-gilroysemibold text-xl text-[#7521A8] py-2 tracking-widest'>-OUR RECENT WORK</h4>
                        <p className='font-gilroysemibold text-2xl lg:text-5xl w-[95%]'>Our impeccable advanced technological solutions.
                                We give you the finest design approach and the
                                most powerful development plans for your brand.</p>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-5 py-5'>
                {items.slice(0,3).map(ourRecentsWork)}
                        {/* <LazyLoadImage effect='blur' className='h-fit w-full' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668491775/codler%20branding%20page/Screenshot_2022-11-15_11071as4_dlps1v.webp' ></LazyLoadImage>
                        <LazyLoadImage effect='blur' className='h-fit w-full' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668491775/codler%20branding%20page/Screenshot_2022-11-15_110sd714_cepkev.webp' ></LazyLoadImage>
                        <LazyLoadImage effect='blur' className='h-fit w-full' src='https://res.cloudinary.com/dvbplh4z9/image/upload/v1668491775/codler%20branding%20page/Screenshot_2022-1asd1-15_110714_zla4fd.webp' ></LazyLoadImage> */}
                </div>

                <div className='grid place-items-center'>
                        <a onClick={() => navigate('/Contactus')} className=' md:mt-0 border-2 border-[#D729DE] duration-500 ease-in-out transition-all  hover:bg-gradient-to-r from-[#D729DE] to-[#7521A8]  cursor-pointer no-underline hover:no-underline text-white font-gilroysemibold px-10 md:px-14 xs:py-2 md:py-3 rounded-md'>Let's Talk</a>
                </div>
         </section>

        
        


        </div>
  )
}

export default Clientsdetails;