import React, {useRef,useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../../digital-marketing.css'
import {BsArrowLeft, BsArrowRight,} from 'react-icons/bs' 
import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { dataDigital } from '../../constants/digital-data';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function SampleNextArrow({onClick}) {
    return (
      <div className='arrow arrow-right  -mt-10' onClick={onClick}>
        <BsArrowRight/>
      </div>
    );
  }
  
  function SamplePrevArrow({onClick}) {
    return (
      <div className='arrow arrow-left  -mt-10' onClick={onClick}>
        <BsArrowLeft/>
      </div>
    );
  }



function Slider3() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  
    return (
     <div>
        <Slider {...settings}>
            {dataDigital.map((item) => (
                <div className="card-bg">
                    <div className="grid place-items-center ">
                      <img className="h-20 md:h-10 lg:h-20 rounded-full" src={item.img} alt="item.title"></img>
                        
                        <h3 className="font-gilroymedium md:text-base lg:text-2xl ">{item.title}</h3>
                        <h6 className="font-gilroylight md:text-sm lg:text-xl">{item.desg}</h6>
                        <p className="font-gilroysemibold text-sm md:text-[10px]  lg:text-base w-9/12 mx-auto  lg:pt-3">{item.desc}</p>
                        <span className="md:-mt-3 lg:-mt-0">{item.star}</span>
                    </div>
                </div>
            ))}
            </Slider>
     </div>
    );
}
export default Slider3;